import React, { createContext, useCallback, useEffect, useState } from "react";
import { HostLead } from "../../config/config";
import { useParams } from "react-router-dom";

const CampaignContext = createContext();

const LIMIT_USERS_PER_PAGE = 20;

const CampaignProvider = ({ children }) => {
  const { id: campaignId } = useParams();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false)

  const getUsersByCampaign = useCallback(async () => {
    try {
      setLoading(true)
      const query = new URLSearchParams({
        ...(searchTerm && { searchTerm }),
        page: currentPage,
        limit: LIMIT_USERS_PER_PAGE,
      });

      const response = await fetch(
        `${HostLead}api/escuchas/campaign/${campaignId}/getUsers?${query.toString()}`
      );
      const data = await response.json();
      setUsers(data.users);
      setTotalPages(data.totalPages)
      setLoading(false)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }, [campaignId, currentPage, searchTerm]);

  const changeUserStatus = async (userId, status) => {
    try {
      const path = status === "ACTIVATE" ? "activateUser" : "deactivateUser";
      await fetch(`${HostLead}api/escuchas/campaign/${campaignId}/${path}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });
      getUsersByCampaign();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUsersByCampaign();
  }, [getUsersByCampaign]);

  return (
    <CampaignContext.Provider
      value={{
        users,
        searchTerm,
        setSearchTerm,
        changeUserStatus,
        getUsersByCampaign,
        campaignId,
        currentPage,
        setPage: setCurrentPage,
        totalPages,
        loading
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

const useCampaign = () => {
  const context = React.useContext(CampaignContext);
  if (context === undefined) {
    throw new Error("useCampaign must be used within a CampaignContext");
  }
  return context;
};

export { CampaignProvider, CampaignContext, useCampaign };
