import React, { Component } from 'react';

import { Button, Card,Form,} from 'react-bootstrap';
// import {HOST} from '../../config/config';
import Axios from 'axios';
// import { VictoryChart, VictoryBar,VictoryPie, VictoryTheme } from "victory";
import donut from "../../images/donut.png"
import pie from "../../images/pie.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload} from '@fortawesome/free-solid-svg-icons'
import hemiciclo from "../../images/hemiciclo.png"
import horizontalBar from "../../images/horizontalBar.png"
import verticalBar from "../../images/verticalBar.png"
import ModalNewData from "../../complements/ModalNewDataChart"
const Style ={ width: '25%',padding: '2%', textAlign:'center'}
class creaChart extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            name:"",
            modalShow:false,
            disabled:true,
         }
         this.handleChange = this.handleChange.bind(this);
    }
    
    
    handleChange = event => {
        this.setState({
        [event.target.id] : event.target.value
        });
        
    }
    creaNuevoChart= async(id_chart_type)=>{
        this.setState({
            modalShow:true
        })
        var urlHost = "http://localhost:1070/apielmundo/updateNewChart";
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        var self= this;
        Axios({
            method: 'PUT',
            url: urlHost,
            headers:{
                "Content-Type": "application/json; charset=utf-8",
				"Authorization": "Bearer " + MYTOKEN.access_token,
            },  
            data:{
                nombre:this.state.name,
                id_chart_type:id_chart_type,
            }
        })
        .then(function(res){
            self.setState({
                id_chart_list:res.data.id_chart_list,
                modalShow:true,
            });

            
        }).catch(
            function(res){
                console.log("Problemas")
            }
        )

      }
    render() { 
        // var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        return ( 
                <Form>
                    <Form.Row>

                    <Form.Group controlId="name">
                        <Form.Label>Nombre del gráfico</Form.Label>
                        <Form.Control type="name"  placeholder="Introduce nombre del gráfico" onChange ={this.handleChange} />
                        <Form.Text className="text-muted">
                        Es conveniente que el Nombre de la pregunta sea igual que el Id de la pregunta. Ej. P1_X

                        </Form.Text>
                    </Form.Group>   
                    </Form.Row>
                <Form.Row>               
                <Card style={Style}>
                    <Card.Body>
                    <Card.Img variant="top" src={donut} style={{width:"50%"}} />
                        <Card.Title>Donut</Card.Title>

                    </Card.Body>
                    <Card.Footer>
                        <Button disabled={!(this.state.name)} onClick={() => this.creaNuevoChart(5)} variant="primary">Seleccionar</Button>
                        <Button src="https://pre-backofficev2.s3-eu-west-1.amazonaws.com/ArchivosEjemplo/Ejemplo_Doughnut.csv" variant="secondary" > <FontAwesomeIcon cursor="pointer" icon={faFileDownload} /></Button>
                    </Card.Footer>
                </Card>
                <Card style={Style}>    
                    <Card.Body>
                        <Card.Img variant="top" src={verticalBar} style={{width:"50%"}} />
                        <Card.Title>Barras Verticales</Card.Title>
                    </Card.Body>
                    <Card.Footer>
                        <Button disabled={!(this.state.name)} onClick={() => this.creaNuevoChart(2)} variant="primary">Seleccionar</Button>
                        <Button src="https://pre-backofficev2.s3-eu-west-1.amazonaws.com/ArchivosEjemplo/Ejemplo_Bar.csv" variant="secondary" > <FontAwesomeIcon cursor="pointer" icon={faFileDownload} /></Button>
                    </Card.Footer>
                </Card>
                <Card style={Style}>

                    <Card.Body>
                    <Card.Img variant="top" src={hemiciclo} style={{width:"50%"}} />
                        <Card.Title>Hemiciclo</Card.Title>
                    </Card.Body>
                    <Card.Footer>
                        <Button disabled={!(this.state.name)} onClick={() => this.creaNuevoChart(6)} variant="primary">Seleccionar</Button>
                        <Button variant="secondary" > <FontAwesomeIcon cursor="pointer" icon={faFileDownload} /></Button>
                    </Card.Footer>
                </Card>
                
                <Card style={Style}>
                    
                    <Card.Body>
                    <Card.Img variant="top" src={horizontalBar} style={{width:"50%"}} />
                        <Card.Title>Barras Horizontales</Card.Title>

                    </Card.Body>
                    <Card.Footer>
                        <Button disabled={!(this.state.name)} onClick={() => this.creaNuevoChart(11)} variant="primary">Seleccionar</Button>
                        <Button variant="secondary" > <FontAwesomeIcon cursor="pointer" icon={faFileDownload} /></Button>
                    </Card.Footer>
                </Card>

                <Card style={Style}>
                    
                    <Card.Body>
                    <Card.Img variant="top" src={pie} style={{width:"50%"}} />
                        <Card.Title>Tarta</Card.Title>
                    </Card.Body>
                        <Card.Footer>
                            <Button disabled={!(this.state.name)} onClick={() => this.creaNuevoChart(4)} variant="primary">Seleccionar</Button>
                            <Button variant="secondary" > <FontAwesomeIcon cursor="pointer" icon={faFileDownload} /></Button>
                        </Card.Footer>
                </Card>
                </Form.Row>
                <ModalNewData 
                            show={this.state.modalShow}
                            name ={this.state.name}
                            id_chart_list={this.state.id_chart_list}
                            onHide2={() => this.setState({modalShow:false})}
                            /> 
                </Form>
        );
    }
}
export default creaChart;