import React, { useState } from 'react'
import AutocompleteUsers from './AutocompleteUsers';
import { useCampaign } from '../../../CampaignContext';
import { Button } from 'react-bootstrap';

function ChangeStatusForm({type = "ACTIVATE", disabled = false}) {
  const [userToActivate, setUserToActivate] = useState(null);
  const { changeUserStatus } = useCampaign();
  return (
    <div
      style={{
        marginBottom: "20px",
      }}
    >
      <AutocompleteUsers disabled={disabled} type={type} value={userToActivate} onChange={(value) => {
        setUserToActivate(value)
      }} />

      <div className="d-grid gap-2">
        <Button variant="primary" className="w-100" disabled={!userToActivate} onClick={async () => {
          await changeUserStatus(userToActivate?.user_id, type);
          setUserToActivate(null);
        }}>
          {type === "ACTIVATE" ? "Activar" : "Desactivar"} Usuario
        </Button>
      </div>
    </div>
  )
}

export default ChangeStatusForm