// import Axios from 'axios';
import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { NavigationBar } from "./complements/NavigationBar";
import Usuarios from "./routes/admin/usuarios";
import Recompensas from "./routes/rewards/rewardsList";
// import {HOST} from './config/config';
import { Layout } from "./Layout";
import terminosCondiciones from "./routes/admin/docleg/termcond";
import legals from "./routes/admin/docleg/legals";
import politicaPriv from "./routes/admin/docleg/politicaPrivacidad";
import politicaCookies from "./routes/admin/docleg/politicaCookies";
import basesLegales from "./routes/admin/docleg/basesLegales";
import encuestasCategory from "./routes/encuestas/encuestasCategory";
import encuestas from "./routes/encuestas/encuestas";
import panelista from "./routes/panelista/panelista";
import panelista2 from "./routes/panelista/panelista2";
//import entregaRecompensas from './routes/rewards/orderRewards'
import importDataSurveys from "./routes/panelista/importDataSurveys";
import elMundoChart from "./routes/charts/elMundoChart";
import creaChart from "./routes/charts/creaChart.js";
import chartResult from "./routes/charts/ChartResult";
import Graficos from "./routes/graficos";
import Notifications from "./routes/notifications";
import MomentUtils from "@date-io/moment";
import FlashSurveys from "./routes/flashSurveys/flashSurveys";
import Admin from "./routes/admin/admin";
import DocsLeg from "./routes/admin/docleg/docleg";
import CampaignDetail from "./routes/campaign/index.js";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Login from "./Login";
import Campaigns from "./routes/campaigns/index.js";

const Home = () => {
  return (
    <div>
      <Router>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div>
            <NavigationBar />
            <Layout>
              <Switch>
                <Route path="/" exact component={Graficos}></Route>
                <Route path="/usuarios" exact component={Usuarios}></Route>
                <Route path="/recompensas" component={Recompensas}></Route>
                <Route
                  path="/terminosCondiciones"
                  component={terminosCondiciones}
                ></Route>
                <Route path="/legals" component={legals}></Route>
                <Route path="/politicaPrivacidad" component={politicaPriv} />
                <Route path="/politicaCookies" component={politicaCookies} />
                <Route path="/basesLegales" component={basesLegales} />
                <Route path="/encuestas" component={encuestas} />
                <Route
                  path="/encuestasCategory"
                  component={encuestasCategory}
                />
                <Route path="/panelista" component={panelista} />
                <Route path="/panelista2" component={panelista2} />
                <Route path="/importarDatos" component={importDataSurveys} />
                <Route path="/elMundoChart" component={elMundoChart} />
                <Route path="/chartResult" component={chartResult} />
                <Route path="/creaChart" component={creaChart} />
                <Route path="/mobileNotification" component={Notifications} />
                <Route path="/flashSurveys" component={FlashSurveys} />
                <Route path="/LogOut" component={Login} />
                <Route path="/admin" component={Admin} />
                <Route path="/docleg" component={DocsLeg} />
                <Route path="/estudio/:id" component={CampaignDetail} />
                <Route path="/estudios" component={Campaigns} />
              </Switch>
            </Layout>
          </div>
        </MuiPickersUtilsProvider>
      </Router>
    </div>
  );
};

export default Home;
