import React, {Component} from 'react';
import {Container,Pagination, Table,Button} from 'react-bootstrap';
import styled from 'styled-components';
import {HOST, HOSTBridge} from '../../config/config';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChartBar} from '@fortawesome/free-solid-svg-icons'
import ModalNewElMundoChart from '../../complements/ModalNewElMundoChart'

const Styles = styled.div`
.container{
    text-align:center;
}
.page-link{
    background-color:#343a40;
    color:white;
    border: 1px solid #dee2e6;

}
&:hover{
    background-color:#3e444a,
    color:white;
    border: 1px solid #dee2e6;

}
paginationItemStyle{
    float:Left;
}
.nuevoUsuario{
    float:right;
}
`
class elMundoChart extends Component{
    constructor(){
        super();
        this.state={
            cols : ["Id", 'ID Artículo','Posición', 'Id Pregunta', 'Tipo de Chart Id', ''],
            modalShow:false,
            setModelShow: false,
            modalShow2:false,
            setModelShow2: false,
            chartsTotal:0,
            chartsPage:10,
            currentPage:1,
            pages:0,
            charts:[]

        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(event){
        this.setState({
            currentPage: Number(event.target.id)
          });
    }
    componentDidMount(){
        console.log("Toy entrando")
        this.getCharts();
    }

    getCharts = async(obj) =>{

        var urlHost = HOSTBridge + "api/panel_elmundo/articles/charts/getAll?limit=20&offset=0&pageClicked=1&[]=";
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        var self= this;
        Axios({
            method: 'GET',
            url: urlHost,
            headers:{
                "Content-Type": "application/json; charset=utf-8",
				"Authorization": "Bearer " + MYTOKEN.access_token,
            },
            data:{}
        })
        .then(function(res){
            var datos = res.data;
            console.log(datos);
            self.setState({
                charts: datos.content,
                chartsTotal:datos.content.length,

            })
        }).catch(
            function(res){
                console.log("no funciona el get Charts")
            }
        )
    }

    render(){
        const pageNumbers =[];
        const charts = this.state.charts;
        for(let i= 1; i <= Math.ceil(this.state.chartsTotal/this.state.chartsPage);i++){
            pageNumbers.push(i);
        }
        const indexOfLastTodo = this.state.currentPage * this.state.chartsPage;
        const indexOfFirstTodo = indexOfLastTodo - this.state.chartsPage;
        const currentTodos = charts.slice(indexOfFirstTodo, indexOfLastTodo);
        const renderCharts = currentTodos.map((chart, index) => {

                const{id,position,question_id,id_chartType,article_id} = chart;

                return(


                    <tr key={id}>
                        <td>{id}</td>
                        <td>{article_id.article_name}</td>
                        <td>{position}</td>
                        <td>{question_id}</td>
                        <td>{id_chartType.Type}</td>

                        <td>
                            <span onClick={() => this.setState({modalShow:true,
                                                               })}>
                                <FontAwesomeIcon cursor="pointer" icon={faChartBar} />
                            </span>
                        </td>
                    </tr>

                )
             })
        const renderPageNumbers = pageNumbers.map(number => {
            return(

                <Pagination.Item
                    className="paginationItemStyle"
                    key={number}
                    id= {number}
                    onClick={this.handleClick}>
                        {number}
                    </Pagination.Item>
            )
        })
        return (
            <Styles>
            <Container className ='container'>
            <div>
                <h1>El Mundo Chart</h1>
                <Button variant="info" className="nuevoUsuario" onClick={() => this.setState({modalShow:true})}>
                    <FontAwesomeIcon icon={faPlus} /> Nuevo Chart</Button>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            {this.state.cols.map((col) => <td key={col}>{col}</td>)}
                        </tr>
                    </thead>
                    <tbody>

                    {renderCharts}

                    </tbody>



                </Table>
                <Pagination className='paginationItemStyle'>
                <ModalNewElMundoChart
                  show={this.state.modalShow}
                  onHide={() => this.setState({modalShow:false})}/>
                            {renderPageNumbers}

                </Pagination>


            </div>
            </Container>
            </Styles>
        )
    }
}


export default elMundoChart;