import React, { Component } from 'react';
import { Col, Row, Form } from 'react-bootstrap';


class QuestionInputs extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            numRespuesta:0,
            response:[""]
         }
    }
    handleChange = (e) => (
        this.props.handleChange(e)
    )
    addQuestion = (e) =>{
        this.props.handleQuestionChange(e)
    }
    addAnswer = (e) =>{
        this.props.handleChange(e)

        if(e.target.getAttribute('index') == this.state.numRespuesta ){
            this.setState((prevState) => ({
                response: [...prevState.response, ""],
                numRespuesta: this.state.numRespuesta + 1
            }))
        }
        
    }
    render() { 
        const self = this
        let questionId = `q-${this.props.index}`
        return(
                
                    <Row>
                        <Form.Group as={Col}  id={questionId}>
                            <Form.Label>
                                Preguntas de la encuesta
                            </Form.Label>
                            <Form.Control id={`${questionId}`} onChange={(e) => this.addQuestion(e)}/>
                        </Form.Group>
                        <Form.Group as={Col}  id={``}>
                            {self.state.response.map((_,index) => {
                                return (
                                    <div key={`${questionId} a-${index}`}> 
                                        <Form.Label>
                                            Respuesta {index}
                                        </Form.Label>    
                                        <Form.Control id={`${questionId} r-${index}`} index ={index} onChange={(e) => this.addAnswer(e)}  />
                                    </div>
                                )
                            })}
                            
                        </Form.Group>
                    </Row>
        )
    }
}
export default QuestionInputs;