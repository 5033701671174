import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import './PointsHistoryForm.css'
import Axios from 'axios';
import { HostLead } from '../../config/config';

const PointsHistoryForm = (props) => {
    const [form, setForm] = useState()

    const handlerForm = (event) => {
        const { name, value } = event.target
        setForm({ ...form, [name]: value })
    }
    const handlerClick = () => {
        props.handlerIndex(0)
    }
    const onSubmitForm = () => {
        console.log("aca")
        const data = {
            user_id: props.user_Id,
            survey_id: form.survey_id,
            points: form.points,
            date: form.date
        }
        Axios({
            method: 'post',
            url: HostLead + `api/addBackofficePoints`,
            data: data
        }).then(function (res) {
            window.alert("Carga correcta")
            handlerClick(0)
        }).catch(err => {
            console.log(err)
            window.alert("ocurrio un problema al cargar los datos ")
        })
    }
    return (
        <Container >
            <Row>
                <Col className="justify-content-center " onClick={() => handlerClick(0)} >
                    <p className="text-center d-block points-history-buttom mt-0 mb-1" >Añadir Item</p>
                </Col>
            </Row>
            <Row>
                <Col className="justify-content-center">
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center label-form-points">CONCEPTO</Form.Label>
                        <Form.Control
                            name='survey_id'
                            className="text-center Control-form-ponts"
                            onChange={handlerForm}
                        />
                    </Form.Group>
                </Col>
                <Col className="justify-content-center">
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center label-form-points">SC</Form.Label>
                        <Form.Control
                            name='points'
                            type="number"
                            className="text-center Control-form-ponts"
                            onChange={handlerForm}
                        />                    </Form.Group>
                </Col>
                <Col className="justify-content-center">
                    <Form.Group className="mb-3">
                        <Form.Label className="text-center label-form-points">FECHA</Form.Label>
                        <Form.Control
                            name='date'
                            type='date'
                            className="text-center Control-form-ponts"
                            onChange={handlerForm}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="justify-content-center" onClick={() => onSubmitForm()}>
                    <p className="text-center d-block points-history-form-buttom mt-0 mb-1" >ACEPTAR</p>
                </Col>
            </Row>
        </Container>
    );
}


export default PointsHistoryForm;