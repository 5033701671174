import React, { createContext, useCallback, useEffect, useState } from "react";
import { HOST_ESCUCHAS_NODE } from "../../../../config/config";
import { useCampaign } from "../../CampaignContext";

const LIMIT_LOGS_PER_PAGE = 20;

const LogCampaignContext = createContext();

const LogCampaignProvider = ({ children }) => {
  const { campaignId } = useCampaign();
  const [overview, setOverview] = useState(null);
  const [logs, setLogs] = useState([]);
  const [isLogsLoading, setIsLogsLoading] = useState(false);
  const [isOverviewLoading, setIsOverviewLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [logsCSV, setLogsCSV] = useState([]);
  const [programSurveysCSV, setProgramSurveysCSV] = useState([]);
  const [isDisableCampaingLoading, setIsDisableCampaingLoading] = useState(false);

  const getCampaignOverview = useCallback(async () => {
    try {
      setIsOverviewLoading(true);
      const response = await fetch(
        `${HOST_ESCUCHAS_NODE}/campaign/getCampaignOverview/${campaignId}`
      );
      const data = await response.json();
      setOverview(data);
      setIsOverviewLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, [campaignId]);

  const getLogs = useCallback(async ({startDate, endDate, currentPage}) => {
    try {
      const query = new URLSearchParams({
        ...(startDate && endDate && { startDate, endDate }),
        page: currentPage,
        limit: LIMIT_LOGS_PER_PAGE,
      });

      setIsLogsLoading(true);
      const response = await fetch(
        `${HOST_ESCUCHAS_NODE}/coincidencia/campaign/${campaignId}?${query.toString()}`
      );
      const data = await response.json();
      setIsLogsLoading(false);
      setLogs(data?.coincidencias ?? []);
      setTotalPages(data.totalPages)
    } catch (error) {
      console.error(error);
    }
  }, [campaignId]);

  const getLogsToExport = useCallback(async ({startDate, endDate}) => {
    try {
      const query = new URLSearchParams({
        ...(startDate && endDate && { startDate, endDate }),
        type: 'all'
      });

      const response = await fetch(
        `${HOST_ESCUCHAS_NODE}/coincidencia/campaign/${campaignId}?${query.toString()}`
      );
      const data = await response.json();
      setLogsCSV(data?.coincidencias ?? []);
    } catch (error) {
      console.error(error);
    }
  }, [campaignId]);

  const getProgramSurveysToExport = useCallback(async ({startDate, endDate}) => {
    try {
      const query = new URLSearchParams({
        ...(startDate && endDate && { startDate, endDate }),
        type: 'all'
      });

      const response = await fetch(
        `${HOST_ESCUCHAS_NODE}/programs-survey?${query.toString()}`
      );
      const data = await response.json();
      setProgramSurveysCSV(data ?? []);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const disableEstudio = useCallback(async () => {
    try {
      setIsDisableCampaingLoading(true);
      await fetch(
        `${HOST_ESCUCHAS_NODE}/campaign/disableCampaign/${campaignId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      alert('Estudio desactivado correctamente');
    } catch (error) {
      console.error(error);
    } finally {
      setIsDisableCampaingLoading(false);
    }
  }, [campaignId])

  useEffect(() => {
    getCampaignOverview();
  }, [getCampaignOverview]);

  useEffect(() => {
    getLogs({currentPage});
  }, [currentPage, getLogs]);

  useEffect(() => {
    if (startDate && endDate ) {
      getLogs({startDate, endDate, currentPage});
      setCurrentPage(1);
    }
  }, [startDate, endDate, getLogs, currentPage]);

  return (
    <LogCampaignContext.Provider
      value={{
        overview,
        isOverviewLoading,
        getCampaignOverview,
        getLogs,
        logs,
        isLogsLoading,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        totalPages,
        setPage: setCurrentPage,
        currentPage,
        getLogsToExport,
        logsCSV,
        disableEstudio,
        isDisableCampaingLoading,
        getProgramSurveysToExport,
        programSurveysCSV
      }}
    >
      {children}
    </LogCampaignContext.Provider>
  );
};

const useLogCampaign = () => {
  const context = React.useContext(LogCampaignContext);
  if (context === undefined) {
    throw new Error("useLogCampaign must be used within a LogCampaignContext");
  }
  return context;
};

export { LogCampaignProvider, LogCampaignContext, useLogCampaign };
