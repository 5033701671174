import React, { Component } from 'react';
import Axios from 'axios';
import  { HostLead} from '../../config/config'
import {  Row, Col, Form } from 'react-bootstrap'
// import Chart from 'chart.js';
import {Bar} from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
// import styled from 'styled-components'

// import * as d3 from 'd3';

// var Chart = require('chart.js');

class second extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersLastDay:0,
            data:{},
            genero:[],
            labels:[],
            hombre:[],
            mujer:[],
            otro:[],
            check:false,
            options:{
                legend: {
                    display:false,
                    labels:{
                        fontSize:20
                    }
                },
                plugins:{
                    datalabels: {

                        borderRadius: 4,
                        color: 'white',
                        font: {
                            weight: 'bold',
                            size:20
                        }
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }
    componentDidMount(){
        this.getLastUsers()
        this.getProvincias()
        this.getCCAA()
    }
    handleChange = event => {
        this.setState({
            [event.target.id] : event.target.value
        });
        this.getLastUsers2(event.target.value, event.target.id)
    }
    getProvincias = async(obj) =>{
        var urlHost = HostLead  + "api/getProvincias";
        var self= this;
        Axios({
            method: 'GET',
            url: urlHost,
        }).then(function(res){
            const data = res.data;
            console.log("👻 ~ second ~ getProvincias=async ~ data:", data);
            self.setState({
                provincias: data
            })
        })

    }
    getCCAA = async(obj) =>{
        var urlHost = HostLead  + "api/getCCAA";
        var self= this;
        Axios({
            method: 'GET',
            url: urlHost,
        }).then(function(res){
            const data = res.data;
            self.setState({
                ccaas: data
            })
        })

    }
    getLastUsers = async()=>{
        const urlHost = HostLead+ "api/registrosQuotas"
        // var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        const self = this;
        Axios({
            method: 'get',
            url: urlHost,
        }).then(function(data){
            var datos = data.data.data;
            // var generos =[]
            var hombres =[]
            var mujeres =[]
            var otros=[]
            // var nomTot = x
            var totalH=0;
            var totalM=0;
        for(var i=0; i<datos.length; i++){
            var x=[0]
            x.push(datos[i]["18-29"])
            x.push(datos[i]["30-44"]);
            x.push(datos[i]["45-64"]);
            x.push(datos[i]["+65"]);
            if(datos[i]["GENERO"] === "HOMBRE"){
                hombres =x;
            }else if(datos[i]["GENERO"] === "MUJER"){
                mujeres=x;
            }else if(datos[i]["GENERO"] === "OTRO"){
                otros=x;
            }
        }
        hombres.forEach((hombre)=> {
            if(hombre !== null){
                totalH= totalH+ parseInt(hombre);
            }

        })
        mujeres.forEach((mujer) =>{
            if(mujer!==null){
                totalM=totalM + parseInt(mujer)
            }
        })
        var arr=[]
        arr.push(totalH + totalM)
        self.setState({
            labels:["Total","18-29","30-44","45-64","+65",],
            hombre:hombres,
            mujer:mujeres,
            otro:otros,
            check:true,
            totalH:totalH,
            totalM:totalM,
            total:totalH+totalM,
            totalarr:arr,
            nomTot:self.state.provincia
        })
        self.Graficos1()
        })
    }
    getLastUsers2 = async(x, y)=>{
        const urlHost = HostLead+ "api/registrosQuotas"
        // var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        const self = this;
        y ==='provincia'?
        Axios({
            method: 'get',
            url: urlHost,
            params: {provincia:x},

        }).then(function(data){
            var datos = data.data.data;
            // var generos =[]
            var hombres =[]
            var mujeres =[]
            var otros=[]
            // var nomTot = x
            var totalH=0;
            var totalM=0;
        for(var i=0; i<datos.length; i++){
            var x=[0]
            x.push(datos[i]["18-29"])
            x.push(datos[i]["30-44"]);
            x.push(datos[i]["45-64"]);
            x.push(datos[i]["+65"]);
            if(datos[i]["GENERO"] === "HOMBRE"){
                hombres =x;
            }else if(datos[i]["GENERO"] === "MUJER"){
                mujeres=x;
            }else if(datos[i]["GENERO"] === "OTRO"){
                otros=x;
            }
        }
        hombres.forEach((hombre)=> {
            if(hombre !== null){
                totalH= totalH+ parseInt(hombre);
            }

        })
        mujeres.forEach((mujer) =>{
            if(mujer!==null){
                totalM=totalM + parseInt(mujer)
            }
        })
        var arr=[]
        arr.push(totalH + totalM)
        self.setState({
            labels:["Total","18-29","30-44","45-64","+65",],
            hombre:hombres,
            mujer:mujeres,
            otro:otros,
            check:true,
            totalH:totalH,
            totalM:totalM,
            total:totalH+totalM,
            totalarr:arr,
            nomTot:self.state.provincia
        })
        self.Graficos1()
        }):
        Axios({
            method: 'get',
            url: urlHost,
            params: {ccaa:x},

        }).then(function(data){
            var datos = data.data.data;
            // var generos =[]
            var hombres =[]
            var mujeres =[]
            var totalH = 0;
            var totalM = 0;
            var otros=[]
        for(var i=0; i<datos.length; i++){
            var x=[0]
            x.push(datos[i]["18-29"])
            x.push(datos[i]["30-44"]);
            x.push(datos[i]["45-64"]);
            x.push(datos[i]["+65"]);
            if(datos[i]["GENERO"] === "HOMBRE"){
                hombres =x;
            }else if(datos[i]["GENERO"] === "MUJER"){
                mujeres=x;
            }else if(datos[i]["GENERO"] === "OTRO"){
                otros=x;
            }
        }
        hombres.forEach((hombre)=> {
            if(hombre !== null){
                totalH= totalH+ parseInt(hombre);
            }

        })
        mujeres.forEach((mujer) =>{
            if(mujer!==null){
                totalM=totalM + parseInt(mujer)
            }
        })
        var arr=[]
        arr.push(totalH + totalM)
        self.setState({
            labels:["Total","18-29","30-44","45-64","+65",],
            hombre:hombres,
            mujer:mujeres,
            otro:otros,
            check:true,
            totalH:totalH,
            totalM:totalM,
            total:totalH+totalM,
            totalarr:arr,
            nomTot:self.state.ccaa
        })
        self.Graficos1()
        })
    }

    Graficos1(){
        this.setState({
            data:{
                labels:this.state.labels,
                datasets:[
                    {
                        label:"Total",
                        data:this.state.totalarr,
                        datalabels: {
                            align: 'center',
                            anchor: 'center'
                        },

                    },
                    {
                        label:"Hombres",
                        backgroundColor:"#084c8c",
                        data:this.state.hombre,
                        datalabels: {
                            align: 'center',
                            anchor: 'center'
                        }
                    },
                    {
                        label:"Mujeres",
                        backgroundColor:"#c859ff",
                        data:this.state.mujer,
                        datalabels: {
                            align: 'center',
                            anchor: 'center'
                        }
                    },
                ]
            },
        })
}
Graficos2(){
  /*  const myChartRef =this.chartRef.current.getContext("2d");
    this.state.canvas.data.labels.pop();
    this.state.canvas.datalabels.forEach(element => {
        element.data.pop()
    });
    this.state.canvas.update();
    new Chart(myChartRef, {
        type:"bar",
        data:{
            labels:this.state.labels,
            datasets:[
                {
                    label:"Hombres",
                    backgroundColor:"#084c8c",
                    data:this.state.hombre,
                    datalabels: {
                        align: 'end',
                        anchor: 'end'
                    }
                },
                {
                    label:"Mujeres",
                    backgroundColor:"#c859ff",
                    data:this.state.mujer,
                    datalabels: {
                        align: 'end',
                        anchor: 'end'
                    }
                },
            ]
        },
        options:{
            legend: {
                display:false
            },
            plugins:{
                datalabels: {
                    backgroundColor:"#006699",
                    borderRadius: 4,
                    color: 'white',
                    font: {
                    weight: 'bold'
                    }
                }
            }
        }
    })*/
}
    render() {
        return (
            <div>

                    <h1>Cuotas Edad y Género</h1>

                <Row>
                    <Row>
                    <Col lg="5">
                            <Form.Group controlId="ccaa" >
                                <Form.Label>Comunidad Autonoma</Form.Label>
                                <Form.Control as="select" custom onChange={this.handleChange.bind(this)} controlId="ccaa" >
                                    {this.state.ccaas? this.state.ccaas.map((ccaa, index)=>{
                                        return <option value={ccaa.ccaa_categorica}>{ccaa.ccaa_categorica}</option>
                                    }):null}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg="5">
                            <Form.Group controlId="provincia" >
                                <Form.Label>Provincia</Form.Label>
                                <Form.Control as="select" custom onChange={this.handleChange.bind(this)} controlId="provincia" >
                                    {this.state.provincias ? this.state.provincias?.map((provincia, index)=>{
                                        return <option value={provincia.prov_categorica}>{provincia.prov_categorica}</option>
                                    }):null}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </Row>
                <Row style={{height:"auto", width:'100%'}}>
                    <Col xs={10}>
                        <div>

                            <Bar
                                data={this.state.data}
                                options={this.state.options}
                            />

                        </div>
                    </Col>
                </Row>
                <Row>
                    <h2>Total <b>{this.state.nomTot}: {this.state.total} || Hombres: {this.state.totalH} || Mujeres {this.state.totalM}</b></h2>
                </Row>
            </div>
        );
    }
}
export default second;