import React, {Component} from 'react'
import {Container,Pagination, Table,Button} from 'react-bootstrap';
import styled from 'styled-components';
import {HOST, HOSTBridge} from '../../config/config';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPenSquare, faPlus} from '@fortawesome/free-solid-svg-icons'
import ModalNewCategory from '../../complements/ModalNewCategory'
import ModalEditCategory from '../../complements/ModalEditCategory'
const Styles = styled.div`
.container{
    text-align:center;
    overflow:hidden;
}
.page-link{
    background-color:#343a40;
    color:white;
    border: 1px solid #dee2e6;

}
&:hover{
    background-color:#3e444a,
    color:white;
    border: 1px solid #dee2e6;

}
paginationItemStyle{
    float:Left;
}
.nuevaEncuesta{
    float:right;
}
.colorStyle{
    color:white;!important
}
.menu{
    background-color:#454d55;
}
`
class encuestasCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cols : ["Id", 'Nombre', 'Descripción', 'Num. Usuarios', ''],
            modalShow:false,
            categoryTotal:0,
            categoryPage:10,
            currentPage:1,
            pages:0,
            categories:[],
         }
         this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        this.getCategories();
    }
    getCategories = async(obj) =>{

        var urlHost = HOSTBridge + "api/getAllStatusCategoriesFormatted?limit=50&offset=0&pageClicked=1&visible_sites=panel-trustsurvey";
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        var self= this;
        Axios({
            method: 'GET',
            url: urlHost,
            headers:{
                "Content-Type": "application/json; charset=utf-8",
				"Authorization": "Bearer " + MYTOKEN.access_token,
            },
            data:{}
        })
        .then(function(res){
            var datos = res.data;
            self.setState({
                categories: datos.content,
                categoryTotal:datos.content.length,

            })

        }).catch(
            function(res){
                console.log("no funciona la obtencion de Categorias")
            }
        )
    }
    handleClick(event){
        this.setState({
            currentPage: Number(event.target.id)
          });
    }
    CategoryEdit(category){
        this.setState({
            id:category.id,
            name:category.name,
            description:category.description,
            number_of_users:category.number,

        })
        this.setState({
            modalShow2 :true,
        })
    }

    render() {
        const pageNumbers =[];
        const categories = this.state.categories;
        for(let i= 1; i <= Math.ceil(this.state.categoryTotal/this.state.categoryPage);i++){
            pageNumbers.push(i);
        }
        const indexOfLastTodo = this.state.currentPage * this.state.categoryPage;
        const indexOfFirstTodo = indexOfLastTodo - this.state.categoryPage;
        const currentTodos = categories.slice(indexOfFirstTodo, indexOfLastTodo);
        const renderEncuestas = currentTodos.map((survey, index) => {
                 //"Id", 'Nombre', 'Descripción', 'Num. Usuarios',''
                const{id, name, description,number_of_users,} = survey;
                return(


                    <tr key={id}>
                        <td>{id}</td>
                        <td>{name}</td>
                        <td>{description}</td>
                        <td>{number_of_users    }</td>
                        <td>
                            <span onClick={() =>  {this.CategoryEdit(survey)}}>
                                <FontAwesomeIcon cursor="pointer" icon={faPenSquare} />
                            </span>
                        </td>
                    </tr>

                )
             })
        const renderPageNumbers = pageNumbers.map(number => {
            return(

                <Pagination.Item
                    className="paginationItemStyle"
                    key={number}
                    id= {number}
                    onClick={this.handleClick}>
                        {number}
                    </Pagination.Item>
            )
        })
        return (
            <Styles>
            <Container className ='container'>
                <div>
                    <Button variant="outline-primary" className="nuevaEncuesta" onClick={() => this.setState({modalShow:true})}>
                        <FontAwesomeIcon icon={faPlus} /> Nueva Categoría</Button>
                    <Table striped bordered hover variant="light">
                        <thead>
                            <tr>
                                {this.state.cols.map((col) => <td key={col}>{col}</td>)}
                            </tr>
                        </thead>
                        <tbody>
                        <ModalEditCategory
                            show={this.state.modalShow2}
                            onHide={() => this.setState({modalShow2:false})}
                            id={this.state.id}
                            description={this.state.description}
                            name={this.state.name}
                            icon_url={this.state.icon_url}
                            user_variable={this.state.user_variable}
                        />
                        <ModalNewCategory
                            show={this.state.modalShow}
                            onHide={() => this.setState({modalShow:false})}
                            id=""
                            description=""
                            name=""
                            icon_url=""
                            user_variable=""
                        />

                        {renderEncuestas}

                        </tbody>



                    </Table>
                    <Pagination className='paginationItemStyle'>

                                {renderPageNumbers}

                    </Pagination>


                </div>
            </Container>
            </Styles>
         );
    }
}

export default encuestasCategory;