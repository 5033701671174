import React, { Component } from "react";
import {
  Container,
  Pagination,
  Table,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import styled from "styled-components";
import { HOSTBridge, HostLead } from "../../config/config";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBan,
  faTimesCircle,
  faEyeSlash,
  faEye,
  faSkull,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

import ModalNewReward from "../../complements/ModalNewReward";
// import ModalEditReward from "../../complements/ModalEditReward";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
const Styles = styled.div`
  .container {
    text-align: center;
  }
  .page-link {
    background-color: #343a40;
    color: white;
    border: 1px solid #dee2e6;
  }
  &:hover {
    color: white;
  }
  paginationItemStyle {
    float: Left;
  }
  .nuevaEncuesta {
    float: right;
  }
`;
class RewardsList extends Component {
  constructor() {
    super();
    this.state = {
      cols: ["Id", "Nombre", "Fecha de Alta", "Estado", "Puntos", "Precio", ""],
      modalShow: false,
      modalShow2: false,
      setModelShow: false,
      rewardsTotal: 0,
      rewardsPage: 20,
      currentPage: 1,
      pages: 0,
      rewards: [],
      reward: [],
      categoria: {},
      categories: [],
    };
    this.handlePaginate = this.handlePaginate.bind(this);
  }
  handlePageNumberClick = (pageNumber) => {
    // Lógica para manejar el cambio de página
    // Actualizaciones de estado aquí si es necesario
    this.handlePaginate(pageNumber);
  };

  handlePaginate(event) {
    this.setState({
      currentPage: Number(event.target.id),
    });
  }
  componentDidMount() {
    this.getRewards();
    this.getRewardsCategory();
  }

  buttonOnclick = async () => {
    await this.getTalentumCatalog();
  };

  getTalentumCatalog = async () => {
    let urlHost = HOSTBridge + "api/getApiTalentum";
    const MYTOKEN = JSON.parse(localStorage.getItem("token"));
    Axios({
      method: "get",
      url: urlHost,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + MYTOKEN.access_token,
      },
      data: {},
    })
      .then(function (res) {
        console.log("getTalentumCatalog ", res);
        window.alert("Catálogo actualizado.");
      })
      .catch(function (res) {
        console.log("Error al actualizar el catálogo");
        window.alert(
          "No se ha podido actualizar el catálogo. Contactar con IT."
        );
      });
  };

  getRewards = async (obj) => {
    var urlHost =
      HOSTBridge +
      "api/recompensasDescriptivas?limit=1000&offset=0&pageClicked=1";
    var MYTOKEN = JSON.parse(localStorage.getItem("token"));
    var self = this;
    Axios({
      method: "get",
      url: urlHost,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + MYTOKEN.access_token,
      },
      data: {},
    })
      .then(function (res) {
        var datos = res.data;
        self.setState({
          rewards: datos.content,
          rewardsTotal: datos.content.length,
        });
      })
      .catch(function (res) {});
  };

  getRewardsCategory = async () => {
    try {
      const urlHost = HostLead + "api/reward/categories";
      const response = await Axios({
        method: "GET",
        url: urlHost,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: {},
      });
      const data = response.data;
      this.setState({
        ...this.state,
        categories: data.items,
      });
    } catch (error) {
      console.log("error get categories", error);
    }
  };

  render() {
    const pageNumbers = [];
    const rewards = this.state.rewards;
    for (
      let i = 1;
      i <= Math.ceil(this.state.rewardsTotal / this.state.rewardsPage);
      i++
    ) {
      pageNumbers.push(i);
    }
    const indexOfLastTodo = this.state.currentPage * this.state.rewardsPage;
    const indexOfFirstTodo = indexOfLastTodo - this.state.rewardsPage;
    const currentTodos = rewards.slice(indexOfFirstTodo, indexOfLastTodo);
    const renderRewards = currentTodos.map((reward, index) => {
      const { id, nombre, fechaAlta, status_reward, puntos, euros } = reward;
      const fecha = moment(fechaAlta).format("DD/MM/YYYY");
      return (
        <tr key={id}>
          <td>{id}</td>
          <td>{nombre}</td>
          <td>{fecha} </td>
          <td>{statusCheck(status_reward)}</td>
          <td>{puntos}</td>
          <td>{euros}</td>
          <td>
            <span
              onClick={() => {
                this.setState({
                  modalShow: true,
                  reward: reward,
                });
              }}
            >
              <FontAwesomeIcon cursor="pointer" icon={faEdit} />
            </span>
          </td>
        </tr>
      );
    });
    const renderPageNumbers = pageNumbers.map((number) => {
      return (
        <Pagination.Item
          className="paginationItemStyle"
          key={number}
          id={number}
          onClick={this.handlePaginate}
        >
          {number}
        </Pagination.Item>
      );
    });
    return (
      <Styles>
        <Button variant="outline-primary" onClick={this.buttonOnclick}>
          Actualizar catálogo
        </Button>
        <Container className="container">
          <Button
            variant="outline-primary"
            className="nuevaEncuesta"
            onClick={() => this.setState({ modalShow: true })}
          >
            <FontAwesomeIcon icon={faPlus} /> Nueva Recompensa
          </Button>
          <Table striped bordered hover variant="light">
            <thead>
              <tr>
                {this.state.cols.map((col) => (
                  <td key={col}>{col}</td>
                ))}
              </tr>
            </thead>
            <tbody>{renderRewards}</tbody>
          </Table>
          <Pagination className="paginationItemStyle">
            {renderPageNumbers}
          </Pagination>

          {this.state.modalShow ? (
            <ModalNewReward
              rewardId={this.state.reward.id}
              show={this.state.modalShow}
              id={this.state.id}
              categories={this.state.categories}
              name={this.state.name}
              registrationDate={this.state.registrationDate}
              status={this.state.status}
              points={this.state.points}
              price={this.state.price}
              userId={this.state.userId}
              onHide={() => this.setState({ modalShow: false })}
              onConfirm={(name, id) => this.SaveAndAssign(name, id)}
            />
          ) : null}

          {/* DEPRECADO */}
          {/* {this.state.modalShow2 && (
                <ModalEditReward
                  categories={this.state.categories}
                  show={this.state.modalShow2}
                  category={this.state.categoria}
                  reward={this.state.reward}
                  onHide={() => this.setState({ modalShow2: false })}
                />
              )} */}
        </Container>
      </Styles>
    );
  }
}

function statusCheck(status) {
  switch (status) {
    case 1:
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">Pendiente de publicar</Tooltip>
          }
        >
          <FontAwesomeIcon icon={faQuestion} />
        </OverlayTrigger>
      );
    case 2:
      return (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">Publicado</Tooltip>}
        >
          <FontAwesomeIcon icon={faEye} />
        </OverlayTrigger>
      );
    case 3:
      return (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">No publicado</Tooltip>}
        >
          <FontAwesomeIcon icon={faEyeSlash} />
        </OverlayTrigger>
      );
    case 4:
      return (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">Pendiente de baja</Tooltip>}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </OverlayTrigger>
      );
    case 5:
      return (
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Baja</Tooltip>}>
          <FontAwesomeIcon icon={faSkull} />
        </OverlayTrigger>
      );
    case 6:
      return (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip-disabled">Desactivado</Tooltip>}
        >
          <FontAwesomeIcon icon={faBan} />
        </OverlayTrigger>
      );
    default:
      return;
  }
}

export default RewardsList;
