import React, {Component} from 'react';
import {Modal, Button, InputGroup, FormControl} from 'react-bootstrap';
import {HOST} from '../config/config';
import Axios from 'axios';

class ModalUser extends Component {
    constructor(props){
        super(props);
        this.state= {
          password : "",
          email:"",
          grant_type :"password",
          scope: "admin",
          isLoggedIn: false,
        }; 
      }
    componentDidCatch(error, errorInfo){
        console.log("Toca el boton de cerrar")
    }
    
    handleChange = event => {
        this.setState({
          [event.target.id] : event.target.value
        });
        
      }

    handleSubmit = event => {
        event.preventDefault();
        this.modificarUsuario();
      }

    crearUsuario = async() => {

            var MYTOKEN = JSON.parse(localStorage.getItem('token'));
            var urlHost = HOST + 'usermanager/v2/superadmin/users';
            const data = {
                    email:this.state.email,
                    password: this.state.password,
                    }           
            
            Axios({
                method:'post',
                url: urlHost,
                headers:{'Content-Type': "application/json",
                    Authorization: 'Bearer ' + MYTOKEN.access_token},
                data:JSON.stringify(data)
            }).then(function(res){
                window.alert("Nuevo usuario registrado")
                window.location.reload();
                
            }).catch(function(res){
                console.log("error de registro")
            })
  }
      

    render() {     
        return ( 
            <Modal
        show= {this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h1>Nuevo Usuario</h1> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <InputGroup className="mb-2" controlId="email">  
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Correo Electrónico</InputGroup.Text>
                    </InputGroup.Prepend>
                    
                    <FormControl
                    id="email"
                    name="email" type="email"
                    aria-label="Correo Electrónico"
                    aria-describedby="basic-addon1"
                    onChange={this.handleChange}
                    
                    />     
            </InputGroup>

            <InputGroup className="mb-2" controlId="password">  
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Contraseña</InputGroup.Text>
                    </InputGroup.Prepend>
                    
                    <FormControl
                    id="password"
                    name="password" type="password"
                    aria-label="Contraseña"
                    aria-describedby="basic-addon1"
                    onChange={this.handleChange}
                    
                    />     
            </InputGroup>
        </Modal.Body>
        <Modal.Footer>
         <Button variant="success" onClick={() => this.crearUsuario()}>Crear Usuario</Button>
        </Modal.Footer>
      </Modal>
         );
    }
}
 
export default ModalUser;