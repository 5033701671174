import React, { useState } from "react";
import "./styles/7th.css";
import Axios from "axios";
import { HostLead } from "../../config/config";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PointsHistoryTable from "../../complements/7th/PointsHistoryTable";
import PointsHistoryForm from "../../complements/7th/PointsHistoryForm";
import { useJsonToCsv } from "react-json-csv";
import { CircularProgress } from "@mui/material";

const { saveAsCsv } = useJsonToCsv();

const Seven = () => {
  const [index, setIndex] = useState(0);
  const [userId, setuserId] = useState();
  const [userEmail, setUseremail] = useState();
  const [userPhone, setUserphone] = useState();
  const [userData, setUserData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const resetData = () => {
    setUserData({});
    setTableData([]);
  };

  const getuserHistoryTableById = async () => {
    setIsLoading(true);
    resetData();
    try {
      if (!!userId) {
        const userResponse = await Axios.get(`${HostLead}api/getuserUserBasicBlockById/${userId}`);
        const data = userResponse.data[0];
        setUserData(data);
        setUseremail(data.email);
        setUserphone(data.phone);
        const historyPointsResponse = await Axios.get(`${HostLead}api/getuserHistoryPoints/${userId}`);
        setTableData(historyPointsResponse.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getuserHistoryTableByEmail = async () => {
    resetData();
    try {
      if (!!userEmail) {
        setIsLoading(true);
        const userResponse = await Axios.get(`${HostLead}api/getuserUserBasicBlockByEmail/${userEmail}`);
        const data = userResponse.data[0];
        setUserData(data);
        setuserId(data.user_id);
        setUserphone(data.phone);
        const historyPointsResponse = await Axios.get(`${HostLead}api/getuserHistoryPoints/${data.user_id}`);
        setTableData(historyPointsResponse.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getuserHistoryTableByPhone = async () => {
    resetData();
    try {
      if (!!userPhone) {
        setIsLoading(true);
        const userResponse = await Axios.get(`${HostLead}api/getuserUserBasicBlockByPhone/${userPhone}`);
        const data = userResponse.data[0];
        setUserData(data);
        setuserId(data.user_id);
        setUseremail(data.email);
        const historyPointsResponse = await Axios.get(`${HostLead}api/getuserHistoryPoints/${data.user_id}`);
        setTableData(historyPointsResponse.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlerIndex = (data) => {
    setIndex(data);
  };

  const handlerTables = () => {
    return tables[index];
  };
  const tables = [
    <PointsHistoryTable
      setTableData={setTableData}
      totalPoints={userData?.credits}
      tableData={tableData}
      handlerIndex={handlerIndex}
    />,
    <PointsHistoryForm
      handlerIndex={handlerIndex}
      user_Id={userData?.user_id}
    />,
  ];

  const handlerdowloadcvs = () => {
    if (tableData.length > 1) {
      const filename = `Historico de puntos usuario ${userData.email}`;
      const fields = {
        survey_id: "Concepto",
        category: "Categoria",
        date: "Fecha",
        points: "SC",
      };
      const data = tableData;
      saveAsCsv({ data, fields, filename });
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>Historico de Puntos:</h1>
        {isLoading && <CircularProgress size={24} />}
      </div>
      <Form>
        <Container>
          <Row>
            <Col className="justify-content-center">
              <Form.Group className="mb-3">
                <Form.Label
                  className="text-center labelFrom"
                  onClick={getuserHistoryTableById}
                >
                  <span role="img" aria-label="magnifying glass">
                    🔎
                  </span>{" "}
                  User_Id
                </Form.Label>
                <Form.Control
                  className="text-center Control-form"
                  value={userId}
                  type="numeric"
                  onChange={(e) => setuserId(e.target.value)}
                  disabled={isLoading}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getuserHistoryTableById();
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col className="justify-content-center">
              <Form.Group className="mb-3">
                <Form.Label
                  className="text-center labelFrom"
                  onClick={getuserHistoryTableByEmail}
                >
                  <span role="img" aria-label="magnifying glass">
                    🔎
                  </span>{" "}
                  Correo Electronico{" "}
                </Form.Label>
                <Form.Control
                  className="text-center Control-form"
                  value={userEmail}
                  type="email"
                  disabled={isLoading}
                  onChange={(e) => setUseremail(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getuserHistoryTableByEmail();
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col className="justify-content-center">
              <Form.Group className="mb-3">
                <Form.Label className="text-center labelFrom">
                <span role="img" aria-label="magnifying glass">
                    🔎
                  </span>{" "}
                  Teléfono
                </Form.Label>
                <Form.Control
                  className="text-center Control-form"
                  value={userPhone}
                  disabled={isLoading}
                  onChange={(e) => setUserphone(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getuserHistoryTableByPhone();
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col className="justify-content-center">
              <Form.Group className="mb-3">
                <Form.Label className="text-center labelFrom">
                  Provincia
                </Form.Label>
                <Form.Label
                  className="text-center d-block label-from-control"
                  type="email"
                >
                  {userData?.ccaa_categorica}
                </Form.Label>
              </Form.Group>
            </Col>
            <Col className="justify-content-center">
              <Form.Group className="mb-3">
                <Form.Label className="text-center labelFrom">
                  Municipio
                </Form.Label>
                <Form.Label
                  className="text-center d-block label-from-control"
                  type="email"
                >
                  {userData?.prov_categorica}
                </Form.Label>
              </Form.Group>
            </Col>
            {/* <Col className="justify-content-center">
              <Form.Group className="mb-3">
                <Form.Label className="text-center labelFrom">
                  Cluster Actividad
                </Form.Label>
                <Form.Label
                  className="text-center d-block label-from-control"
                  type="email"
                ></Form.Label>
              </Form.Group>
            </Col> */}
          </Row>
          {
            <Row className="d-flex justify-content-end mb-1">
              <Button
                variant="link"
                className="button-cvs"
                onClick={handlerdowloadcvs}
              >
                ↡ DESCARGA CVS
              </Button>
            </Row>
          }
          <Row>{handlerTables(0)}</Row>
        </Container>
      </Form>
    </div>
  );
};

export default Seven;
