import React, { useEffect, useMemo, useState } from "react";
import FileDropZone from "../../../components/form/FileDropZone";
import Axios from "axios";
import { HostLead } from "../../../config/config";
import { useCampaign } from "../CampaignContext";

const API_PATH = {
  activate: `activateUsersByFile`,
  deactivate: `deactivateUsersByFile`,
}

function FileDropZoneCSV({type = "ACTIVATE", disabled = false}) {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const {getUsersByCampaign} = useCampaign();

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage("");
      }, 4000);
    }

    return () => {
      clearTimeout();
    };
  }, [message]);

  const handleSubmit = async (file) => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const formData = new FormData();
      formData.append("users", file);

      await Axios.post(
        `${HostLead}api/escuchas/${API_PATH[type.toLowerCase()]}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer" + token.access_token,
          },
        }
      );
      setError(false);
      setMessage("Archivo Cargo Correctamente");
      getUsersByCampaign();
    } catch (error) {
      setError(true);
      setMessage("Error al cargar el archivo");
    }
  };

  return (
    <FileDropZone
      onSubmit={handleSubmit}
      error={error}
      label={message ?? "Arrastra el archivo CSV aquí"}
      disabled={disabled}
    />
  );
}

export default FileDropZoneCSV;
