export const CAMPAIGN_STATUS = {
  STARTED: "STARTED",
  FINISHED: "FINISHED",
};

export const SURVEY_ICONS = [
  {
    nombre: "Alimentación-dietetica",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/alimentacion-dietetica.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/Alimentacion-dietetica.png",
  },
  {
    nombre: "Coches",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/transportes-coches.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/coches.png",
  },
  {
    nombre: "e-Comerce",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/compras-online.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/Comercio+electronico.png",
  },
  {
    nombre: "Compras y consumo",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/compras.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/compras-consumo.png",
  },
  {
    nombre: "Cosméticos",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/cosmetica.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/cosmeticos.png",
  },
  {
    nombre: "Deportes",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/deporte.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/deportes.png",
  },
  {
    nombre: "Finanzas",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/economia.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/finanzas.png",
  },
  {
    nombre: "Hogar",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/hogar.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/hogar.png",
  },
  {
    nombre: "Infantil",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/infantil.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/infantil.png",
  },
  {
    nombre: "Inmobiliaria",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/inmobiliaria.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/inmobiliaria.png",
  },
  {
    nombre: "Innovación",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/innovacion.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/innovacion.png",
  },
  {
    nombre: "Mascotas",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/animales.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/mascotas.png",
  },
  {
    nombre: "Movilidad",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/movilidad.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/movilidad.png",
  },
  {
    nombre: "Multimedia",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/multimedia.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/multimedia.png",
  },
  {
    nombre: "Ocio-Cultura",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/ocio-cultura.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/Ocio-cultura.png",
  },
  {
    nombre: "Politica",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/politica.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/politica.png",
  },
  {
    nombre: "Salud",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/salud.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/salud.png",
  },
  {
    nombre: "Seguros",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/seguros.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/seguros.png",
  },
  {
    nombre: "Suministros",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/suministros.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/suministros.png",
  },
  {
    nombre: "Telefonia",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/telefonia-internet.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/telefonia.png",
  },
  {
    nombre: "Viajes",
    url: "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/WhiteIcons/viajes.png",
    url_blue:
      "https://panel-export-surveys-csv-files.s3-eu-west-1.amazonaws.com/Recursos/BlueIcons/viajes.png",
  },
];

export const NEW_SURVEY_ICONS = [
  {
    name: 'Accesibilidad',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Accesibilidad.png',
  },
  {
    name: 'Amor',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Amor.png'
  },
  {
    name: 'Arte',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Arte.png'
  },
  {
    name: 'Bienestar',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Bienestar.png'
  },
  {
    name: 'Ciencia',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Ciencia.png'
  },
  {
    name: 'Ciudad',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Ciudad.png'
  },
  {
    name: 'Diversidad',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Diversidqad.png'
  },
  {
    name: 'Educación',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Educacio%CC%81n.png'
  },
  {
    name: 'Energía',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Energi%CC%81a.png'
  },
  {
    name: 'Flash',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Flash.png'
  },
  {
    name: 'Genérica 1',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Gene%CC%81rica+1.png'
  },
  {
    name: 'Genérica 2',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Gene%CC%81rica+2.png'
  },
  {
    name: 'Genérica 3',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Gene%CC%81rica+3.png'
  },
  {
    name: 'Genérica 4',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Gene%CC%81rica+4.png'
  },
  {
    name: 'Genérica 5',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Gene%CC%81rica+5.png'
  },
  {
    name: 'Genérica 6',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Gene%CC%81rica+6.png'
  },
  {
    name: 'Intereses',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Intereses.png'
  },
  {
    name: 'Juego',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Juego.png'
  },
  {
    name: 'Justicia',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Justicia.png'
  },
  {
    name: 'Naturaleza',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Naturaleza.png'
  },
  {
    name: 'Oficial',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Oficial.png'
  },
  {
    name: 'Opinión 1',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Opinio%CC%81n+1.png'
  },
  {
    name: 'Opinión 2',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Opinio%CC%81n+2.png'
  },
  {
    name: 'OTT',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/OTT.png'
  },
  {
    name: 'Premium 1',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Premium+1.png'
  },
  {
    name: 'Premium 2',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Premium+2.png'
  },
  {
    name: 'Radio 2',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Radio2.png'
  },
  {
    name: 'RRSS',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/RRSS.png'
  },
  {
    name: 'Trabajo',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Trabajo.png'
  },
  {
    name: 'Transporte 1',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Transporte+1.png'
  },
  {
    name: 'Transporte 2',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Transporte+2.png'
  },
  {
    name: 'Transporte 3',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Transporte+3.png'
  },
  {
    name: 'Transporte 4',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Transporte+4.png'
  },
  {
    name: 'Transporte 5',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Transporte+5.png'
  },
  {
    name: 'Videojuegos',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Videojuegos.png'
  },
  {
    name: 'Votación 2',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Votacio%CC%81n+2.png'
  },
  {
    name: 'Web',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/survey_icons/Web.png'
  },

]

export const PERFILADO_ICONS = [
  {
    name: 'Automóvil',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/Automo%CC%81vil.jpg'
  },
  {
    name: 'Banco',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/Banco.jpg'
  },
  {
    name: 'Cosmética',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/Cosme%CC%81tica.jpg'
  },
  {
    name: 'Deporte',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/Deporte.jpg'
  },
  {
    name: 'Dietas',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/Dietas.jpg'
  },
  {
    name: 'Heliodoro',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/HELIODORO.jpg'
  },
  {
    name: 'LUKA',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/LUKA.jpg'
  },
  {
    name: 'Mascotas',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/Mascotas.jpg'
  },
  {
    name: 'Motos',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/Motos.jpg'
  },
  {
    name: 'NIKOLA',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/NIKOLA.jpg'
  },
  {
    name: 'Ocio',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/Ocio.jpg'
  },
  {
    name: 'Primera',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/Primera.jpg'
  },
  {
    name: 'RRSS',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/RRSS.jpg'
  },
  {
    name: 'Seguros',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/Seguros.jpg'
  },
  {
    name: 'Telefonía',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/Telefoni%CC%81a.jpg'
  },
  {
    name: 'Televisión',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/Televisio%CC%81n.jpg'
  },
  {
    name: 'THIAGO',
    url: 'https://temp-terms-files.s3.eu-west-1.amazonaws.com/perfilado_icons/THIAGO.jpg'
  },
]