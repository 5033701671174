import React, { useState } from "react";
import { Box } from "@mui/material";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import UsersTab from "./tabs/usersTab/usersTab";
import LogStudio from "./tabs/logCampaign/logCampaign";
import "./styles.css";
import { CampaignProvider } from "./CampaignContext";
import { LogCampaignProvider } from "./tabs/logCampaign/LogCampaingContext";
import { CampaignsProvider } from "../campaigns/CampaignsContext";

function CampaignDetail() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <CampaignsProvider>
      <CampaignProvider>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Usuarios" {...a11yProps(0)} />
            <Tab label="Log Estudio" {...a11yProps(1)} />
            <Tab label="Métricas" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <UsersTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LogCampaignProvider>
            <LogStudio />
          </LogCampaignProvider>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <h2>Métricas</h2>
        </TabPanel>
      </CampaignProvider>
    </CampaignsProvider>
  );
}

export default CampaignDetail;

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}
