import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';
// import {HOST,MYTOKEN} from '../config/config';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import { CSVReader }  from 'react-papaparse'

class ModalNewDataChart extends Component {
    constructor(props){
        super(props);
        this.state= {
        }; 
    }
    componentDidCatch(error, errorInfo){
        console.log("Toca el boton de cerrar")
    }
    
    handleChange = event => {
        this.setState({
            [event.target.id] : event.target.value
        });
        
    }
    handleSubmit = event => {
        event.preventDefault();
    }
    handleCheckChange =(evt) => {
        if(evt.target.checked ){
            this.setState({ [evt.target.id]: 1})
        }else{
            this.setState({ [evt.target.id]: 0})
        }
    }
    handleOnDrop = (data) => {
        
        console.log(data);
            var urlHost = "http://localhost:1070/apielmundo/updateNewDataChart";
            var MYTOKEN = JSON.parse(localStorage.getItem('token'));
            // var self= this;
            Axios({
                method: 'PUT',
                url: urlHost,
                headers:{
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": "Bearer " + MYTOKEN.access_token,
                },  
                data:{
                    id_chart_list:this.props.id_chart_list,
                    data:data
                },
            })
            .then(function(res){
                console.log("subido");
                
            }).catch(
                function(res){
                    console.log("Problemas")
                }
            )
    }
    
    borradoNewChart = () =>{
        var urlHost = "http://localhost:1070/apielmundo/deleteNewChart";
            var MYTOKEN = JSON.parse(localStorage.getItem('token'));
            // var self= this;
            Axios({
                method: 'delete',
                url: urlHost,
                headers:{
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": "Bearer " + MYTOKEN.access_token,
                },  
                data:{
                    id_chart_list:this.props.id_chart_list
                },
            })
            .then(function(res){
                this.props.onHide2()
            }).catch(
                function(res){
                    console.log("Problemas")
                }
            )
    }

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }

    handleOnRemoveFile = (data) => {
        
    }
    render() { 
        return ( 
            <Modal
                show= {this.props.show}
                onHide={() => this.borradoNewChart}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                    <h1>Grafico: {this.props.name} +  ID: + {this.props.id_chart_list}  </h1> 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        style={{}}
                        config={{header:true,encoding: "ISO-8859-1",}}
                        
                        addRemoveButton
                        onRemoveFile={this.handleOnRemoveFile}
                        >
                        <span>Arrastra tu Csv</span>
                    </CSVReader>

                </Modal.Body>
                <Modal.Footer>
                <Button variant="warning" >
                    <FontAwesomeIcon icon={faExclamationTriangle} /> Borrar Gráfico <FontAwesomeIcon icon={faExclamationTriangle} /></Button>
                <Button variant="success" onClick={() => this.props.onHide()}>Guardar Cambios</Button>
                </Modal.Footer>
            </Modal>
    );
    }
}

export default ModalNewDataChart;