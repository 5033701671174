import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';
import { HostLead } from '../config/config';
import Axios from 'axios';
// import moment from 'moment'
// import Compress from 'compress.js'
// import ModalImage from './ModalImage'
import { CSVReader }  from 'react-papaparse'
// import { useJsonToCsv } from 'react-json-csv';
//   const { saveAsCsv } = useJsonToCsv();
class ModalCustomAssing extends Component {
    constructor(props){
        super(props);
        this.state= {
            perfilado:0,
            trustsurvey:0,
            modalShow:false,
            image:null,
            allCategories:[],
            totalRespuestas:[0],
            totalPanelistas:[0],
            fields2:{
                "email" : "email",
                "user_id" : "user_id",
                "URL" :"URL",
                "Gandia" : "GANDIA",
                "name" : "name",
                
            },

        }; 
    }
    componentDidMount() {
        console.log(this.props.surveyId)
    }
    handleChange = event => {
        this.setState({
            [event.target.id] : event.target.value
        });
        
    }

    handleOnDrop = async(data) => {
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        var urlHost = HostLead +'api/assignCustom';
        // var self= this;
        await Axios({
            method: 'POST',
            url: urlHost,
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + MYTOKEN.access_token,
            }, 
            data:{
                surveyId:this.state.id,
                data:data
            },
        })
        .then(function(res){
            alert("Se han insertado "+ res.data + " usuarios." )
            
        }).catch(
            function(res){
                console.log("Problemas")
            }
        )
}
    
    obtenerEncuesta(){
        const data = JSON.parse(localStorage.getItem('dataSurvey'));
        if(!data){
            
        }else if( this.state.id !== data.id ) { 
            this.setState({
                id:data.id,
                name:data.name,
                category_id:data.category_id,
                start_date:data.start_date,
                end_date:data.end_date,
                credits:data.credits,
                trustsurvey:data.trust_survey,
                perfilado:data.perfilado,
                survey_key:data.survey_key,

            })
            }
    }

    render() {    
        this.obtenerEncuesta();
        return ( 
            <Modal
                show= {this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
            <Modal.Header>
                <Modal.Title id="">
                    <div>Asignación de usuarios a la encuesta: {this.state.name} id: {this.state.id}</div>  
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2>Requisitos:</h2>
                    <p> Se necesita introducir un CSV con la lista de user_id de los usuarios a los que se le quiere asignar la encuesta.</p>
                    <CSVReader
                            onDrop={this.handleOnDrop}
                            onError={this.handleOnError}
                            style={{}}
                            config={{header:true,encoding: "ISO-8859-1",}}
                            
                            addRemoveButton
                            onRemoveFile={this.handleOnRemoveFile}
                            >
                            <span>Arrastra tu Csv o Pincha aqui</span>
                        </CSVReader>
            </Modal.Body>
            <Modal.Footer>
                
                <Button variant="danger" onClick={this.props.onHide}>Cerrar</Button>
                
            </Modal.Footer>
            
            </Modal>

        );
    }
}
export default ModalCustomAssing;