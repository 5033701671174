import React, { Component } from "react";
import { Container, Row, Form, Button } from "react-bootstrap";
import styled from "styled-components";
import logo from "./complements/logo.png";
import Axios from "axios";
import { TOKEN_KEY } from "./authentification/Auth";
import { HOST } from "./config/config";
const Styles = styled.div`
  .formulario {
    position: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
  }
  .container {
  }
  .logo {
    margin: 5%;
  }
  .bg-color {
    background: rgba(95, 166, 217, 1);
    position: relative;
    z-index: 1;
    width: 460px;
    margin: 25%;
    padding: 40px;
    text-align: center;
    box-shadow: 0 0 20px rgba(500, 500, 500, 0.9);
    border-radius: 20px;
    border: 1px dotted wheat;
  }
`;
class Login extends Component {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
    this.state = {
      username: "",
      password: "",
      grant_type: "password",
      scope: "admin",
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    localStorage.clear();
  }

  postCredencials = async () => {
    localStorage.clear();
    const data = new URLSearchParams();
    data.append("username", this.state.username);
    data.append("password", this.state.password);
    data.append("grant_type", this.state.grant_type);
    data.append("scope", this.state.scope);
    try {
      await Axios({
        method: "post",
        url: HOST + "auth/oauth/token",
        data: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic YnJvd3Nlcjo=",
        },
      }).then((resp) => {
        console.log("Loggeado");

        localStorage.setItem("token", JSON.stringify(resp.data));
      });

      localStorage.setItem(TOKEN_KEY, "OK");
      this.routeChange();
    } catch (error) {
      alert("Correo electrónico/Contraseña invalidos");
    }
  };

  routeChange = () => {
    let path = `/home`;
    this.props.history.push(path);
  };
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.postCredencials();
  };

  render() {
    return (
      <Styles>
        <Container className="container">
          <Row className="justify-content-md-center">
            <div className="bg-color formulario">
              <Form onSubmit={this.handleSubmit}>
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                <Form.Group controlId="username">
                  <Form.Label>Correo electronico</Form.Label>
                  <Form.Control
                    type="username"
                    name="username"
                    value={this.state.username}
                    onChange={this.handleChange}
                    placeholder="email"
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    placeholder="password"
                  />
                </Form.Group>
                <Button variant="secondary" type="submit">
                  Acceder
                </Button>
              </Form>
            </div>
          </Row>
        </Container>
      </Styles>
    );
  }
}

export default Login;
