import React from "react";
import styled from "styled-components";
import StudyCard from "./components/CampaignCard";
import { Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useCampaigns } from "./CampaignsContext";

const GridContainer = styled(Row)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 12px;
  margin-top: 20px;
`;

function CampaignsList() {
  const history = useHistory();

  const { campaigns } = useCampaigns();

  const handleGoToStudy = (id) => {
    history.push(`/estudio/${id}`);
  };

  return (
    <GridContainer>
      {campaigns.map((campaign) => (
        <StudyCard
          key={campaign.id}
          name={campaign.name}
          date={new Date(campaign.created_at).toDateString()}
          onClick={() => handleGoToStudy(campaign.id)}
        />
      ))}
    </GridContainer>
  );
}

export default CampaignsList;
