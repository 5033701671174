import React from 'react';
import {Container} from 'react-bootstrap';
import styled from 'styled-components';

const MainContainer = styled(Container)`
    padding: 0px;
    margin-bottom: 76px;
`;

export const Layout = (props) => (
    <MainContainer>
        {props.children}
    </MainContainer>
);
