import {HOST, HOSTBridge} from '../config/config';

var JsUtils = (function(param){
    var _ajaxCall = function(set) {
        var urlHost = set.defaultHost ? HOST : HOSTBridge;
        var dataValue = set.data;
        var methodValue = set.method;
        var contentType = set.contentType || 'application/json; charset=UTF-8';
        var params = set.params;
        var urlCall = set.urlUsers ? set.urlUsers : urlHost + set.url;
        var urlPattern = set.urlPattern;

        if (methodValue === 'POST' || methodValue === 'PUT') {
            dataValue = set.params;
        } else {
            if (params) {
                urlCall = urlCall + '?' + params;
            }

            if (urlPattern) {
                urlCall = urlHost + urlPattern;
            }
        }

        var setting = {
            method: methodValue || 'GET',
            contentType: contentType,
            url: urlCall,
            data: dataValue
        };

        if (localStorage.getItem('token') !== null) {
            var myToken = JSON.parse(localStorage.getItem('token'));

            setting.headers = {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + myToken.access_token
            };
        }
        return fetch(setting);
    };

    return{
        ApiCall : _ajaxCall,
    }
}
)();

export const removeTZ = (date) => {
    return date.replace("T", " ").replace("Z", "").replace("n", "");
};

export default JsUtils;