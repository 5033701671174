import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import styled from "styled-components";

const Container = styled.div`
  margin-bottom: 16px;
`;

const fileTypes = ["CSV"];

function FileDropZone({ onSubmit, error, label, disabled }) {
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (file) {
      setTimeout(() => {
        setFile(null);
      }, 4000);
    }

    return () => {
      clearTimeout();
    };
  }, [file]);

  const handleChange = (file) => {
    setFile(file);
    onSubmit(file);
  };

  return (
    <Container>
      <FileUploader
        disabled={disabled}
        classes={`drop_zone ${isDragging ? "drop_dragging" : ""}`}
        hoverTitle="Suelte el archivo aquí"
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        fileOrFiles={file}
        dropMessageStyle={{
          opacity: 0,
        }}
        onDraggingStateChange={(value) => {
          setIsDragging(value);
        }}
      >
        {file ? (
          <Typography variant="body2">
            <span
              style={{
                textDecoration: "underline",
              }}
            >
              {file.name}
            </span>{" "}
            ha sido cargado correctamente
          </Typography>
        ) : (
          <Typography variant="body2">Arrastra tu Csv o Pincha aqui</Typography>
        )}
      </FileUploader>
      {error ? (
        <Typography variant="body2" color="error">
          {label}
        </Typography>
      ):
      <Typography variant="body2" style={{
        color: 'rgba(24, 169, 77, 1)'
      }}>
          {label}
        </Typography>}
    </Container>
  );
}

export default FileDropZone;
