/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Typography } from "@mui/material";
import { Row, Container, Col, Alert } from "react-bootstrap";
import FileDropZoneCSV from "../../components/FileDropZoneCSV";
import SearchInput from "./components/SearchInput";
import UsersTable from "./components/UsersTable";
import ChangeStatusForm from "./components/ChangeStatusForm";
import { useJsonToCsv } from "react-json-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "react-bootstrap/Spinner";
import {
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { useCampaign } from "../../CampaignContext";
import { useCampaigns } from "../../../campaigns/CampaignsContext";
import { CAMPAIGN_STATUS } from "../../../../config/constants";

function UsersTab() {
  const { saveAsCsv } = useJsonToCsv();
  const {campaigns} = useCampaigns();
  const { loading, campaignId } = useCampaign();
  const currentCampaign = campaigns.find(c => c.id === parseInt(campaignId));
  const disabledForms = currentCampaign?.status === CAMPAIGN_STATUS.FINISHED

  const downloadCSVSample = () => {
    const filename = `Fichero_ejemplo_activar_usuarios`;
    const fields = {
      user_id: "userId",
      estudio_id: "estudioId",
    };

    const data = [
      {
        user_id: 123,
        estudio_id: 2,
      },
      {
        user_id: 234,
        estudio_id: 1,
      },
    ];

    saveAsCsv({ data, fields, filename });
  };

  return (
    <>
      <Container>
        <Row>
          <Col xs={7}>
            <div style={{ marginBottom: "20px", display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }} >
              <Typography variant="h5">Usuarios</Typography>
              {loading && <Spinner animation="border" variant="primary" size="sm" />}
            </div>

            <SearchInput />
            <UsersTable disabledActions={disabledForms} />
          </Col>
          <Col xs={5}>
            {disabledForms && (
              <Alert variant="info">
                El estudio ha finalizado y no se pueden activar usuarios
              </Alert>
            )}
            <div>
              <Typography variant="h5">
                Activar usuarios mediante el archivo csv
              </Typography>
              <Typography variant="body2">
                Se necesita introducir un CSV con la lista de user_id de los
                usuarios a los que se le quiere activar. Descarga el <a href="#" onClick={(event) => {
                  event.preventDefault();
                  downloadCSVSample();
                }}>Archivo de ejemplo <FontAwesomeIcon icon={faDownload} /></a>
              </Typography>

              <FileDropZoneCSV type="ACTIVATE" disabled={disabledForms} />
            </div>

            <ChangeStatusForm disabled={disabledForms} />
            <div>
              <Typography variant="h5">
                Desactivar usuarios mediante el archivo csv
              </Typography>
              <Typography variant="body2">
                Se necesita introducir un CSV con la lista de user_id de los
                usuarios a los que se le quiere desactivar.
              </Typography>

              <FileDropZoneCSV type="DEACTIVATE" disabled={disabledForms} />

              <ChangeStatusForm type="DEACTIVATE" disabled={disabledForms} />

            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UsersTab;
