import React, { Component } from "react";
import Axios from "axios";


import { HostNode } from "../../config/config";
import { Card, Button,Form } from "react-bootstrap";
import { DateTimePicker } from "@material-ui/pickers";
import { useJsonToCsv } from "react-json-csv";
import { CSVReader } from "react-papaparse";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";

//const { saveAsCsv } = useJsonToCsv();

const cardStyle = {
  width: "18rem",
  textAlign: "center",
  padding: "2em",
  margin: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const contactMethodOptions = [
  { label: 'WhatsApp', value: 'WHATSAPP' },
  { label: 'Teléfono', value: 'TELEFONO' },
  { label: 'SMS', value: 'SMS' },
  { label: 'Email', value: 'EMAIL' },
];


class first extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersLastDay: 0,
      data: {},
      registros: 0,
      loading: false,
      fields: {
        user_id: "user_id",
        email: "email",
        name: "name",
        first_surname: "first_surname",
        second_surname: "second_surname",
        Estado: "Estado",
        credits: "Sigcoins",
        Creado: "Creado",
        FechaNacimiento: "FechaNacimiento",
        Genero: "Genero",
        ccaa_categorica: "Comunidad Autonoma",
        prov_categorica: "Provincia",
      },
      fieldsOrigen: {
        user_id: "user_id",
        email: "email",
        origen: "origen",
        created_at: "created_at",
        Hora: "Hora",
        Creado: "Creado",
      },
      fieldsNull: {
        email: "email",
        Fecha: "Fecha",
        Baja: "Baja",
        origen: "origen",
        extra: "extra",
        Creado: "Creado",
      },
      fieldSorteo: {
        user_id: "user_id",
      },
      fieldsEU: {
        user_id: "user_id",
        origen: "origen",
        encuestas: "encuestas",
        created_at: "created_at ",
      },
      fieldsSc: {
        user_id: "user_id",
        email: "email",
        survey_id: "survey_id",
        points: "points",
        reward: "reward",
        date: "date",
        origen: "origen",
      },
      dateFromSc: new Date(),
      dateToSc: new Date(),
      selectedMethods: [], // State to manage selected contact methods
    };
  }
  

  getallUsers = async () => {
    const urlHost = HostNode + "api/v2/dailyUsers";
    const { saveAsCsv } = useJsonToCsv();
    const self = this;
    const token = JSON.parse(localStorage.getItem("token"));
    Axios({
      method: "get",
      url: urlHost,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + token.access_token,
      },
    }).then(function (res) {
      const filename = `Usuarios actualizados a dia ${new Date()}`;
      const fields = self.state.fields;

      const data = res.data;

      saveAsCsv({ data, fields, filename });
    });
  };
  getallUsersOrigenes = async () => {
    const urlHost = HostNode + "api/v2/dailyOrigen";
    const { saveAsCsv } = useJsonToCsv();
    const self = this;
    const MYTOKEN = JSON.parse(localStorage.getItem("token"));
    let from;
    let to;
    if (this.state.dateFrom && this.state.dateTo) {
      from = moment(this.state.dateFrom).format("YYYY-MM-DDTHH:mm:ss");
      to = moment(this.state.dateTo).format("YYYY-MM-DDTHH:mm:ss");
    }

    Axios({
      method: "get",
      params: {
        dateFrom: from,
        dateTo: to,
      },
      url: urlHost,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + MYTOKEN.access_token,
      },
    }).then(function (res) {
      const filename = `Usuarios Origenes a dia ${new Date()}`;
      const fields = self.state.fieldsOrigen;

      const data = res.data;

      saveAsCsv({ data, fields, filename });
    });
  };
  getUEWeekly = async () => {
    const urlHost = HostNode + "api/v2/weeklySurveysDone";
    const { saveAsCsv } = useJsonToCsv();
    const MYTOKEN = JSON.parse(localStorage.getItem("token"));
    const self = this;
    let from;
    let to;
    if (this.state.dateFromUE && this.state.dateToUE) {
      from = moment(this.state.dateFromUE).format("YYYY-MM-DDTHH:mm:ss");
      to = moment(this.state.dateToUE).format("YYYY-MM-DDTHH:mm:ss");
    }
    Axios({
      method: "get",
      params: {
        dateFrom: from,
        dateTo: to,
      },
      url: urlHost,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + MYTOKEN.access_token,
      },
    }).then(function (res) {
      const filename = `Usuariso y encuestas realizadas ${new Date()}`;
      const fields = self.state.fieldsEU;

      const data = res.data.data;

      saveAsCsv({ data, fields, filename });
    });
  };
  getScWeekly = async () => {
    const urlHost = HostNode + "api/v2/weeklySigcoins";
    const { saveAsCsv } = useJsonToCsv();
    const MYTOKEN = JSON.parse(localStorage.getItem("token"));
    const self = this;
    let from;
    let to;
    if (this.state.dateFromSc && this.state.dateToSc) {
      from = moment(this.state.dateFromSc).format("YYYY-MM-DDTHH:mm:ss");
      to = moment(this.state.dateToSc).format("YYYY-MM-DDTHH:mm:ss");
    }

    Axios({
      method: "get",
      url: `${urlHost}${from && to ? `?dateFrom=${from}&dateTo=${to}` : ""}`,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + MYTOKEN.access_token,
      },
    }).then(function (res) {
      const filename = `Historial de sigcoins ${new Date()}`;
      const fields = self.state.fieldsSc;

      const data = res.data.data;
      saveAsCsv({ data, fields, filename });
    });
  };
  handleFromDate = async (event) => {
    this.setState({
      dateFrom: event,
    });
  };
  handleToDate = async (event) => {
    this.setState({
      dateTo: event,
    });
  };
  handleFromDateUE = async (event) => {
    this.setState({
      dateFromUE: event,
    });
  };
  handleToDateUE = async (event) => {
    this.setState({
      dateToUE: event,
    });
  };
  handleFromDateSc = async (event) => {
    this.setState({
      dateFromSc: event,
    });
  };
  handleToDateSc = async (event) => {
    this.setState({
      dateToSc: event,
    });
  };
  handleFromDateReward = async (event) => {
    this.setState({
      dateFromReward: event,
    });
  };
  handleToDateReward = async (event) => {
    this.setState({
      dateToReward: event,
    });
  };

  handleOnDrop = async (data) => {
    try {
      const csvData = data
        .filter((elem) => elem.data.user_id)
        .map((elem) => elem.data);
      if (
        csvData.filter(
          (elem) => !elem.user_id || !elem.survey_id || !elem.points
        ).length > 0
      ) {
        alert(
          "El formato del csv es incorrecto, debe tener al menos tres columnas: user_id, surver_id y points!!!"
        );
      } else {
        this.setState({ data: csvData });
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleOnError = (err, file, inputElem, reason) => {
    alert("No se ha podido subir: \n razon :" + reason);
  };

  handleOnRemoveFile = () => {
    this.setState({ data: [] });
  };

  addPoints = () => {
    const MYTOKEN = JSON.parse(localStorage.getItem("token"));
    const urlHost = HostNode + "api/v2/addPoints";
    if (Object.keys(this.state.data).length === 0) {
      alert("Debe importar un archivo para poder ejecutar esta función!!");
    } else {
      this.setState({ loading: true });
      Axios({
        method: "post",
        url: urlHost,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + MYTOKEN.access_token,
        },
        data: {
          data: this.state.data,
        },
      })
        .then((res) => {
          this.setState({ loading: false });
          this.handleOnRemoveFile();
          return alert("Puntos cargados con éxito!!");
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.handleOnRemoveFile();
          return alert(`Ha ocurrido un error durante la carga --> ${err}`);
        });
    }
  };

  downloadCSVSample = () => {
    const filename = `Fichero_ejemplo_carga_puntos`;
    const { saveAsCsv } = useJsonToCsv();
    const fields = {
      user_id: "user_id",
      survey_id: "survey_id",
      points: "points",
    };

    const data = [
      {
        user_id: 11225,
        survey_id: "NOVA",
        points: 65,
      },
      {
        user_id: 11239,
        survey_id: "NOVA",
        points: 65,
      },
      {
        user_id: 11239,
        survey_id: "ROBERTO",
        points: 40,
      },
    ];

    saveAsCsv({ data, fields, filename });
  };

  getallUsersNullTLW = async () => {
    const urlHost = HostNode + "api/v2/dailyNull";
    const { saveAsCsv } = useJsonToCsv();
    const MYTOKEN = JSON.parse(localStorage.getItem("token"));
    const self = this;
    Axios({
      method: "get",
      url: urlHost,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + MYTOKEN.access_token,
      },
    }).then(function (res) {
      const filename = `Nulls de TLW a dia ${new Date()}`;
      const fields = self.state.fieldsNull;

      const data = res.data;

      saveAsCsv({ data, fields, filename });
    });
  };
  getSorteo = async () => {
    const urlHost = HostNode + "api/v2/sorteo";
    const { saveAsCsv } = useJsonToCsv();
    const self = this;
    let from;
    let to;
    const MYTOKEN = JSON.parse(localStorage.getItem("token"));
    if (this.state.dateFromRaffle && this.state.dateToRaffle) {
      from = moment(this.state.dateFromRaffle).format("YYYY-MM-DDTHH:mm:ss");
      to = moment(this.state.dateToRaffle).format("YYYY-MM-DDTHH:mm:ss");
    }
    Axios({
      method: "get",
      params: {
        dateFrom: from,
        dateTo: to,
      },
      url: urlHost,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + MYTOKEN.access_token,
      },
    }).then(function (res) {
      const filename = `Usuarios Sorteo ${new Date()}`;
      const fields = self.state.fieldSorteo;
      const data = res.data.rows;
      saveAsCsv({ data, fields, filename });
    });
  };
  getListaNavidad = async () => {
    const urlHost = HostNode + "api/v2/getChristmasList";
    const { saveAsCsv } = useJsonToCsv();
    // const self = this;

    Axios({
      method: "get",
      url: urlHost,
    }).then(function (res) {
      const filename = `Lista Navidad`;
      const fields = { user_id: "user_id", link: "link", email: "email" };
      const data = res.data;
      saveAsCsv({ data, fields, filename });
    });
  };

  getOrderReward = async () => {
    const MYTOKEN = JSON.parse(localStorage.getItem("token"));
    const urlHost = HostNode + "api/v2/getOrderRewards";
    let from;
    let to;
    if (this.state.dateFromReward && this.state.dateToReward) {
      from = moment(this.state.dateFromReward).format("YYYY-MM-DDTHH:mm:ss");
      to = moment(this.state.dateToReward).format("YYYY-MM-DDTHH:mm:ss");
    }

    Axios({
      method: "get",
      params: {
        dateFrom: from,
        dateTo: to,
      },
      url: urlHost,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + MYTOKEN.access_token,
      },
    }).then(function (res) {
      const today = new Date();
      const { saveAsCsv } = useJsonToCsv();
      const filename = `Lista_pedidos_${today}`;
      const fields = {
        user_id: "user_id",
        reward_id: "reward_id",
        numero: "numero",
        fecha: "fecha",
        delegacion: "delegacion",
        nombre: "nombre",
        apellido1: "apellido1",
        apellido2: "apellido2",
        email: "email",
        telefono: "telefono",
        empresa: "empresa",
        contacto: "contacto",
        direccion: "direccion",
        localidad: "localidad",
        provincia: "provincia",
        pais: "pais",
        codpostal: "codpostal",
        observaciones: "observaciones",
        obstransporte: "obstransporte",
        linea: "linea",
        producto: "producto",
        cantidad: "cantidad",
        puntos: "puntos",
        precioventa: "precioventa",
        atencionde: "atencionde",
        personalizacion: "personalizacion",
        documento: "documento",
      };
      const data = res.data;
      saveAsCsv({ data, fields, filename, separator: ";" });
    });
  };

  

  // Handle checkbox selection
  handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    this.setState((prevState) => ({
      selectedMethods: checked
        ? [...prevState.selectedMethods, value]
        : prevState.selectedMethods.filter((method) => method !== value),
    }));
  };

  getListadoMetodoContacto = async () => {
    const MYTOKEN = JSON.parse(localStorage.getItem("token")); // Retrieve token from localStorage
    const { selectedMethods } = this.state;
  
    if (selectedMethods.length === 0) {
      alert("Por favor, selecciona al menos un método de contacto.");
      return;
    }
  
    const queryParams = selectedMethods.join(",");
    const urlHost = HostNode + `api/getContactMethod?contactMethods=${queryParams}`;
  
    try {
      const response = await Axios.get(urlHost, {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + MYTOKEN.access_token,
        },
      });
      console.log("API response:", response.data);

      const fields = {
        user_id: "User ID",
        contact_method: "Contact Method",
        phone: "Phone",
      };
  
      const filename = `Contact_Preferences`;
      if (!Array.isArray(response.data.data) || response.data.length === 0) {
        alert("No hay datos disponibles para exportar.");
        return;
      }  
  
      const data = response.data.data;
      const { saveAsCsv } = useJsonToCsv();

      console.log("Data to export:", data, "fields: ", fields, "filename: ", filename);      
      saveAsCsv({ data, fields, filename, separator: ";" });
    } catch (error) {
      console.error("Error fetching contact method list:", error);
    }
  };
  

  componentDidMount() {}

  render() {
    const contactMethodOptions = [
      { label: "WhatsApp", value: "WHATSAPP" },
      { label: "Teléfono", value: "TELEFONO" },
      { label: "SMS", value: "SMS" },
      { label: "Email", value: "EMAIL" },
    ];
    if (!this.state.datos && !this.state.fecha) {
    }

    return (
      <div style={{ display: "flex", flexWrap: "wrap", alignItems: "stretch" }}>
        <Card style={cardStyle}>
          <div style={{marginBottom: '16px'}}>
            <Card.Title>Usuarios</Card.Title>
            <Card.Text>
              Obtención de los datos de todos nuestros panelistas.
            </Card.Text>
          </div>
          <Button variant="primary" onClick={() => this.getallUsers()}>
            Descargar
          </Button>
        </Card>
        <Card style={cardStyle}>
          <div style={{marginBottom: '16px'}}>
            <Card.Title>Null TLW</Card.Title>
            <Card.Text>
              Obtención de las personas que entran nulos o son baja.
            </Card.Text>
          </div>
          <Button variant="primary" onClick={() => this.getallUsersNullTLW()}>
            Descargar
          </Button>
        </Card>
        <Card style={cardStyle}>
          <div style={{marginBottom: '16px'}}>
            <Card.Title>Sorteo</Card.Title>
            <Card.Text>
              Participaciones de los panelistas en los sorteo.
            </Card.Text>
            <Card.Text>
              <DateTimePicker
                label={"Desde:"}
                value={this.state.dateFromRaffle}
                onChange={this.handleFromDate}
              />
            </Card.Text>
          </div>
          <Button variant="primary" onClick={() => this.getSorteo()}>
            Descargar
          </Button>
        </Card>
        <Card style={cardStyle}>
          <div style={{marginBottom: '16px'}}>
            <Card.Title>Origen</Card.Title>
            <Card.Text>
              Obtención de los origenes de nuestros panelistas a dia de hoy
            </Card.Text>
            <Card.Text>
              <DateTimePicker
                label={"Desde:"}
                value={this.state.dateFrom}
                onChange={this.handleFromDate}
              />
              <DateTimePicker
                label={"Hasta:"}
                value={this.state.dateTo}
                onChange={this.handleToDate}
              />
            </Card.Text>
          </div>
          <Button variant="primary" onClick={() => this.getallUsersOrigenes()}>
            Descargar
          </Button>
        </Card>
        <Card style={cardStyle}>
          <div style={{marginBottom: '16px'}}>
            <Card.Title>Usuarios-Encuestas</Card.Title>
            <Card.Text>
              Obtención una lista de los usuarios y cuantas encuestas ha
              realizado en la ultima semana, o en la fecha que indiques
            </Card.Text>
            <Card.Text>
              <DateTimePicker
                label={"Desde:"}
                value={this.state.dateFromUE}
                onChange={this.handleFromDateUE}
              />
              <DateTimePicker
                label={"Hasta:"}
                value={this.state.dateToUE}
                onChange={this.handleToDateUE}
              />
            </Card.Text>
          </div>
          <Button variant="primary" onClick={() => this.getUEWeekly()}>
            Descargar
          </Button>
        </Card>
        <Card style={cardStyle}>
          <div style={{marginBottom: '16px'}}>
            <Card.Title>Sigcoins</Card.Title>
            <Card.Text>
              Obtención una lista de Sigcoins ganados y perdidos. (El fichero se
              descargará un momento.)
            </Card.Text>
            <Card.Text>
              <DateTimePicker
                label={"Desde:"}
                value={this.state.dateFromSc}
                onChange={this.handleFromDateSc}
              />
              <DateTimePicker
                label={"Hasta:"}
                value={this.state.dateToSc}
                onChange={this.handleToDateSc}
              />
            </Card.Text>
          </div>
          <Button variant="primary" onClick={() => this.getScWeekly()}>
            Descargar
          </Button>
        </Card>
        <Card style={cardStyle}>
          <div style={{marginBottom: '16px'}}>
            <Card.Title>
              Descargar Detalles de los pedidos efectuados
            </Card.Title>
            <Card.Text>
              Lista de los detalles de los pedidos realizados
            </Card.Text>
            <Card.Text>
              <DateTimePicker
                label={"Desde:"}
                value={this.state.dateFromReward}
                onChange={this.handleFromDateReward}
              />
              <DateTimePicker
                label={"Hasta:"}
                value={this.state.dateToReward}
                onChange={this.handleToDateReward}
              />
            </Card.Text>
          </div>
          <Button variant="primary" onClick={() => this.getOrderReward()}>
            Descargar
          </Button>
        </Card>
        <Card style={cardStyle}>
          <div style={{marginBottom: '16px'}}>
            <Card.Title>Cargar puntos a lista de usuarios</Card.Title>
            <Card.Text>
              Carga puntos a los usuarios del csv. El csv deberá contener tres
              columnas, una con el id del usuario, otra con el nombre de la
              encuesta y otra con el número de puntos a cargar
            </Card.Text>
            <Card.Text>
              <CSVReader
                onDrop={this.handleOnDrop}
                onError={this.handleOnError}
                style={{}}
                config={{ header: true, encoding: "ISO-8859-1" }}
                addRemoveButton
                onRemoveFile={this.handleOnRemoveFile}
              >
                <span>Arrastra tu Csv o Pincha aqui</span>
              </CSVReader>
            </Card.Text>
          </div>
          {this.state.loading ? (
            <div>
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Button
                className="mt-2"
                variant="primary"
                onClick={() => this.addPoints()}
              >
                Cargar
              </Button>
              <Button
                className="mt-2"
                variant="primary"
                onClick={() => this.downloadCSVSample()}
              >
                Descargar CSV de ejemplo
              </Button>
            </div>
          )}
        </Card>
        <Card style={cardStyle}>          
          <Card.Title>Selecciona métodos de contacto</Card.Title>
          <Card.Text>
            Elige los métodos de contacto preferidos y descarga un informe con
            los panelistas relacionados.
          </Card.Text>

          <Form>
            {contactMethodOptions.map((option) => (
              <Form.Check
                key={option.value}
                type="checkbox"
                label={option.label}
                value={option.value}
                onChange={this.handleCheckboxChange}
              />
            ))}
          </Form>

          <Button
            variant="primary"
            onClick={this.getListadoMetodoContacto}
            style={{ marginTop: "1em" }}
          >
            Descargar
          </Button>
        </Card>
      </div>
    );
  }
}

export default first;
