import React, { useCallback, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useLogCampaign } from '../LogCampaingContext'
import { useJsonToCsv } from "react-json-csv";
import { removeTZ } from '../../../../../funciones/utils';

const FIELDS = {
  user_id: 'Id de usuario',
  name: "Usuario",
  createdAt: "Dia y hora ",
  recognized: "Es reconocido",
  programName: "Programa escuchado",
};

const formatData = (data) => {
  return data?.map(log => ({
    user_id: log.user_id,
    name: log.full_name,
    createdAt: new Date(removeTZ(log.createdAt)).toLocaleString().replace(",", ""),
    recognized: log.recognized,
    programName: log.service_response?.acrcloud_response?.metadata?.custom_streams[0]?.title ?? "Sin coincidencia"
  }))
}

export const ExportButton = () => {
  const {getLogsToExport, logsCSV, startDate, endDate} = useLogCampaign();
  const { saveAsCsv } = useJsonToCsv();

  const handleExport = useCallback(() => {
    getLogsToExport({startDate, endDate});
  }, [getLogsToExport, startDate, endDate]);

  useEffect(() => {
    if (logsCSV.length) {
      const filename = `Logs Estudio ${new Date()}`;
      const data = formatData(logsCSV);

      saveAsCsv({ data, fields: FIELDS, filename });
    }
  }, [logsCSV, saveAsCsv])


  return (
    <Button variant="primary" onClick={handleExport}>Exportar a CSV</Button>
  )
}
