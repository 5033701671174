import React, {Component} from 'react';
import {Modal, Form, Col, Button, Row} from 'react-bootstrap';
import { HostLead, HostNode} from '../config/config';
import Axios from 'axios';
// import moment from 'moment'
// import Compress from 'compress.js'
// import ModalImage from './ModalImage'
import { useJsonToCsv } from 'react-json-csv';
const { saveAsCsv } = useJsonToCsv();
class ModalNewSurvey extends Component {
    constructor(props){
        super(props);
        this.state= {
            perfilado:0,
            trustsurvey:0,
            modalShow:false,
            image:null,
            allCategories:[],
            fields2:{
                "email" : "email",
                "user_id" : "user_id",
                "URL" :"URL",
                "Gandia" : "GANDIA",
                "ccaa_numerica":"ccaa_numerica",
                "mun_numerica":"mun_numerica",
                "prov_numerica":"prov_numerica",
                "gender":"gender",
                "birth_date":"birth_date",
                "credits":"credits",
                "name" : "name"
                
            },

        }; 
    }
    componentDidMount() {
    this.getTotalPanelistas()
    }
    handleChange = event => {
        this.setState({
            [event.target.id] : event.target.value
        });
        
    }
    getTotalPanelistas(){
        const urlHost = HostLead + 'api/getProfiled' 
        const self =this;
        Axios({
            method: 'GET',
            url: urlHost,          
        }).then(
            function(res){
                var datos = res.data;
                self.setState({
                    totalPanelistas:datos
                })
                console.log("numPanelistas: " + datos)
            }
        )
    }
    onClickCalculate(){
        const urlHost = HostNode + 'api/getMuestra'
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        const self =this;
        Axios({
            method: 'GET',
            url: urlHost,
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + MYTOKEN.access_token,
            },  
            params:{
                numero : this.state.panelistas,
                indiceRes: this.state.ir
            }
            
        }).then(
            function(res){
                var datos = res.data;
                self.setState({
                    numeroMuestra: Math.trunc(datos.panelistasDisp),
                })
                console.log("calculo: " + datos.panelistasDisp)
            }
        )
    }
    downloadPanelistas(){
        const urlHost = HostNode + 'api/downloadPanelistas'
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        const self =this;
        Axios({
            method: 'GET',
            url: urlHost,
            headers:{'Content-Type': "application/json",
                   Authorization: 'Bearer ' + MYTOKEN.access_token},
            params:{
                surveyId:this.props.surveyId,
                numeroPanelistas: self.state.panelistas,
                surveyName:this.props.surveyName
            }
        })
        .then(function(res){
            const data = res.data
            self.setState({
                datos : data,
            })
            console.log(data)
            const filename= `Datos de ${self.props.surveyName}`;
            const fields = self.state.fields2;
            console.log(data)
            saveAsCsv({ data, fields, filename })
        })
    }

    render() {     
        return ( 
            <Modal
            show= {this.props.show}
            onHide={this.props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            >
            <Modal.Header>
                <Modal.Title id="">
                    <div>Asignación de usuarios a la encuesta: {this.props.surveyName}</div>   
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <h2>Tenemos en total: {this.state.totalPanelistas} panelistas</h2>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="panelistas">
                                <Form.Label>Introduce el nº de panelistas que necesitas:</Form.Label>
                                <Form.Control name="panelistas" type="panelistas" placeholder="Nº de panelistas"  onChange={this.handleChange} value={this.state.panelistas} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="ir">
                                <Form.Label>Cual es el indice de respuestas (en porcentaje)</Form.Label>
                                <Form.Control name="ir" type="ir" placeholder="Indice de Respuesta %"  onChange={this.handleChange} value={this.state.ir} />
                        </Form.Group>
                    </Col>
                    
                </Row>
                <Row>
                    <Col>
                        <Form.Label> </Form.Label>
                        <Button variant="info" onClick={() => this.onClickCalculate()}>Calcular</Button>
                    </Col>
                    <Col>
                    {this.state.numeroMuestra?
                    <h4>Tenemos disponibles: {this.state.numeroMuestra} panelistas</h4> : null
                }
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={this.props.onHide}>Cerrar</Button>
                <Button variant="success" onClick={() => this.downloadPanelistas()}>Descargar</Button>
            </Modal.Footer>
            
            </Modal>

        );
    }
}
export default ModalNewSurvey;