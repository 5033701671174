import React, {Component} from 'react';
import {Modal, Button, Form, FormCheck} from 'react-bootstrap';
import {HOST} from '../config/config';
import Axios from 'axios';
// import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitterSquare, faFacebookSquare} from '@fortawesome/free-brands-svg-icons'
// import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import './class.css';
const Styles = styled.div`
`

class ModalUser extends Component {
    constructor(props){
        super(props);
        this.state= {
          userId:"",
          name:"",
          firstSurname:"",
          secondSurname:"",
          email:"",
          dni:"",
          birthday:"",
          credits:"",
          status:1,
          createdAt:"",
          twitter_uid:"",
          facebook:"",
          checkFC:"no-activoFC",
          checkTW:"no-activoFC"
        };
      }
    componentDidCatch(error, errorInfo){
        console.log("Toca el boton de cerrar")
    }

    handleChange = event => {
        this.setState({
          [event.target.id] : event.target.value
        });

      }

    handleSubmit = event => {
        event.preventDefault();
        this.modificarUsuario();
      }

    modificarPanelista = async() => {
            var fechNac = this.state.birthday
            var fechNacFinal =Date.parse(fechNac);
            var MYTOKEN = JSON.parse(localStorage.getItem('token'));
            var urlHost = HOST + 'usermanager/v2/backoffice/users';
            const data = {
                "userId":this.state.userId,
                "email":this.state.email,
                "birthDate":fechNacFinal,
                "credits":this.state.credits,
                "status":{
                    "statusId":this.state.status
                    },
                "userProfile":{
                    "name":this.state.name,
                    "firstSurname":this.state.firstSurname,
                    "secondSurname":this.state.secondSurname,
                    "dni":this.state.dni,
                    "phone":""
                }};
            console.log(data);



            Axios({
                method:'post',
                url: urlHost,
                headers:{'Content-Type': "application/json",
                    Authorization: 'Bearer ' + MYTOKEN.access_token},
                data:data
            }).then(function(res){
                window.alert("Cambios Guardados")
                window.location.reload()

            }).catch(function(res){
                console.log("error del guardado")
            })
  }

  handleCheckChange =(evt) => {
    if(evt.target.checked ){
        this.setState({ [evt.target.id]: 2})
     }else{
        this.setState({ [evt.target.id]: 1})
     }

  }
  obtenerRedes(Id){
  /*  var MYTOKEN = JSON.parse(localStorage.getItem('token'));
    var urlHost = HOSTBridge + 'api/getUserTwitterAndFacebook/' + Id;
    var self =this;
    this.setState({
        checkFC:"no-activoFC"
    })
    Axios({
        method:'get',
        url: urlHost,
        headers:{'Content-Type': "application/json",
            Authorization: 'Bearer ' + MYTOKEN.access_token},
    }).then(function(res){
        var xd = res.data;
        var twui = xd.twitter_uid?xd.twitter_uid:"vacio";
        var fb = xd.facebook?xd.facebook:"vacio";

        if(fb !== "vacio"){
           self.setState({
               checkFC:"activoFC"
           })
           console.log("Funciono FB")
        }else{
            self.setState({
                checkFC:"no-activoFC"

            })
            console.log("Funciono FB no")
        }

        if(twui !== "vacio"){
            self.setState({
                checkTW:"activoFC"
            })
            console.log("Funciono TW")
         }else{
             self.setState({
                 checkTW:"no-activoFC"

             })
             console.log("Funciono TW no")
         }

    }).catch(function(res){
        console.log("error del guardado")
    })*/
  }
    render() {
            const user = this.props.user;
            if(user.userId !==this.state.userId){
                this.setState({
                    userId:user.userId,
                    name:user.name,
                    firstSurname:user.firstSurname,
                    secondSurname:user.secondSurname,
                    email:user.email,
                    dni:user.dni,
                    birthday:user.birthday,
                    credits:user.credits,
                    status:user.status,
                    createdAt:user.createdAt
                })
                this.obtenerRedes(user.userId)
            }

        return (
            <Styles>
            <Modal
                show= {this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h1>{this.props.name} ID:{user.userId} </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Row>
                <div className="col-md-4">
                    <Form.Group controlId="name">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control name="name" type="name" placeholder={user.name} onChange={this.handleChange} value={this.state.name} />
                    </Form.Group>
                    <Form.Group controlId="dni">
                        <Form.Label>DNI</Form.Label>
                        <Form.Control name="name" type="name" placeholder={user.dni} onChange={this.handleChange} value={this.state.dni} />
                    </Form.Group>
                    <Form.Group controlId="credits">
                        <Form.Label>Credits</Form.Label>
                        <Form.Control name="name" type="number" placeholder={user.credits} onChange={this.handleChange} value={this.state.credits} disabled/>
                    </Form.Group>
                </div>
                <div className="col-md-4">
                    <Form.Group controlId="firstSurname">
                        <Form.Label>1º Apellido</Form.Label>
                        <Form.Control name="firstSurname" type="name" placeholder="1º Apellido"  onChange={this.handleChange} value={this.state.firstSurname} />
                    </Form.Group>
                    <Form.Group controlId="birthday">
                        <Form.Label>Fecha de Nacimiento</Form.Label>
                        <Form.Control name="name" type="name" placeholder="Fecha de Nacimiento"  onChange={this.handleChange} value={this.state.birthday} />
                    </Form.Group>
                    <Form.Group controlId="status">
                            <Form.Label></Form.Label>
                            <FormCheck
                                onChange = {this.handleCheckChange}
                                type="switch"
                                checked={this.state.status ===2? true:false}
                                name="status"
                                id="status"
                                label="Activo"
                                className="text-left"
                            />
                    </Form.Group>
                </div>
                <div className="col-md-4">
                    <Form.Group controlId="secondSurname">
                        <Form.Label>2º Apellido</Form.Label>
                        <Form.Control name="name" type="name" placeholder="2º apellido"  onChange={this.handleChange} value={this.state.secondSurname} />
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control name="name" type="name" placeholder="E-mail"  onChange={this.handleChange} value={this.state.email} />
                    </Form.Group>
                    <Form.Group controlId="sociales">
                        <Form.Label>Redes Sociales</Form.Label>
                        <Form style={{ fontSize:"xxx-large"}}>
                            <FontAwesomeIcon  class={this.state.checkTW}  icon={faTwitterSquare}/>
                            <FontAwesomeIcon class={this.state.checkFC} id="iconos" icon={faFacebookSquare} />
                        </Form>
                    </Form.Group>
                </div>
            </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.onHide}>Cerrar</Button>
          <Button variant="success" onClick={() => this.modificarPanelista()}>Guardar Cambios</Button>
        </Modal.Footer>
      </Modal>
      </Styles>
         );
    }
}
export default ModalUser;