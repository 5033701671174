import React, { useState } from "react";
import styudyIcon from "../../../../images/study_icon.png";
import styudyIconHover from "../../../../images/study_icon_hover.png";
import styled from "styled-components";
import { Typography } from "@mui/material";

const GridItem = styled.button`
  padding: 20px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(244, 247, 254, 1);
  color: rgba(63, 63, 63, 1);
  border: none;
  :hover {
    background-color: rgba(0, 146, 173, 1);
    color: white;
  }
  width: 180px;
`;

function StudyCard({ name, date, onClick, ...props }) {
  const [icon, setIcon] = useState(styudyIcon);
  return (
    <GridItem
      onMouseEnter={() => setIcon(styudyIconHover)}
      onMouseLeave={() => setIcon(styudyIcon)}
      onClick={onClick}
    >
      <img
        src={icon}
        alt="study"
        style={{ width: "50px", marginBottom: "4px" }}
      />
      <Typography variant="body1" fontWeight="bold">
        {name}
      </Typography>
      <Typography variant="body2">{date}</Typography>
    </GridItem>
  );
}

export default StudyCard;
