import React, {Component} from 'react';
import {Container,Pagination, Table,Button} from 'react-bootstrap';
import styled from 'styled-components';
import {HOST, HOSTBridge} from '../../../config/config';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPlus} from '@fortawesome/free-solid-svg-icons'
import ModalTerms from '../../../complements/ModalTerms';
import ModalNewTerm from '../../../complements/ModalNewTerm'
import moment from 'moment';
const Styles = styled.div`
.container{
    text-align:center;
}
.page-link{
    background-color:#343a40;
    color:white;
    border: 1px solid #dee2e6;

}
&:hover{
    background-color:#3e444a,
    color:white;
    border: 1px solid #dee2e6;

}
paginationItemStyle{
    float:Left;
}
.size{
    widht:15%,
}
.nuevaEncuesta{
    float: right
}
`
class Termcond extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cols: ["Nombre","Versión", "Fecha Alta", "Section", "Estado", "Fecha Creación", " "],
            modalShow: false,
            terminosTotales:0,
            terminosPage:10,
            currentPage:1,
            pages:0,
            terms:[],
         }
         this.handleClick = this.handleClick.bind(this);

    }

    handleClick(event){
        this.setState({
            currentPage: Number(event.target.id)
          });
    }

    componentDidMount(){

        this.getTerms();

    }

    getTerms = async(obj) =>{

        var urlHost = HOSTBridge + "api/getsecAndTermsFilter?limit=150&offset=0&pageClicked=1&filter_tipo=4";
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        var self= this;
        Axios({
            method: 'get',
            url: urlHost    ,
            headers:{
                    'Content-Type': 'application/json',
                     Authorization: 'Bearer ' + MYTOKEN.access_token},
            data:{}
        })
        .then(function(res){
            var datos = res.data;
            console.log(datos);
            self.setState({
                terms : datos.content,
                terminosTotales: datos.content.length,
            })

        }).catch(
            function(res){
                console.log("No funciona la obtención de el Aviso Legal")
            }
        )
    }
    render() {
        const pageNumbers=[]
        const terms = this.state.terms
        for(let i= 1; i <= Math.ceil(this.state.terminosTotales/this.state.terminosPage);i++){
            pageNumbers.push(i);
        }
        const indexOfLastTodo = this.state.currentPage * this.state.terminosPage;
        const indexOfFirstTodo = indexOfLastTodo - this.state.terminosPage;
        const currentTodos = terms.slice(indexOfFirstTodo, indexOfLastTodo);
        const renderTerminos= currentTodos.map((termino, index) => {
                //"Nombre", 'Versión', 'Fecha de Alta', 'Enlace', 'Section', 'Estado', 'Fecha de Creación'
                const{name, version, fechaAlta, url, app, flag, created_at } = termino;
                const fecha = moment(fechaAlta).format('DD/MM/YYYY');
                const fecha1 = moment(created_at).format('DD/MM/YYYY');
                const status = parseInt(flag)=== 1? "Activo" : "No Activo";

                return(


                    <tr key={url}>
                        <td>{name}</td>
                        <td>{version}</td>
                        <td>{fecha} </td>
                        <td>{app}</td>
                        <td>{status}</td>
                        <td>{fecha1}</td>
                        <td>
                            <span onClick={() => this.setState({modalShow:true,
                                                                enlace:url,
                                                                nombre:name,
                                                                })}>
                                <FontAwesomeIcon cursor="pointer" icon={faEye} />
                            </span>
                        </td>
                    </tr>

                )
             })
        const renderPageNumbers = pageNumbers.map(number => {
            return(

                <Pagination.Item
                    className="paginationItemStyle"
                    key={number}
                    id= {number}
                    onClick={this.handleClick}>
                        {number}
                    </Pagination.Item>
            )
        })
        return (
            <Styles>
                <Container className ='container'>
                    <div>
                        <Button variant="outline-primary" className="nuevaEncuesta" onClick={() => this.setState({modalShow2:true})}>
                            <FontAwesomeIcon icon={faPlus} /> Nueva Política de Privacidad
                        </Button>
                        <Table striped bordered hover variant="light">
                            <thead>
                                <tr>
                                    {this.state.cols.map((col) => <td key={col}>{col}</td>)}
                                </tr>
                            </thead>
                            <tbody>
                                {renderTerminos}
                                <ModalTerms
                                    show={this.state.modalShow}
                                    enlace = {this.state.enlace}
                                    nombre = {this.state.nombre}
                                    onHide={() => this.setState({modalShow:false})} />
                                    <ModalNewTerm
                                        condicion = "Privacidad"
                                        show={this.state.modalShow2}
                                        onHide={() => this.setState({modalShow2:false})}
                                        ></ModalNewTerm>
                            </tbody>

                        </Table>
                        <Pagination className='paginationItemStyle'>
                            {renderPageNumbers}
                        </Pagination>
                    </div>
                </Container>
            </Styles>

          );
    }
}

export default Termcond;