import React, {Component} from 'react';
import {Modal, Button, InputGroup, FormControl, Form} from 'react-bootstrap';
import {HOST} from '../config/config';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'

class ModalUser extends Component {
    constructor(props){
        super(props);
        this.state= {
          password : "",
          name:"",
          email:"",
          grant_type :"password",
          scope: "admin",
          isLoggedIn: false,
        }; 
      }
    componentDidCatch(error, errorInfo){
        console.log("Toca el boton de cerrar")
    }
    
    handleChange = event => {
        this.setState({
          [event.target.id] : event.target.value
        });
        
      }

    handleSubmit = event => {
        event.preventDefault();
        this.modificarUsuario();
      }

    modificarCategoria = async() => {

            var MYTOKEN = JSON.parse(localStorage.getItem('token'));
            var urlHost = HOST + 'usermanager/v2/backoffice/users';
            const data = {
              
                    "userId":this.props.userId,
                    "password":this.state.password,
                   "status_id": this.state.status_id,
                

        };
            console.log(this.props.userId);
            console.log(this.state.password);   
            
            
            Axios({
                method:'post',
                url: urlHost,
                headers:{'Content-Type': "application/json",
                    Authorization: 'Bearer ' + MYTOKEN.access_token},
                data:data
            }).then(function(res){
                window.alert("Cambios Guardados")
                window.location.reload()
                
            }).catch(function(res){
                console.log("error del guardado")
            })
  }
      
  handleCheckChange =(evt) => {
    if(evt.target.checked ){
        this.setState({ [evt.target.id]: 1})
     }else{
        this.setState({ [evt.target.id]: 0})
     }
   
  }
    render() { 
            
            let idusuario = this.props.userId;   
            let status_id = this.props.userId;
            if(status_id !== this.state.status_id){
                this.setState({status_id : status_id});
            }
        return ( 
            <Modal
                show= {this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h1>{this.props.name} ID:{this.props.userId} </h1> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                </InputGroup.Prepend>
                
                <FormControl
                
                name="email"
                placeholder={this.props.mail}

                onChange={this.handleChange}
               
                disabled
                />
                <br/>        
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">#</InputGroup.Text>
                </InputGroup.Prepend>
                
                <FormControl
                controlId="password"
                name="password" type="password"
                placeholder="Contraseña"
                aria-label="Password"
                aria-describedby="basic-addon1"
                onChange={this.handleChange}
               
              
                />
                <br/>         
            </InputGroup>
        </Form> 
        </Modal.Body>
        <Modal.Footer>
        <Button variant="warning" onClick={
                function(){
                   var MYTOKEN = JSON.parse(localStorage.getItem('token'));
                    var urlHost = HOST + 'usermanager/v2/backoffice/users/'+ idusuario + '/deactivate';
                    Axios({
                    method: 'Delete',
                    url: urlHost,
                    headers:{'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + MYTOKEN.access_token},
                    })
                    .then(function(res){
                        console.log("Borrado");
                        window.alert("El usuario ha sido eliminado"); 
                        window.location.reload()
                        
        
                    }).catch(
                    function(res){
                        console.log("no funciona el delete user");
                    }
                    )}}>
                        <FontAwesomeIcon icon={faExclamationTriangle} /> BORRAR USUARIO <FontAwesomeIcon icon={faExclamationTriangle} /></Button>
          <Button variant="danger" onClick={this.props.onHide}>Cerrar</Button>
          <Button variant="success" onClick={() => this.modificarCategoria()}>Guardar Cambios</Button>
        </Modal.Footer>
      </Modal>
         );
    }
}
 
export default ModalUser;