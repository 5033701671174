import React, {Component} from 'react';
import { Container, Col, Row, Form, Button} from 'react-bootstrap';
// import { CSVReader, jsonToCSV }  from 'react-papaparse'
import { HostNode} from '../../config/config'
import Axios from 'axios'
// import Compress from 'compress.js'
// import Files from 'react-files'
import Questions from './Questions'
class FlashSurveys extends Component{
    constructor(props){
        super(props);
        this.state= {
            Preguntas:[
                {question:"",response:[]}
            ]
        }; 
        
    }

    handleOnError = (err, file, inputElem, reason) => {
        alert("No se ha podido subir: \n razon :" + reason)
    }

    handleOnRemoveFile = (data) => {
        
    }
    handleChange = event => {
        this.setState({
          [event.target.id] : event.target.value
        })
      }
    handleQuestionChange = event => {
        console.log(event.target.id+ ' ' + event.target.value)
        this.setState({
            preguntas:{
                ...this.state.preguntas,
                [event.target.id] : event.target.value
            }
        })
    }


    handleAnswerChange = event => {
        this.setState({
            respuestas:{
                ...this.state.respuestas,
                [event.target.id] : event.target.value
            }
        })
    }
    onSubmit = async() =>{
        console.log("enviado")
        var data = {
            titulo:this.state.titulo,
            respuestas:this.state.respuestas,
            preguntas:this.state.preguntas
        }
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        var urlHost = HostNode + 'api/postSurvey'
        Axios({
            method:'post',
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + MYTOKEN.access_token,
            }, 
            url: urlHost,
            data:data
        }).then(function(res){
            if(!alert('Encuesta Creada Satisfactoriamente :D')){window.location.reload();}
        }).catch(function(res){
            console.log("error de registro")
        })
    }

    inputShowing(inputs){
        var rows = []
        for(var i = 0; i<inputs; i++){
            rows.push()
        }
    }
    addQuestion = (e) =>{
        this.setState((prevState) => ({
            Preguntas: [...prevState.Preguntas, {question:"", response:[""]}]
        }))
    }
    render() { 
        return ( 
            <Container className={"border border-3 rounded-4 shadow"}>
                <Form className={"m-4 "}>
                    <Row className={"show-grid"} float="center">
                        
                        <Form.Group as={Col}   lg="4" id={"titulo"} className="xs">
                            <Form.Label>Título de la encuesta flash</Form.Label>
                            <Form.Control id={"titulo"} type="text" placeholder="Introduce el Titulo de la encuesta" maxLength="200" onChange={this.handleChange}/>
                        </Form.Group>
                        <Col xs lg="1">
                        <Form.Label>
                            + Preg.
                        </Form.Label>
                        <Button onClick={(e) => this.addQuestion(e)}>+</Button>
                    </Col>
                    </Row>
                    {this.state.Preguntas.map((val,index) =>{
                        return <Questions index={index} key={index} handleChange={this.handleAnswerChange} handleQuestionChange ={this.handleQuestionChange}/>
                    })
                        
                    }
                    
                </Form>
                <Row className={"mx-auto my-5"}>
                    <Button onClick={() => this.onSubmit()}>Crear Encuesta</Button>
                </Row>
            </Container>
        );
    }
}


export default FlashSurveys;