import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function CheckProgram({ name, variant = "checked" }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
        justifyContent: "center",
        color:
          variant === "checked"
            ? "rgba(24, 169, 77, 1)"
            : "rgba(238, 58, 58, 1)",
      }}
    >
      {variant === "checked" ? (
        <FontAwesomeIcon icon={faCheckCircle} />
      ) : (
        <FontAwesomeIcon icon={faTimesCircle} />
      )}
      <span>{name ?? "Sin coincidencia"}</span>
    </div>
  );
}

export default CheckProgram;
