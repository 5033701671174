import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './PointsHistoryTable.css'


const PointsHistoryTable = (props) => {
    const [tableData, setTableData] = useState([])
    const [tableStyle, setTableStyle] = useState('points-history-table-row')
    const [filter, setFilter] = useState('')
    useEffect(() => {
        setTableData(props.tableData)
    }, [props]);

    const handlerClick = (category) => {
        if (category === filter) {
            setTableData(props.tableData)
            setTableStyle(`points-history-table-row`)
            setFilter('')
        } else if (tableData.length > 0) {
            const filterData = props.tableData.filter(data => data.category === category)
            setTableStyle(`points-history-table-row-${category}`)
            setTableData(filterData)
            setFilter(category)
        }
    }
    return (
        <Container >
            <Row>
                <Col className="justify-content-center" >
                    <p className="text-center d-block points-history-buttom mt-0 mb-1" onClick={() => props.handlerIndex(1)} >Historico de puntos +</p>
                    <p className="text-center d-block points-history-total mt-0 mb-1">Total: {props.totalPoints}</p>
                </Col>
            </Row>
            <Row>
                <Col className="justify-content-center" >
                    <p className="text-center d-block points-history-surveys" onClick={() => { handlerClick("Encuestas") }}>Encuestas</p>
                </Col>
                <Col className="justify-content-center" >
                    <p className="text-center d-block points-history-redemptions" onClick={() => { handlerClick("Canje") }}>Canjes</p>
                </Col>
                <Col className="justify-content-center" >
                    <p className="text-center d-block points-history-Extra" onClick={() => { handlerClick("Extra") }}>Extra</p>
                </Col>
                <Col className="justify-content-center" >
                    <p className="text-center d-block points-history-friendCode" onClick={() => { handlerClick("codAmigo") }}>Codigo Amigo</p>
                </Col>
            </Row>
            <Row>
                <Col className="justify-content-center w-75">
                    <p className={`text-center d-block ${tableStyle}`}>Concepto</p>
                </Col>
                <Col className="justify-content-center">
                    <p className={`text-center d-block ${tableStyle}`}>SC</p>
                </Col>
                <Col className="justify-content-center">
                    <p className={`text-center d-block ${tableStyle}`}>FECHA</p>
                </Col>
                <Col className="justify-content-center">
                    <p className={`text-center d-block ${tableStyle}`}>CATEGORIA</p>
                </Col>
            </Row>
            {tableData.map((e, index) => {
                return (
                    <Row key={index}>
                        <Col className="justify-content-center">
                            <p className={`text-center d-block ${tableStyle} col-table-history`}>{e.name ? e.name.slice(0, 20): ""}</p>
                        </Col>
                        <Col className="justify-content-center">
                            <p className={`text-center d-block ${tableStyle} col-table-history`}>{e.points}</p>
                        </Col>
                        <Col className="justify-content-center">
                            <p className={`text-center d-block ${tableStyle} col-table-history`}>{e.date.slice(0, 10)}</p>
                        </Col>
                        <Col className="justify-content-center">
                            <p className={`text-center d-block ${tableStyle} col-table-history`}>{e.category}</p>
                        </Col>
                    </Row>
                )
            })
            }
        </Container>
    );
}


export default PointsHistoryTable;