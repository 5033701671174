import React, { Component } from 'react';
import Axios from 'axios';

import { ListGroup, Row, Col } from 'react-bootstrap'
// import Chart from 'chart.js';
import  {HostNode, HostLead} from '../../config/config'
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPoll} from '@fortawesome/free-solid-svg-icons'
// import styled from 'styled-components'
// import { List } from 'material-ui';

// var Chart = require('chart.js');
class second extends Component {
    chartRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = { 
        }
    }
    componentDidMount(){
        this.getSurveyCompleted();
        this.getallUsers();
        this.getPerfilados();
    }
getSurveyCompleted = async()=> {
    var urlHost = HostLead  + "api/getSurveyComp";
    var self= this;
    Axios({
        method: 'GET',
        url: urlHost,
    }).then(function(res){
        const data = res.data;
        self.setState({
            surveys: data.data
        })
    })
}
getPerfilados = async()=> {
    var urlHost = HostLead  + "api/getProfiled";
    var self= this;
    Axios({
        method: 'GET',
        url: urlHost,
    }).then(function(res){
        const data = res.data;
        self.setState({
            perfilados: data
        })
    })
}
getallUsers = async() =>{
    const urlHost =HostNode +"api/registrosAllUsers"
    var MYTOKEN = JSON.parse(localStorage.getItem('token')); 
    const self = this;
    Axios({
        method: 'get',
        url: urlHost,
        headers:{
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + MYTOKEN.access_token,
        },  
    }).then(function(data){
       self.setState({
           registros:data.data
       })
    })
    
}
    
    render() { 
       console.log(this.state.perfilados)
        return(this.state.surveys?
            <>
            <ListGroup>
                <ListGroup.Item>
                            <Row>
                                <Col>
                                    <a>Nombre de la Encuesta</a>
                                </Col>
                                <Col>
                                    <a>Total Respuestas</a>
                                </Col>
                                <Col>
                                    <a>% Respuesta/Ususarios Totales</a>
                                </Col>
                                <Col>
                                    <a>% Respuesta/Usuarios Perfilados</a>
                                </Col>
                            </Row>    
                        </ListGroup.Item>
                {this.state.surveys.map((survey) =>{
                    return(
                        <ListGroup.Item>
                            <Row>
                                <Col>
                                    <FontAwesomeIcon icon={faPoll}/>  {survey.name}
                                </Col>
                                <Col>
                                    {survey.usuarios}
                                </Col>
                                <Col>
                                    {Math.round((survey.usuarios *100)/this.state.registros)}%
                                </Col>
                                <Col>
                                    {Math.round((survey.usuarios *100)/this.state.perfilados)}%
                                </Col>
                            </Row>    
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            </>:null
        )
    }
}

export default second;