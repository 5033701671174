import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTimePicker } from "@material-ui/pickers";
import { Typography } from "@mui/material";
import React from "react";
import { useLogCampaign } from "../LogCampaingContext";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

function DateFilters() {
  const { getLogs, startDate, setStartDate, endDate, setEndDate } = useLogCampaign();
  return (
    <Container>
      <Typography variant="body2">Filtrar por fecha</Typography>
      <DateContainer>
        <DateTimePicker
          label={"Desde:"}
          value={startDate}
          onChange={setStartDate}
        />
        <DateTimePicker
          label={"Hasta:"}
          value={endDate}
          onChange={setEndDate}
        />
        {startDate && endDate && (
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{
              marginTop: "24px",
              cursor: "pointer",
              marginLeft: "8px",
            }}
            onClick={() => {
              getLogs({currentPage: 1, startDate: null, endDate: null});
              setStartDate(null);
              setEndDate(null);
            }}
          />
        )}
      </DateContainer>
    </Container>
  );
}

export default DateFilters;
