import React, {Component} from 'react';
import { Container } from 'react-bootstrap';
import { CSVReader }  from 'react-papaparse'
import {HostLead} from '../../config/config'
import Axios from 'axios'
class Importación extends Component{
    constructor(props){
        super(props);
        this.state= {
        }; 
    }
    handleOnDrop = async(data) => {
            var urlHost = HostLead +'api/updateUser';
            // var self= this;
            await Axios({
                method: 'POST',
                url: urlHost,
                data:{
                    data:data
                },
            })
            .then(function(res){
                alert("¡Subida de datos realizada con exito! \nSe han actualizado filas" )
                
            }).catch(
                function(res){
                    console.log("Problemas")
                }
            )
    }

    handleOnError = (err, file, inputElem, reason) => {
        alert("No se ha podido subir: \n razon :" + reason)
    }

    handleOnRemoveFile = (data) => {
        
    }
    
    render() { 
        return ( 
            <Container>

                <h2>Requisitos:</h2>
                <p> Se necesita un CSV con los siguientes datos unicamente VALGUID, SEXO, AUTONO, PROV separado por comas ','</p>
                <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        style={{}}
                        config={{header:true,encoding: "ISO-8859-1",}}
                        
                        addRemoveButton
                        onRemoveFile={this.handleOnRemoveFile}
                        >
                        <span>Arrastra tu Csv o Pincha aqui</span>
                    </CSVReader>
            </Container>
         );
    }
}


export default Importación;