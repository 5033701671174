// Footer.js
import React from 'react';
import { version } from '../package.json'; // Ajusta esta ruta según sea necesario

const Footer = () => {
    const year = new Date().getFullYear(); // Obtener el año actual

    return (
        <footer style={footerStyle}>
            <div style={footerContent}>
                <p>Backoffice Trust Survey © {year} | v {version}</p>
            </div>
        </footer>
    );
}

// Estilos para el footer
const footerStyle = {
    background: '#333',
    color: '#fff',
    textAlign: 'center',
    padding: '10px 0',
    position: 'fixed',
    left: '0',
    bottom: '0',
    width: '100%',
}

const footerContent = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}

export default Footer;
