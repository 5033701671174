import React from 'react';
import {ButtonGroup,Dropdown, Navbar, Button, Nav} from 'react-bootstrap';
import styled from 'styled-components';
import Logo from './logo.png';
import { NavLink, useHistory} from 'react-router-dom';
//import { MenuItem, ProSidebar, SidebarHeader, SidebarFooter, SidebarContent,Menu,SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import {GrTask, GrPower} from 'react-icons/gr'
import {Logout} from '../authentification/Logout';


const Styles = styled.div`
    .navbar{
        background: rgba(95,166,217,1);

    }
    .navbar-brand, .navbar-nav .nav-link{
        color: rgba(9,90,121,1);

        &:hover{
            color:rgba(9,90,121,1);
        }
    }
    .dropdown-item:hover{
        background-color:rgba(8,85,101,1)
    }
    .admin{
        background-color: transparent;
        border-color: transparent;
    }
    .mendesp{
        background-color:#0093ad;
        color:white;
    }
    .custom{
        background: rgba(95,166,217,1);
        color:white;
    }
    .white{
        color:white;
    }
    img{
        width:30%;
        margin-right:10%;
    }
`;

export const NavigationBar=() => {
    const history = useHistory(); // Hook de React Router para manejar la navegación

    // Función para manejar el cierre de sesión
    const handleLogout = () => {
        localStorage.clear(); // Limpia el almacenamiento local (o cualquier otra lógica de logout)
        history.push('/home'); // Redirecciona al usuario a la página de inicio de sesión
    };


return (

    <Styles>
        <Navbar expand="ltrue" >
            <Navbar.Brand>
            <a href="/home">
                <img src={Logo} alt="Logo Sigma dos by Trustsurvey"/>
            </a>
            </Navbar.Brand>
            <Nav.Link as = {NavLink} to={"/panelista"} className="white">Panelistas</Nav.Link>
            <Nav.Link as = {NavLink} to={"/estudios"} className="white">Estudios</Nav.Link>
            <Nav.Link as = {NavLink} to={"/mobileNotification"} className="white">Notificaciones</Nav.Link>
            <Nav.Link as = {NavLink} to={"/encuestas"} className="white">Encuestas</Nav.Link>
            <Nav.Link as = {NavLink} to={"/recompensas"} className="white">Recompensas</Nav.Link>
            <Nav.Link as = {NavLink} to={"/admin"} className="white">Administración</Nav.Link>
            <Button variant='danger' onClick={handleLogout}>
                    <GrPower color="white"/> Logout
            </Button>

            {/*<Dropdown as={ButtonGroup}>
                <Dropdown.Toggle id="a" className="admin"><CgMenu className="white" />Panel Backoffice</Dropdown.Toggle>
                <DropdownMenu className="custom">

                    <Dropdown as={ButtonGroup} drop= "right">
                        <Dropdown.Toggle id="a" className="admin">Administración</Dropdown.Toggle>
                        <DropdownMenu className="custom">
                        <Dropdown.Item as = {NavLink} to={"/usuarios"}  className="white" >Usuarios</Dropdown.Item>

                            <Dropdown as={ButtonGroup} >
                                <Dropdown.Toggle id="a" className="admin">Documentos Legales</Dropdown.Toggle>
                                <DropdownMenu className="custom">
                                    <Dropdown.Item as ={Link} to ={"/terminosCondiciones"} className="white">Términos y Condiciones</Dropdown.Item>
                                    <Dropdown.Item as ={Link} to ={"/legals"} className="white">Aviso Legal</Dropdown.Item>
                                    <Dropdown.Item as ={Link} to ={"/politicaPrivacidad"}  className="white">Política de Privacidad</Dropdown.Item>
                                    <Dropdown.Item as ={Link} to ={"/politicaCookies"}  className="white">Política de Cookies</Dropdown.Item>
                                    <Dropdown.Item as ={Link} to ={"/BasesLegales"}className="white">Bases Legales</Dropdown.Item>
                                </DropdownMenu>
                            </Dropdown>

                            <Dropdown.Item as = {Link} to={"/recompensas"} className="white">Recompensas</Dropdown.Item>
                            <Dropdown.Item as = {Link} to={"/entregaRecompensas"} className="white">Enviar pedidos</Dropdown.Item>
                        </DropdownMenu>
                    </Dropdown>



                    <Dropdown as={ButtonGroup} drop= "right">
                                <Dropdown.Toggle id="a" className="admin">Encuestas</Dropdown.Toggle>
                                <DropdownMenu className="custom">
                                    <Dropdown.Item as ={Link} to ={"/encuestas"} className="white">Encuestas Externas</Dropdown.Item>
                                    <Dropdown.Item as ={Link} to ={"/encuestasCategory"} className="white">Categorías</Dropdown.Item>
                                </DropdownMenu>
                    </Dropdown>
                    <Dropdown as={ButtonGroup} drop= "right">
                                <Dropdown.Toggle id="a" className="admin">Panelistas</Dropdown.Toggle>
                                <DropdownMenu className="custom">
                                    <Dropdown.Item as ={Link} to ={"/panelista2"} className="white">Extracción</Dropdown.Item>
                                    <Dropdown.Item as ={Link} to ={"/importarDatos"} className="white">Importación de datos</Dropdown.Item>

                                </DropdownMenu>
                    </Dropdown>
                    <Dropdown.Item as ={Link} to ={"/mobileNotification"} className="white">Notificaciones</Dropdown.Item>
                </DropdownMenu>
            </Dropdown>
            <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle id="a" className="admin"><CgMenu className="white" />Flash Surveys</Dropdown.Toggle>
                <DropdownMenu className="custom">

                    <Dropdown as={ButtonGroup} drop= "right">
                        <Dropdown.Item as = {Link} to={"/flashSurveys"} className="white">Creación de Encuestas</Dropdown.Item>
                    </Dropdown>
                </DropdownMenu>
            </Dropdown>
            <Button variant='danger' onClick={() => localStorage.clear()} as ={Link} to ={"/LogOut"}><GrPower color="white"/> </Button>
            {/** Menu */}
            {/*<Dropdown as={ButtonGroup}>
                <Dropdown.Toggle id="a" className="admin"><GrLanguage className="white" />El Mundo Backoffice</Dropdown.Toggle>
                <DropdownMenu drop="right" className="custom">

                    <Dropdown as={ButtonGroup}drop="left">
                        <Dropdown.Toggle id="a" className="admin">Articulos</Dropdown.Toggle>
                        <DropdownMenu drop="left" className="custom">
                            <Dropdown.Item className="white">Listado</Dropdown.Item>
                            <Dropdown.Item as ={Link} to ={"/elMundoChart"} className="white">Charts</Dropdown.Item>
                            <Dropdown.Item className="white">Contents</Dropdown.Item>
                            <Dropdown.Item as ={Link} to ={"/ChartResult"} className="white">Chart Results</Dropdown.Item>
                            <Dropdown.Item as ={Link} to ={"/CreaChart"} className="white">Crear Chart</Dropdown.Item>
                        </DropdownMenu>
                    </Dropdown>

                    <Dropdown as={ButtonGroup}drop="left">
                        <Dropdown.Toggle id="a" className="admin">Encuestas</Dropdown.Toggle>
                        <DropdownMenu drop="left" className="custom">
                            <Dropdown.Item className="white">Listado</Dropdown.Item>
                        </DropdownMenu>
                    </Dropdown>
                </DropdownMenu>
            </Dropdown>*/}
        </Navbar>
        </Styles>
        )
}