import React, { Component } from 'react';
import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import MDEditor from "@uiw/react-md-editor";
import Axios from 'axios';
import Compress from 'compress.js'
import { HOST } from '../config/config';


import { CSVReader } from 'react-papaparse';
import { HostLead } from '../config/config';

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersNotis: 0,
            checkValue: 'link',
            users: [],
            segs: 2,
            titulo: '',
            url: '',
            texto: '',
            MDEditor: '',
            selectedImage: null, // To store the selected image file
        };
    }

    handleOnError = (err, file, inputElem, reason) => {
        alert("No se ha podido subir: \nrazón :" + reason);
    }

    handleOnRemoveFile = (data) => {
        // Implement handling for file removal if needed.
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleMDEditorChange = (value) => {
        this.setState({
            MDEditor: value
        });
    }

    handleImageChange = event => {
        this.setState({
            selectedImage: event.target.files[0]
        });
    }

    renderImagePreview = () => {
        const { selectedImage } = this.state;
        if (selectedImage) {
            return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Imagen seleccionada"
                        style={{ marginTop: "10px", maxHeight: "200px" }}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    handleOnDrop = async (data) => {
        this.setState({ users: data.map(item  => item.data.user_id), usersNotis: data.length });
    }

    pushNotification = () => {
        const compress = new Compress()
        let imagen;
        const type = this.state.selectedImage?.type;
        const myToken = JSON.parse(localStorage.getItem('token'));
        if (this.state.selectedImage) {
            compress.compress([this.state.selectedImage], {
                size: 1,
                quality: 1,
                maxWidth: 350,
                maxHeight: 350,
                resize: true,
            }).then((results) => {
                const img1 = results[0]
                imagen = img1.data
                Axios({
                    "url": HOST + "/bridgemanager/DEV/uploadfile",
                    "method": "POST",
                    "headers": {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer" + myToken.access_token
                    },
                    "data": JSON.stringify({ "app": "PANEL", "contenttype": type, "data": imagen })

                }).then(resp => {
                    console.log(resp)
                    Axios({
                        "url": HostLead + "api/pushNotificationDevice",
                        "method": "POST",
                        "data": {
                            segs: this.state.segs,
                            title: this.state.titulo,
                            deepLink: this.state.url,
                            message: this.state.texto,
                            html: this.state.MDEditor,
                            image_url: resp.data.uri,
                            users: this.state.users.length > 0 ? this.state.users : null
                        }

                    }).then(resp => {
                        alert("Notificación enviada")
                    })
                })
            })



        } else if (this.state.checkValue === 'link') {
            Axios({
                "url": HostLead + "api/pushNotificationDevice",
                "method": "POST",
                "data": {
                    segs: this.state.segs,
                    title: this.state.titulo,
                    deepLink: this.state.url,
                    message: this.state.texto,
                    users: this.state.users.length > 0 ? this.state.users : null

                }
            }).then(resp => {
                alert("Notificación enviada")
            })
        } else if (this.state.checkValue === 'html') {
            Axios({
                "url": HostLead + "api/pushNotificationDevice",
                "method": "POST",
                "data": {
                    segs: this.state.segs,
                    title: this.state.titulo,
                    message: this.state.texto,
                    html: this.state.MDEditor,
                    users: this.state.users.length > 0 ? this.state.users : null

                }
            }).then(resp => {
                alert("Notificación enviada")
            })
        }
    }

    handleCheck = (checkValue) => {
        this.setState({ checkValue: checkValue });
    }
    pushNotificationAll = () => {
        // Axios({
        //     url: HostLead + "api/pushNotificationDeviceAll",
        //     method: "POST",
        //     data: {
        //         segs: this.state.segs,
        //         title: this.state.titulo,
        //         message: this.state.texto,
        //     }
        // }).then(resp => {
        //     alert("Notificación enviada");
        // });
    }

    modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link'],
        ],
    };

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];

    render() {
        return (
            <Container className={"border border-3 rounded-4 shadow"}>
                <Row style={{ marginBottom: '200px' }}>
                    <Col>
                        {
                            this.state.usersNotis === 0 ?
                                <>
                                    <h2>Requisitos:</h2>
                                    <p> Se necesita introducir un CSV con la lista de user_id de los usuarios a los que se le quiere asignar la encuesta.</p>
                                    <CSVReader
                                        onDrop={this.handleOnDrop}
                                        onError={this.handleOnError}
                                        style={{}}
                                        config={{ header: true, encoding: "ISO-8859-1", }}
                                        addRemoveButton
                                        onRemoveFile={this.handleOnRemoveFile}
                                    >
                                        <span>Arrastra tu Csv o Pincha aqui</span>
                                    </CSVReader>
                                </> :
                                <>
                                    <h2>La notificación se va a enviar a: {this.state.usersNotis}</h2>
                                    <Button variant='danger' onClick={() => this.setState({ usersNotis: 0 })}>Volver a asignar</Button>
                                </>

                        }

                    </Col>
                    <Col>
                        <h2 className='ml-4'>Imagen</h2>
                        <Form.Group as={Col} id={"image"}>
                            <Form.Label>Seleccionar imagen:</Form.Label>
                            <Form.Control
                                id="image"
                                type="file"
                                accept="image/*"
                                onChange={this.handleImageChange}
                                custom
                            />
                        </Form.Group>
                        {/* Image preview */}
                        {this.renderImagePreview()}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h2>Contenido</h2>
                        <Form.Group id={"titulo"}>
                            <Form.Label>Título: (Máx. 200 caracteres)</Form.Label>
                            <Form.Control id={"titulo"} type="text" placeholder="Introduce el título de la notificación" maxLength="200" onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group id={"texto"}>
                            <Form.Label>Texto: (Máx. 1024 caracteres)</Form.Label>
                            <Form.Control id={"texto"} type="text" placeholder="Introduce el texto de la notificación" maxLength="1024" onChange={this.handleChange} />
                        </Form.Group>

                    </Col>
                </Row>
                <Row style={{marginBottom:'16px'}}>
                    <Col>
                        <Form.Check
                            inline
                            label="Link"
                            value="link"
                            name="group1"
                            type={'radio'}
                            id={`inline-${'radio'}-1`}
                            onChange={() => this.handleCheck('link')}
                            defaultChecked
                        />
                        <Form.Check
                            inline
                            label="HTML"
                            name="group1"
                            value="html"
                            type={'radio'}
                            id={`inline-${'radio'}-2`}
                            onChange={() => this.handleCheck('html')}
                        />
                    </Col>
                </Row>
                <Row>
                    {
                        this.state.checkValue === 'html' ?
                            <Form.Group as={Col} id={"HTML"}>
                                <h2 className='ml-4'>HTML</h2>
                                <MDEditor id='MDEditor' height={600} value={this.state.MDEditor} onChange={this.handleMDEditorChange} data-color-mode="light" />
                            </Form.Group>
                            :
                            <Form.Group as={Col} id={"LINK"}>
                                <Form.Label>LINK</Form.Label>
                                <Form.Control id={"url"} type="text" placeholder="Introduce la URL de la notificación" maxLength="1024" onChange={this.handleChange} />
                            </Form.Group>
                    }


                </Row>
                <Row className={"mx-1 my-3 flex-column w-50"}>
                    <Button variant="outline-primary" className={"my-2"} onClick={() => this.pushNotification()}>Enviar Notificación</Button>
                    <Button variant="outline-primary" onClick={() => this.pushNotificationAll()}>Enviar Notificación a todos los usuarios</Button>
                </Row>
            </Container>
        );
    }
}

export default Notifications;
