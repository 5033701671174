import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from "./Home";
import RequiredAuth from './authentification/RequiredAuth';
import {BrowserView, MobileView,} from "react-device-detect";
import Login from './Login';
import Footer from './Footer';

class App extends Component{
  render(){
    return(
      <React.Fragment>
        <BrowserView>
            <Router>
              <Switch>
                <Route  path="/" component={Login} exact></Route>
                  <Route path = {'/home'}
                    component={props => <RequiredAuth {...props} Component={Home} />}
                  />
              </Switch>
              <Footer />
            </Router>
          </BrowserView>
          <MobileView>
          <Router>
              <Switch>
                <Route  path="/" component={Login} exact></Route>
                  <Route path = {'/home'} exact
                    component={props => <RequiredAuth {...props} Component={Home} />}
                  />
              </Switch>
              <Footer />
            </Router>
          </MobileView>
        </React.Fragment>
    );
  }
}

export default App;
