import React, {Component} from 'react';
import {Modal, Button, Form, Col} from 'react-bootstrap';
import {HOST, HOSTBridge} from '../config/config';
import Axios from 'axios';
import moment from 'moment'
import { Editor } from '@tinymce/tinymce-react';
class ModalUser extends Component {
    constructor(props){
        super(props);
        this.state= {
          name:"",
          version:"",
          apply:"",
          tipo:0,
          urlArchivo:""
        };
    }
    componentDidCatch(error, errorInfo){
        console.log("Toca el boton de cerrar")
    }
    componentDidMount() {
      switch (this.props.condicion){
        case "Condición":
          this.setState({tipo:1, name:this.props.condicion})
          break;
        case "Legal":
          this.setState({tipo:2, name:this.props.condicion})
          break;
        case "Política de Cookies":
          this.setState({tipo:3, name:this.props.condicion})
          break;
        case "Privacidad":
          this.setState({tipo:4, name:this.props.condicion})
          break;
        case "BasesLegales":
          this.setState({tipo:5, name:this.props.condicion})
          break;
        default:
          window.alert("ERROR");
      }
    }
    handleChange = event => {
        this.setState({
          [event.target.id] : event.target.value
        });

      }

    handleSubmit = event => {
        event.preventDefault();
        this.modificarUsuario();
      }


    handleEditorChange = event =>{
      var contenido = event.target.getContent();
      localStorage.setItem('cond', contenido) ;
    }
    subidaDocumentoLegal(){
      var MYTOKEN = JSON.parse(localStorage.getItem('token'));
      var urlSubida = HOSTBridge + 'api/secAndTerms';
      var datos = {
        name:this.state.name,
        app:this.state.apply,
        fechaAlta: moment().format('YYYY-MM-DD'),
        url:this.state.urlArchivo,
        flag: "1",
        version: this.state.version,
        tipo:this.state.tipo,
      }
      Axios({
        method:'post',
        url: urlSubida,
        headers:  {
          "Content-Type":"application/json",
          "Authorization":"Bearer" + MYTOKEN.access_token
        },
        data:JSON.stringify(datos),
      }).then(function(res){
        window.alert("Nuevo Documento Legal creado");
        window.location.reload();
      })
    }
    crearDocumentoLegal(){
      var MYTOKEN = JSON.parse(localStorage.getItem('token'));
      var urlHost = HOST + 'bridgemanager/DEV/uploadfile';
      var dataTo64 = localStorage.getItem('cond');
      var b64 = window.btoa(dataTo64);
      var self = this;
      Axios({
        method:'post',
        url: urlHost,
        headers: {
              "Content-Type":"application/json",
              "Authorization":"Bearer" + MYTOKEN.access_token
        },
        "data": JSON.stringify({
          "app" : "PANEL",
          "contenttype" : "text/html",
          "data" : b64
        })
      }).then(function(res){

        self.setState({urlArchivo: res.data.uri})
        localStorage.removeItem('cond')
      }).then(function(res){
       self.subidaDocumentoLegal();
      })


    }
    render() {
      console.log(this.state.urlArchivo)
        return (
            <Modal
        show= {this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h1>Nueva {this.props.condicion}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Row>

                    <Form.Group as={Col} controlId="name">
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control name="name" type="name"  disabled onChange={this.handleChange} value={this.state.name} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="version">
                      <Form.Label>Version</Form.Label>
                      <Form.Control name="version" type="version" placeholder="Version"  onChange={this.handleChange} value={this.state.version} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="apply">
                    <Form.Label>Estado</Form.Label>
                    <Form.Control as ="select" name="apply" type="apply" onChange={this.handleChange} value={this.state.apply}>
                            <option>Aplica a:</option>
                            <option>Front-end</option>
                            <option>Backoffice Test</option>
                            <option>Leads</option>

                    </Form.Control>
                    </Form.Group>
                </Form.Row>

          <Editor
         initialValue="<p>Nuevo Documento</p>"
         apiKey="jsfailjuxe16yk63zy1cwj1p83861t78oukr1kya49vwr53v"
         init={{
           language_url: '/src/lib/TinyMCE/langs/es.js',
           body_id: 'legalWarning_editor',
           height: 400,
           branding: false,
           font_formats:
           "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif;Montserrat=montserrat; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
           content_style: "@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,100;1,300;1,400;1,500&display=swap')",
           plugins: [
            'advlist autolink lists link image',
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ]
         }}
         onChange={this.handleEditorChange}
       />
        </Modal.Body>
        <Modal.Footer>
         <Button variant="success" onClick={() => this.crearDocumentoLegal()}>Crear Documento legal</Button>
        </Modal.Footer>
      </Modal>
         );
    }
}

export default ModalUser;