import React, { Component } from 'react';
import Axios from 'axios';

// import {Container, Card, ListGroup, Row, Col } from 'react-bootstrap'
import  { HostLead} from '../../config/config'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Chart from 'chart.js';
// import {faPoll} from '@fortawesome/free-solid-svg-icons'
// import styled from 'styled-components'
// import { List } from 'material-ui';

// var Chart = require('chart.js');
class second extends Component {
    chartRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = { 
            ccaa:[],
            total:[],
            options:{
                indexAxis: 'y',
                elements: {
                  bar: {
                    borderWidth: 2,
                  },
                },
                responsive: true,
                plugins: {
                  legend: {
                    position: 'right',
                  },
                  title: {
                    display: true,
                    text: 'Chart.js Horizontal Bar Chart',
                  },
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }
    componentDidMount(){
        this.getSurveyCompleted();
        this.getallUsers();
        this.getPerfilados();
    }
getSurveyCompleted = async()=> {
    var urlHost = HostLead  + "api/getSurveyComp";
    var self= this;
    Axios({
        method: 'GET',
        url: urlHost,
    }).then(function(res){
        const data = res.data;
        self.setState({
            surveys: data.data
        })
    })
}
getPerfilados = async()=> {
    var urlHost = HostLead  + "api/getProfiled";
    var self= this;
    Axios({
        method: 'GET',
        url: urlHost,
    }).then(function(res){
        const data = res.data;
        self.setState({
            perfilados: data
        })
    })
}
getallUsers = async() =>{
    const urlHost =HostLead +"api/getCCAATotales"
    var MYTOKEN = JSON.parse(localStorage.getItem('token')); 
    const self = this;
    const total =[]
    const ccaa =[]
    Axios({
        method: 'get',
        url: urlHost,
        headers:{
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + MYTOKEN.access_token,
        },  
    }).then(function(data){
       data.data.forEach((datos) => {
           total.push(datos.total);
           ccaa.push(datos.ccaa_categorica)
       })
       self.setState({
            total:total,
            ccaa:ccaa
       })
       self.Graficos1()
       console.log(self.state.total)
       console.log(self.state.ccaa_categorica)
    })
    
    
}
Graficos1(){
    const myChartRef =this.chartRef.current.getContext("2d");
    new Chart(myChartRef, {
        type:"horizontalBar",
        data:{
            labels:this.state.ccaa,
            datasets:[
                {   
                    label:"Comunidades Autonomas",
                    data:this.state.total,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                      ],
                      borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                      ],
                      borderWidth: 1,
                },
                
            ]
        },
        options: this.state.options
    })
    
}
    render() { 
        return(
                <>
                <h1>Origenes RRSS</h1>
                    <canvas id="myChart"
                        ref={this.chartRef}/>
                </>
        )

    }
}
export default second;