import React, { Component } from 'react';
import { Container, Pagination, Table, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { HOSTBridge } from '../../config/config';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPenSquare, faThumbsUp, faBan, faTimesCircle, faCheck, faUsersSlash, faUsers, faMagic } from '@fortawesome/free-solid-svg-icons';
import ModalNewAssigment from '../../complements/ModalNewAssigment.js';
import ModalEditAssigment from '../../complements/ModalEditAssigment.js';
import ModalCustomAssing from '../../complements/ModalCustomAssign';
import ModalNewSurvey from '../../complements/ModalNewSurvey.js';
import ModalEditSurvey from '../../complements/ModalEditSurvey.js';
import styled from 'styled-components';

const Styles = styled.div`
.container {
    text-align: center;
    overflow: hidden;
}
.page-link {
    background-color: #343a40;
    color: white;
    border: 1px solid #dee2e6;
}
&:hover {
    background-color: #3e444a,
    color: white;
    border: 1px solid #dee2e6;
}
.paginationItemStyle {
    float: center;
}
.nuevaEncuesta {
    float: right;
}
`;

class Usuarios extends Component {
    constructor() {
        super();
        this.state = {
            cols: ["Id", 'Titulo', "Estado", 'Editar', "Asignar"],
            modalShow: false,
            modalShow2: false,
            surveyTotal: 0,
            surveyPage: 10,
            currentPage: 1,
            surveys: [],
            limit: 10, // Number of surveys per page
            sortColumn: 'id', // Default sort by 'id'
            sortDirection: 'asc', // Default sort direction
            categoria: {},
            survey: {},
            modalAssign: false,
            modalShowCustomAssign: false,
            modalShow2assign: false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    componentDidMount() {
        this.getSurveys(this.state.currentPage);
        this.getSurveyCategory();
    }

    handleClick(event) {
        const pageClicked = Number(event.target.id);
        this.getSurveys(pageClicked);
    }

    handleSort(column) {
        const { sortColumn, sortDirection } = this.state;
        let newDirection = 'asc';

        // If the same column is clicked, toggle the direction
        if (sortColumn === column) {
            newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        }

        this.setState({
            sortColumn: column,
            sortDirection: newDirection
        });
    }

    // Function to get sorted surveys
    getSortedSurveys() {
        const { surveys, sortColumn, sortDirection } = this.state;

        return surveys.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (a[sortColumn] > b[sortColumn]) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    getSurveys = async (pageClicked = 1) => {
        const { limit } = this.state;
        const offset = (pageClicked - 1) * limit; // Calculate offset

        const urlHost = `${HOSTBridge}api/encuestas?limit=${limit}&offset=${offset}&pageClicked=${pageClicked}&visible_sites=panel-trustsurvey`;

        const MYTOKEN = JSON.parse(localStorage.getItem('token'));
        var self = this;

        Axios({
            method: 'GET',
            url: urlHost,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + MYTOKEN.access_token,
            }
        })
        .then(function (res) {
            const datos = res.data;
            self.setState({
                surveys: datos.content, // Assuming 'content' contains survey data
                surveyTotal: datos.total, // Assuming 'total' is the total number of surveys
                currentPage: pageClicked, // Update current page in the state
            });
        })
        .catch(function (error) {
            console.log("Error fetching surveys:", error);
        });
    }

    getSurveyCategory = async () => {
        const urlHost = `${HOSTBridge}api/getAllStatusCategories`;
        const MYTOKEN = JSON.parse(localStorage.getItem('token'));
        const self = this;

        Axios({
            method: 'GET',
            url: urlHost,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + MYTOKEN.access_token,
            },
        })
        .then(function (res) {
            self.setState({
                categoria: res.data,
            });
        })
        .catch(function (error) {
            console.log("Error fetching survey categories:", error);
        });
    }

    SurveyEdit(survey) {
        localStorage.removeItem('dataSurvey');
        const data = survey;
        localStorage.setItem('dataSurvey', JSON.stringify(data));
        this.setState({ modalShow2: true });
    }

    AssignEdit(survey) {
        localStorage.removeItem('dataSurvey');
        const data = survey;
        localStorage.setItem('dataSurvey', JSON.stringify(data));
        this.setState({ survey: data, modalShow2assign: true });
    }

    AssignCustom(survey) {
        localStorage.removeItem('dataSurvey');
        const data = survey;
        localStorage.setItem('dataSurvey', JSON.stringify(data));
        this.setState({ survey: data, modalShowCustomAssign: true });
    }

    NewSurvey() {
        this.setState({ modalShow: true });
    }

    SaveAndAssign(name, id) {
        this.setState({
            modalShow: false,
            modalAssign: true,
            surveyName: name,
            surveyId: id,
        });
    }

    render() {
        const { surveyTotal, surveyPage, currentPage, sortColumn, sortDirection } = this.state;
        const totalPages = Math.ceil(surveyTotal / surveyPage); // Calculate total pages
        const maxPageButtons = 5; // Number of page buttons to show

        // Determine which page numbers to display
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

        if (endPage - startPage < maxPageButtons - 1) {
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        const renderPageNumbers = [];

        // Add first page and ellipsis if needed
        if (startPage > 1) {
            renderPageNumbers.push(
                <Pagination.Item key={1} id={1} active={currentPage === 1} onClick={this.handleClick}>
                    1
                </Pagination.Item>
            );
            if (startPage > 2) {
                renderPageNumbers.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }
        }

        // Add pages within the range
        for (let number = startPage; number <= endPage; number++) {
            renderPageNumbers.push(
                <Pagination.Item key={number} id={number} active={currentPage === number} onClick={this.handleClick}>
                    {number}
                </Pagination.Item>
            );
        }

        // Add last page and ellipsis if needed
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                renderPageNumbers.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }
            renderPageNumbers.push(
                <Pagination.Item key={totalPages} id={totalPages} active={currentPage === totalPages} onClick={this.handleClick}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        const sortedSurveys = this.getSortedSurveys(); // Get sorted surveys

        return (
            <Styles>
                <Container className='container'>
                    <Button variant="outline-primary" className="nuevaEncuesta" onClick={() => this.setState({ modalShow: true })}>
                        <FontAwesomeIcon icon={faPlus} /> Nueva Encuesta
                    </Button>
                    <Table striped bordered hover variant="light">
                        <thead>
                            <tr>
                                <th onClick={() => this.handleSort('id')}>
                                    Id {sortColumn === 'id' && (sortDirection === 'asc' ? '▲' : '▼')}
                                </th>
                                <th onClick={() => this.handleSort('name')}>
                                    Titulo {sortColumn === 'name' && (sortDirection === 'asc' ? '▲' : '▼')}
                                </th>
                                <th onClick={() => this.handleSort('status_id')}>
                                    Estado {sortColumn === 'status_id' && (sortDirection === 'asc' ? '▲' : '▼')}
                                </th>
                                <th>Editar</th>
                                <th>Asignar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedSurveys.map((survey) => {
                                const { id, name, status_id } = survey;
                                return (
                                    <tr key={id}>
                                        <td>{id}</td>
                                        <td>{name}</td>
                                        <td>{statusCheck(status_id)}</td>
                                        <td>
                                            <span onClick={() => this.SurveyEdit(survey)}>
                                                <FontAwesomeIcon cursor="pointer" icon={faPenSquare} />
                                            </span>
                                        </td>
                                        <td>
                                            <span onClick={() => this.AssignEdit(survey)}>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Asignado General</Tooltip>}>
                                                    <FontAwesomeIcon cursor="pointer" icon={faUsers} />
                                                </OverlayTrigger>
                                            </span>
                                            <a>|  |</a>
                                            <span onClick={() => this.AssignCustom(survey)}>
                                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Asignado Customizado</Tooltip>}>
                                                    <FontAwesomeIcon cursor="pointer" icon={faMagic} />
                                                </OverlayTrigger>
                                            </span>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <Pagination pagination-color="light" className='paginationItemStyle'>
                        <Pagination.Prev
                            onClick={() => this.getSurveys(Math.max(currentPage - 1, 1))}
                            disabled={currentPage === 1}
                        />
                        {renderPageNumbers}
                        <Pagination.Next
                            onClick={() => this.getSurveys(Math.min(currentPage + 1, totalPages))}
                            disabled={currentPage === totalPages}
                        />
                    </Pagination>
                </Container>

                {/* Modals */}
                {this.state.modalShow && (
                    <ModalNewSurvey
                        show={this.state.modalShow}
                        category={this.state.categoria}
                        survey={this.state.survey}
                        onHide={() => this.setState({ modalShow: false })}
                        onConfirm={(name, id) => this.SaveAndAssign(name, id)}
                    />
                )}
                {this.state.modalShow2 && (
                    <ModalEditSurvey
                        show={this.state.modalShow2}
                        category={this.state.categoria}
                        survey={this.state.survey}
                        onHide={() => this.setState({ modalShow2: false })}
                    />
                )}
                {this.state.modalAssign && (
                    <ModalNewAssigment
                        show={this.state.modalAssign}
                        survey={this.state.survey}
                        surveyName={this.state.surveyName}
                        surveyId={this.state.surveyId}
                        onHide={() => this.setState({ modalAssign: false })}
                    />
                )}
                {this.state.modalShow2assign && (
                    <ModalEditAssigment
                        show={this.state.modalShow2assign}
                        survey={this.state.survey}
                        surveyName={this.state.surveyName}
                        surveyId={this.state.surveyId}
                        onHide={() => this.setState({ modalShow2assign: false })}
                    />
                )}
                {this.state.modalShowCustomAssign && (
                    <ModalCustomAssing
                        show={this.state.modalShowCustomAssign}
                        survey={this.state.survey}
                        surveyName={this.state.surveyName}
                        surveyId={this.state.surveyId}
                        onHide={() => this.setState({ modalShowCustomAssign: false })}
                    />
                )}
            </Styles>
        );
    }
}

function statusCheck(status) {
    switch (status) {
        case 1:
            return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Activo</Tooltip>}>
                <FontAwesomeIcon icon={faCheck} />
            </OverlayTrigger>;
        case 2:
            return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Desactivado</Tooltip>}>
                <FontAwesomeIcon icon={faBan} />
            </OverlayTrigger>;
        case 3:
            return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Completado</Tooltip>}>
                <FontAwesomeIcon icon={faThumbsUp} />
            </OverlayTrigger>;
        case 4:
            return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Finalizado</Tooltip>}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </OverlayTrigger>;
        case 5:
            return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Borrado</Tooltip>}>
                <FontAwesomeIcon icon={faUsersSlash} />
            </OverlayTrigger>;
        default:
            return null;
    }
}

export default Usuarios;
