import { Typography } from "@mui/material";
import React from "react";

function InfoCard({variant = 'primary', num, title}) {
  const colors = {
    primary: {
      backgroundColor: "rgba(95,166,217,1)",
      color: "white",
    },
    secondary: {
      backgroundColor: "rgba(244, 247, 254, 1)",
      color: "rgba(0, 72, 132, 1)",
    }
  };
  return (
    <div
      style={{
        padding: "20px 32px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: colors[variant].backgroundColor,
        color: colors[variant].color,
      }}
    >
      <Typography variant="h4" style={{ fontWeight: "bold" }}>
        {num}
      </Typography>
      <span
        style={{
          fontSize: "14px",
        }}
      >
        {title}
      </span>
    </div>
  );
}

export default InfoCard;