import { CircularProgress } from "@mui/material";
import React from "react";
import { format } from "date-fns";
import CheckProgram from "../../../components/CheckProgram";
import { Pagination, Table } from "react-bootstrap";
import { useLogCampaign } from "../LogCampaingContext";
import { removeTZ } from "../../../../../funciones/utils";

function LogsTable() {
  const { logs, isLogsLoading, totalPages, setPage, currentPage } = useLogCampaign();
  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr
            style={{
              textAlign: "center",
            }}
          >
            <th>Usuario</th>
            <th>Día y hora de toma</th>
            <th>Programa escuchado</th>
          </tr>
        </thead>
        <tbody>
          {isLogsLoading ? (
            <tr>
              <td colSpan={4}>
                <CircularProgress size={16} />
              </td>
            </tr>
          ) : (
            <>
              {logs.length > 0 ? (
                logs.map((log) => (
                  <tr
                    key={log.id}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <td>{log.full_name}</td>
                    <td>
                      {format(
                        new Date(removeTZ(log.createdAt)),
                        "dd/MM/yy h:mm bb"
                      )}
                    </td>
                    <td>
                      <CheckProgram
                        variant={log.recognized ? "checked" : "unchecked"}
                        name={
                          log.service_response?.acrcloud_response?.metadata
                            ?.custom_streams[0]?.title ?? "Sin coincidencia"
                        }
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>Sin Resultados</td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>

      {!isLogsLoading && <Pagination className='paginationItemStyle'>
          {totalPages > 1 && Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <Pagination.Item
              className="paginationItemStyle"
              key={page}
              id= {page}
              active={page === currentPage}
              onClick={() => setPage(page)}
              >
                {page}
            </Pagination.Item>
          ))}
        </Pagination>
      }

    </>
  );
}

export default LogsTable;
