import React, {Component} from 'react';
import {Modal, Button, Card,Form} from 'react-bootstrap';
import {HOST} from '../config/config';
import Axios from 'axios';
import { VictoryChart, VictoryBar,VictoryPie } from "victory";
class ModalNewChart extends Component {
    constructor(props){
        super(props);
        this.state= {
            password : "",
            email:"",
            grant_type :"password",
            scope: "admin",
            isLoggedIn: false,
        }; 
    }
    componentDidCatch(error, errorInfo){
    }
    
    handleChange = event => {
        this.setState({
            [event.target.id] : event.target.value
        });
        
    }
      componentDidMount() {
        // var MYTOKEN = JSON.parse(localStorage.getItem('token'));
      }

    handleSubmit = event => {
        event.preventDefault();
        this.modificarUsuario();
      }

    crearUsuario = async() => {

            var MYTOKEN = JSON.parse(localStorage.getItem('token'));
            var urlHost = HOST + 'usermanager/v2/superadmin/users';
            const data = {
                    email:this.state.email,
                    password: this.state.password,
                    }           
            
            Axios({
                method:'post',
                url: urlHost,
                headers:{'Content-Type': "application/json",
                    Authorization: 'Bearer ' + MYTOKEN.access_token},
                data:JSON.stringify(data)
            }).then(function(res){
                window.alert("Nuevo usuario registrado")
                window.location.reload();
                
            }).catch(function(res){
                console.log("error de registro")
            })
  }
      
          handleChange = event => {
            this.setState({
              [event.target.id] : event.target.value
            });
            
          }

    render() {   
        // var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        const data = [
            { x: "Rojo", y: 100 },
            { x: "Azul", y: 75 },
            { x: "Naranja", y: 50},
            { x: "Morado", y: 25},
            
            
          ];  
          
        return ( 
            <Modal
        show= {this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h1>Nuevo Usuario</h1> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Row>
        <Card style={Style}>
             <VictoryPie data = {data} colorScale ={["#ea2020","#20b3ea","#f56000", "#8900f5"]}/>
            <Card.Body>
                <Card.Title>Donut</Card.Title>

                <Button variant="primary">Seleccionar</Button>
            </Card.Body>
        </Card>
        <Card style={Style}>
             <VictoryPie data = {data} colorScale ={["#ea2020","#20b3ea","#f56000", "#8900f5"]} startAngle={90} endAngle={-90}/>
            <Card.Body>
                <Card.Title>Hemiciclo</Card.Title>
                <Button variant="primary">Seleccionar</Button>
            </Card.Body>
        </Card>
        <Card style={Style}>
             <VictoryChart colorScale ={["#ea2020","#20b3ea","#f56000", "#8900f5"]} >
            <VictoryBar data = {data} alignment="start"></VictoryBar>
            </VictoryChart>
            <Card.Body>
                <Card.Title>Gráficos</Card.Title>
                <Button variant="primary">Seleccionar</Button>
            </Card.Body>
        </Card>
        </Form.Row>
        </Modal.Body>

        <Modal.Footer>
         <Button variant="success" onClick={() => this.crearUsuario()}>Crear Usuario</Button>
        </Modal.Footer>
      </Modal>
         );
    }
}
 const Style ={ width: '25%',padding: '2%', textAlign:'center'}
export default ModalNewChart;