import React, { useCallback, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useLogCampaign } from '../LogCampaingContext'
import { useJsonToCsv } from "react-json-csv";
import { removeTZ } from '../../../../../funciones/utils';

const FIELDS = {
  user_id: 'Id de usuario',
  email: "Email",
  programs: "Programs",
  user_programs: "User Programs",
  device: "Device",
  method: "Method",
  createdAt: "Dia y hora",
};

const replaceComasToCSV = (string) => {
  return string.replace(/,/g, '-');
}

const formatData = (data) => {
  return data?.map(log => ({
    user_id: log.user_id,
    programs: replaceComasToCSV(log.programs),
    user_programs: replaceComasToCSV(log.user_programs),
    device: log.device ?? "--",
    method: log.method ?? "--",
    email: log.email,
    createdAt: `${new Date(removeTZ(log.createdAt)).toLocaleString().replace(",", "")}`,
  }))
}

export const ExportProgramSurveysButton = () => {
  const {getProgramSurveysToExport, programSurveysCSV, startDate, endDate} = useLogCampaign();
  const { saveAsCsv } = useJsonToCsv();

  const handleExport = useCallback(() => {
    getProgramSurveysToExport({startDate, endDate});
  }, [getProgramSurveysToExport, startDate, endDate]);

  useEffect(() => {
    if (programSurveysCSV.length) {
      const filename = `Logs Estudio ${new Date()}`;
      const data = formatData(programSurveysCSV);

      saveAsCsv({ data, fields: FIELDS, filename });
    }
  }, [programSurveysCSV, saveAsCsv])


  return (
    <Button variant="primary" onClick={handleExport}>Exportar Encuestas CSV</Button>
  )
}