import React from "react";
import { Modal, Button, Image } from "react-bootstrap";
import { NEW_SURVEY_ICONS, PERFILADO_ICONS } from "../config/constants";

const ModalImage = ({show, onHide, callbackImage, isPerfilado = false}) => {
  const handleClick = (url) => {
    callbackImage(url);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <p>Selecciona la imagen de la encuesta, o sube la tuya personalizada</p>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "50vh", overflowY: "auto" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
          }}
        >
          {(Boolean(isPerfilado) ? PERFILADO_ICONS: NEW_SURVEY_ICONS).map((card, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #ccc",
                padding: "1rem",
                width: "150px",
                borderRadius: "5px",
                background: "#49be55",
                color: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "100px", }} >
                  <Image
                    variant="top"
                    style={{ width: "100%" }}
                    src={card.url}
                  />
                </div>
                <h4 style={{ fontSize: "0.8rem", textAlign: "center" }}>
                  {card.name}
                </h4>
              </div>

              <Button
                variant="light"
                onClick={() => handleClick(card.url)}
                block
              >
                Seleccionar
              </Button>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalImage;
