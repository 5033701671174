export const HOST = "https://panelapi.sigmados.com:8090/";
export const HOSTBridge = "https://panelapi.sigmados.com:8090/bridgemanager/"
export const HostNode = "https://panelapi.sigmados.com:8090/bridgemanager/"
export const HostLead = "https://panelapi.sigmados.com:1090/"
export const NodeHost = "https://panelapi.sigmados.com:1060/"
export const HOST_ESCUCHAS_NODE = "https://panelapi.trustsurvey.es:1095"
/*
export const HOST = "https://pre-panelapi.trustsurvey.es:8090/";
export const HOSTBridge = "https://pre-panelapi.trustsurvey.es:8090/bridgemanager/"
export const HostNode = "https://pre-panelapi.trustsurvey.es:8090/bridgemanager/"
export const HostLead ="https://pre-panelapi.trustsurvey.es:1090/"
export const NodeHost = "https://pre-panelapi.trustsurvey.es:1060/"
export const PREBBDD = "panelsigmadb-dev.ciyjrxylvdgs.eu-west-1.rds.amazonaws.com";
export const HOST_ESCUCHAS_NODE = "https://pre-panelapi.trustsurvey.es:1095"
*/
export const USERBBDD = "panelsigma";

export const PSWBBDD ="panelsigma";

export const DATABASE ="";

export const PORT = "";

// Local
// export const HOSTBridge = "http://localhost:1060/"
// export const HostLead ="http://localhost:1080/"
