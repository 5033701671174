import React from 'react';
import {Redirect} from 'react-router-dom';
import { TOKEN_KEY } from './Auth.js';
import Login from '../Login';

export default function RequiredAuth({Component}){
    if(!window.localStorage.getItem(TOKEN_KEY)){
        return <Redirect to ={Login} />
    }
    return <Component />
}  ;