import React, { Component } from "react";
import { Modal, Form, FormCheck, Col, Button } from "react-bootstrap";
import { HOST, HOSTBridge } from "../config/config";
import Axios from "axios";
import moment from "moment";
import Compress from "compress.js";
import ModalImage from "./ModalImage";

class ModalNewSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perfilado: 0,
      trustsurvey: 0,
      url: "https://encuestas.trustsurvey.com/online.php", // URL por defecto
      modalShow: false,
      image: null,
      allCategories: [],
    };
  }
  componentDidMount() {
    this.getAllCategories();
  }
  handleChange = (event) => {
    const { name, value } = event.target;

    // Si selecciona "other", permite la entrada manual de una URL
    if (value === 'other') {
      this.setState({
        [name]: value,
        manualUrl: '' // Inicializa manualUrl para el input de texto
      });
    } else if (name === 'url' && this.state.url === 'other') {
      this.setState({
        manualUrl: value, // Guardar la URL manualmente
      });
    } else {
      this.setState({
        [name]: value, // Actualiza el estado normalmente
      });
    }
  };
  handleCheckChange = (evt) => {
    if (evt.target.checked) {
      this.setState({ [evt.target.id]: 1 });
    } else {
      this.setState({ [evt.target.id]: 0 });
    }
  };
  getImageValue = async (data) => {
    this.setState({
      image_url: data,
      modalShow: false,
    });
  };

  ParseDate(date) {
    return date ? moment(date, "DD-MM-YYYY") : "";
  }

  onDrop(event) {
    const compress = new Compress();
    let imagen;
    let type = event.target.files[0].type;
    let uploadFile = [...event.target.files];

    let myToken = JSON.parse(localStorage.getItem("token"));
    compress
      .compress(uploadFile, {
        size: 1,
        quality: 1,
        maxWidth: 350,
        maxHeight: 350,
        resize: true,
      })
      .then((results) => {
        const img1 = results[0];
        imagen = img1.data;

        Axios({
          url: HOST + "/bridgemanager/DEV/uploadfile",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + myToken.access_token,
          },
          data: JSON.stringify({
            app: "PANEL",
            contenttype: type,
            data: imagen,
          }),
        }).then((resp) => {
          localStorage.setItem("imageSurvey", resp.data.uri);
        });
      });
  }

  NuevaEncuesta() {
    let MYTOKEN = JSON.parse(localStorage.getItem("token"));
    let urlHost = HOSTBridge + "api/encuesta";
    const self = this;
    const datosFinal = {
      ee_id: "",
      ee_image_url: this.state.image_url,
      ee_title: this.state.name,
      ee_category_id: this.state.category_id.toString(),
      ee_status_id: this.StateId(this.state.status_id),
      ee_associated_points: this.state.credits.toString(),
      ee_start_date: this.ParseDate(this.state.start_date),
      ee_end_date: this.ParseDate(this.state.end_date),
      ee_min_response_time: this.state.min_response_time.toString(),
      ee_survey_key: this.state.survey_key,
      ee_visible_sites: "panel-trustsurvey",
      ee_url_ee: "https://encuestas.trustsurvey.com/online.php",
      ee_callbackLink: "",
      ee_customize_link_successfullUpdated: "",
      ee_customize_link_failed: "",
      ee_customize_incompatible_user: "",
      ee_perfilado: this.state.perfilado.toString(),
      ee_trustSurvey: this.state.trustsurvey.toString(),
    };
    Axios({
      method: "post",
      url: urlHost,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + MYTOKEN.access_token,
      },
      data: JSON.stringify(datosFinal),
    })
      .then(function (res) {
        console.log(res);
        self.props.onConfirm(self.state.name, res.data.id);
      })
      .catch(function (res) {
        console.log("error de registro");
      });
  }

  getAllCategories = async () => {
    let urlHost = HOSTBridge + "api/getAllStatusCategories";
    let MYTOKEN = JSON.parse(localStorage.getItem("token"));
    let self = this;
    Axios({
      method: "GET",
      url: urlHost,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + MYTOKEN.access_token,
      },
    }).then(function (res) {
      var datos = res.data;
      self.setState({
        allCategories: datos,
      });
    });
  };

  StateId(state) {
    switch (state) {
      case "Activo":
        return 1;

      case "Desactivado":
        return 2;

      case "Completado":
        return 3;

      case "Finalizado":
        return 4;

      case "Borrado":
        return 5;

      default:
        return 404;
    }
  }

  render() {
    const imagen = localStorage.getItem("imageSurvey");
    if (imagen) {
      if (imagen !== this.state.image_url) {
        this.setState({
          image_url: imagen,
        });
        localStorage.removeItem("imageSurvey");
      }
    }
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title id="">
            <div>Nueva Encuesta Externa</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <div className="col-md-4 text-center float-right">
              <Form.Group controlId="paso1">
                {this.state.image_url && (
                  <div
                    style={{
                      padding: "1rem",
                      width: "100%",
                      borderRadius: "5px",
                      background: "rgb(0, 74, 129)",
                      color: "white",
                      marginBottom: "1rem",
                    }}
                  >
                    <img width="100%" src={this.state.image_url} alt="imagen" />
                  </div>
                )}
                <div className="mb-4">
                  <Form.Row>
                    <Form.Group
                      controlId="trustsurvey"
                      style={{ marginBottom: "4px" }}
                    >
                      <FormCheck
                        type="switch"
                        name="trustsurvey"
                        id="trustsurvey"
                        onChange={this.handleCheckChange}
                        label="TrustSurvey"
                        className="text-left"
                        checked={this.state.trustsurvey}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group
                      controlId="perfilado"
                      style={{ marginBottom: "4px" }}
                    >
                      <FormCheck
                        onChange={this.handleCheckChange}
                        type="switch"
                        checked={this.state.perfilado}
                        name="perfilado"
                        id="perfilado"
                        label="Perfilado"
                        className="text-left"
                      />
                    </Form.Group>
                  </Form.Row>
                </div>


                {/*<Form.File onChange={this.onDrop}/>
                    <Form.Text className="text-muted">Maximo 350x350</Form.Text>*/}
                <Button
                  className="mb-4"
                  size="sm"
                  onClick={() => this.setState({ modalShow: true })}
                  variant="primary"
                  block
                >
                  Selecciona una imagen
                </Button>
              </Form.Group>
            </div>
            <div className="col-md-8">
              <Form.Group controlId="name">
                <Form.Label>Titulo</Form.Label>
                <Form.Control
                  name="name"
                  type="name"
                  placeholder="Nombre de la encuesta"
                  onChange={this.handleChange}
                  value={this.state.name}
                />
              </Form.Group>
              <Form.Label>
                <b>Configuración Básica</b>
              </Form.Label>
              <Form.Row>
                <Form.Group as={Col} controlId="category_id">
                  <Form.Label>Categoría</Form.Label>
                  <Form.Control
                    as="select"
                    name="category_id"
                    type="category"
                    onChange={this.handleChange}
                    value={this.state.category_id}
                  >
                    <option>Selecciona:</option>
                    {this.state.allCategories.map((value, index) => {
                      return (
                        <option key={index} value={value.id}>
                          {value.description}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="status_id">
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    as="select"
                    name="status_id"
                    type="state"
                    onChange={this.handleChange}
                    value={this.state.status_id}
                  >
                    <option>Selecciona:</option>
                    <option>Activo</option>
                    <option>Desactivado</option>
                    <option>Completado</option>
                    <option>Finalizado</option>
                    <option>Borrado</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Label>
                <b>Disponibilidad</b>
              </Form.Label>
              <Form.Row>
                <Form.Group as={Col} controlId="start_date">
                  <Form.Label>Fecha de Inicio</Form.Label>
                  <Form.Control
                    placeholder="DD-MM-AAAA"
                    name="start_date"
                    onChange={this.handleChange}
                    value={this.state.start_date}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="end_date">
                  <Form.Label>Fecha de finalización</Form.Label>
                  <Form.Control
                    placeholder="DD-MM-AAAA"
                    name="end_date"
                    onChange={this.handleChange}
                    value={this.state.end_date}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="credits">
                  <Form.Label>Puntos Asociados</Form.Label>
                  <Form.Control
                    type="number"
                    name="credits"
                    onChange={this.handleChange}
                    value={this.state.credits}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="min_response_time">
                  <Form.Label>Tiempo Min. de respuesta</Form.Label>
                  <Form.Control
                    type="number"
                    name="min_response_time"
                    onChange={this.handleChange}
                    value={this.state.min_response_time}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Label>
                <b>Configuración Del Sistema De Encuestas</b>
              </Form.Label>
              <Form.Row>
                <Form.Group as={Col} controlId="survey_key">
                  <Form.Label>Nombre de la Encuesta</Form.Label>
                  <Form.Control
                    placeholder="Nombre de la encuesta"
                    name="survey_key"
                    onChange={this.handleChange}
                    value={this.state.survey_key}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="url">
                  <Form.Label>URL de la Encuesta</Form.Label>
                  <Form.Control
                    as="select"
                    name="url"
                    onChange={this.handleChange} // Seleccionar o escribir URL
                    value={this.state.url}
                  >
                    <option value="https://encuestas.trustsurvey.com/online.php">https://encuestas.trustsurvey.com/online.php</option>
                    <option value="https://sigmadoscati.tesintegra.net/online.php">https://sigmadoscati.tesintegra.net/online.php</option>
                    <option value="other">Otra URL (Escribir manualmente)</option>
                  </Form.Control>

                  {/* Input manual para otra URL, solo se muestra si se selecciona "other" */}
                  {this.state.url === 'other' && (
                    <Form.Control
                      type="text"
                      placeholder="Introduce la URL manualmente"
                      name="url"
                      onChange={this.handleChange}
                      value={this.state.manualUrl || ""}
                    />
                  )}
                </Form.Group>
              </Form.Row>
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.onHide}>
            Cerrar
          </Button>
          <Button variant="success" onClick={() => this.NuevaEncuesta()}>
            Guardar y Asignar
          </Button>
        </Modal.Footer>
        <ModalImage
          show={this.state.modalShow}
          category={this.state.categoria}
          survey={this.state.survey}
          onHide={() => this.setState({ modalShow: false })}
          callbackImage={this.getImageValue}
          isPerfilado={this.state.perfilado}
        />
      </Modal>
    );
  }
}
export default ModalNewSurvey;
