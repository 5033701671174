import React, {Component} from 'react'
import {Container,Pagination, Table,Button,Row, Col, Form} from 'react-bootstrap';
import styled from 'styled-components';
import { HostLead} from '../../config/config';
import Axios from 'axios';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCheckCircle,faBan,faTimesCircle,faCheck,} from '@fortawesome/free-solid-svg-icons'
import ModalEditPanelista from '../../complements/ModalEditPanelista'
import { useJsonToCsv } from 'react-json-csv';
// import CsvDownload from 'react-json-to-csv'
const Styles = styled.div`
.container{
    text-align:center;
}
.page-link{
    background-color:#343a40;
    color:white;
    border: 1px solid #dee2e6;
    
}
&:hover{
    background-color:#3e444a,
    color:white;
    border: 1px solid #dee2e6;
  
}
paginationItemStyle{
    float:Left;
}
.nuevoUsuario{
    float:right;
}
`
const { saveAsCsv } = useJsonToCsv();

class panelista extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            cols : ["Id", 'Nombre', 'Apellidos', 'Email','Fecha de Alta', 'Estado', ''],
            modalShow:false,
            modalShow2:false,
            fields:{
                "user_id" : "user_id",
                "email" : "email",
                "name" : "name",
                "phone":"phone"
            },
            fields2:{
                "email" : "email",
                "user_id" : "user_id",
                "URL" :"URL",
                "Gandia" : "GANDIA",
                "ccaa_numerica":"ccaa_numerica",
                "mun_numerica":"mun_numerica",
                "prov_numerica":"prov_numerica",
                "gender":"gender",
                "birth_date":"birth_date",
                "credits":"credits",
                "name" : "name"
                
            },
            filename:"Datos",
            ccaa:"",
            option:1,
            user:{},
            gender:0,
            }
    }
    componentDidMount(){
       this.getCcaa()
    }
    getCcaa = async(obj) =>{
        var urlHost = HostLead  + "api/getCCAA";
        var self= this;
        Axios({
            method: 'GET',
            url: urlHost,
        }).then(function(res){
            const data = res.data;
            self.setState({
                ccaas: data
            })
        })
    
    }

    csvData(){
        return this.state.panelistas;
    }
    getPanelista = async(obj) =>{
        var urlHost = HostLead  + "api/getQuota";
        var self= this;
        Axios({
            method: 'GET',
            url: urlHost,
            params:{
                gender:this.state.gender?this.state.gender:0,
                ccaa: this.state.ccaa?this.state.ccaa:null,
                to: this.state.to?this.state.to:null,
                from: this.state.from?this.state.from:null,
            }
        })
        .then(function(res){
            const data = res.data
            self.setState({
                datos : data,
            })
            console.log(data)
            const filename= "Datos Gandia";
            const fields = self.state.fields;
            console.log(data)
            saveAsCsv({ data, fields, filename })
        })
    }
    getPanelistaSendin = async(obj) =>{
        var urlHost = HostLead  + "api/getQuotasUrl";
        var self= this;
        Axios({
            method: 'GET',
            url: urlHost,
            params:{
                gender:this.state.gender?this.state.gender:0,
                ccaa: this.state.ccaa?this.state.ccaa:null,
                to: this.state.to?this.state.to:null,
                from: this.state.from?this.state.from:null,
                surveyName: this.state.surveyName,
            }
        })
        .then(function(res){
            const data = res.data
            self.setState({
                datos : data,
            })
            console.log(data)
            const filename= "Datos Sendin";
            const fields = self.state.fields2;
            console.log(data)
            saveAsCsv({ data, fields, filename })
        })
    }
    handleChange = event => {
        this.setState({
          [event.target.id] : event.target.value
        });
        console.log(event.target.id + " " + event.target.value);
      }
    buttonOnclick = async() =>{
        await this.getPanelista();
        // const data = this.state.datos;
        //const filename= "Datos";
        //const fields = this.state.fields;
        //console.log(data)
        //saveAsCsv({ data, fields, filename })

    }
    buttonOnclick2 = async() =>{
        await this.getPanelistaSendin();
        // const data = this.state.datos;
        //const filename= "Datos";
        //const fields = this.state.fields;
        //console.log(data)
        //saveAsCsv({ data, fields, filename })

    }
    handleInputChange(e){
        const target = e.target;
        const name = target.name;
        this.setState({
            [name]: target.value
        })
    }
    render() { 
        return ( 
            <Styles>    
                <Container className ='container'> 
                <div>
                    <Row>
                    <Col>
                        <h2>Gandia</h2>
                        <Row>
                        <Col xs lg="6">
                            <Form>
                                <Form.Group controlId="gender" >
                                    <Form.Label>Género</Form.Label>
                                    <Form.Control as="select" custom onChange={this.handleChange.bind(this)}>
                                        <option value="0">Todos</option>
                                        <option value='1'>Hombres</option>
                                        <option value='2'>Mujeres</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Label>Rango de Edades</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="from" >
                                            <Form.Control controlId="from" name="from" placeholder="De" onChange={this.handleChange.bind(this)} />
                                        </Form.Group>
                                    </Col> 
                                    <Col>
                                        <Form.Group controlId="to" >
                                            <Form.Control controlId='to' name ='to' placeholder="Hasta" onChange={this.handleChange.bind(this)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col xs lg="4" className="align-middle">
                            <Row>
                                <Form.Group controlId="ccaa" > 
                                    <Form.Label>Comunidad Autónoma</Form.Label>
                                    <Form.Control as="select" custom onChange={this.handleChange.bind(this)} controlId="ccaa" >
                                        {this.state.ccaas? this.state.ccaas.map((ccaa, index)=>{
                                            return <option value={ccaa.ccaa_categorica}>{ccaa.ccaa_categorica}</option>
                                        }):null}
                                    </Form.Control>
                                </Form.Group>
                            </Row>
                            {/*<Row>
                                <Form.Group>
                                    <Form.Label>Descarga BBDD</Form.Label><br/>
                                    <Button onClick={this.buttonOnclick} >
                                        Descargar
                                    </Button>
                                </Form.Group>
                            </Row>*/}
                        </Col>
                        </Row>
                    </Col>
                    <Col xs lg="4" className="align-middle">
                        <Row><h2>SendinBlue</h2></Row>
                        <Row>
                            <Form.Group controlId="surveyName" > 
                                <Form.Label>Nombre de la Encuesta</Form.Label>
                                <Form.Control controlId='surveyName' name ='surveyName' placeholder="Nombre de Encuesta" onChange={this.handleChange.bind(this)}/>
                            </Form.Group>
                        </Row>                           
                    </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="text-center m0 ">
                                <Button variant="outline-primary" onClick={this.buttonOnclick2} >
                                Descargar</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                    <Col  lg="4">
                        <Table striped bordered hover variant="dark">   
                            <thead>
                                <tr></tr>
                            </thead>  
                            <tbody>
                                <ModalEditPanelista
                                    show={this.state.modalShow}
                                    user={this.state.user}
                                    onHide={() => this.setState({modalShow:false})}
                                />      
                            </tbody>  
                        </Table>
                        <Pagination className='paginationItemStyle'></Pagination>
                    </Col>
                    </Row>
                </div>
                </Container>
            </Styles>
        );
    }
}
// function statusCheck(status){
//     switch(status){
//         case 1:
//             return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">No Verificado</Tooltip>}>
//                     <FontAwesomeIcon  icon={faCheck} /> 
//                   </OverlayTrigger>;
//         case 2: 
//             return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Activo</Tooltip>}>
//                     <FontAwesomeIcon icon={faCheckCircle} /> 
//                    </OverlayTrigger>;
//         case 3: 
//             return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Desactivado</Tooltip>}>
//                      <FontAwesomeIcon icon={faBan} /> 
//                     </OverlayTrigger>;
//         case 4: 
//             return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Bloqueado</Tooltip>}>
//                      <FontAwesomeIcon icon={faTimesCircle} /> 
//                     </OverlayTrigger>;
//         default: 
//             return ;
//     }
//   }

export default panelista;