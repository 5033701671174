import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { HOSTBridge, HostLead } from '../../config/config';

const Styles = styled.div`
.container{
    text-align:center;
}
.page-link{
    background-color:#343a40;
    color:white;
    border: 1px solid #dee2e6;
    
}
&:hover{
    background-color:#3e444a,
    color:white;
    border: 1px solid #dee2e6;
  
}
paginationItemStyle{
    float:Left;
}
.nuevoUsuario{
    float:right;
}
`
const UrgentRewardForm = () => {
    const [orderData, setOrderData] = useState({
        user_id:'',
        numero: '',
        linea: '1',
        reward_id: '',
        producto: '',
        cantidad: '1',
        puntos: '',
        precioventa: '',
        atencionde: '',
        personalizacion: '-',
        documento: '',
        fecha: new Date(),
        delegacion: '-',
        nombre: '',
        apellido1: '',
        apellido2: '',
        email: '',
        telefono: '',
        empresa: '-',
        contacto: '',
        direccion: '',
        localidad: '',
        provincia: '',
        pais: 'España', // Default to España
        codpostal: '',
        observaciones: '-',
        obstransporte: '-'
    });

    const [productos, setProductos] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [buttonText, setButtonText] = useState("¿Crear nº de pedido?"); // New state for button text

    useEffect(() => {
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        const fetchProductos = async () => {
            try {
                const response = await axios.get(HOSTBridge +'api/recompensas?section=reward&filter_status=2&limit=1000&offset=0&pageClicked=1',{
                    headers: {
                        'Authorization': 'Bearer'+ MYTOKEN.access_token,
                        'Content-Type': 'application/json'
                    }
                });
                setProductos(response.data.content); // Adjust according to the actual response structure
            } catch (error) {
                console.error('Error fetching productos:', error);
            }
        };

        
        fetchProductos();
    },  []);
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setOrderData(prevState => ({
            ...prevState,
            [name]: value
        }));        

        if (name === 'producto') {
            const selectedProduct = productos.find(product => product.id === value);
            if (selectedProduct) {
                setOrderData(prevState => ({
                    ...prevState,
                    puntos: selectedProduct.points,
                    precioventa: selectedProduct.price
                }));
            }
        }
    };

    const handleDateChange = (field, date) => {
        setOrderData({ ...orderData, [field]: date });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Make the API call here to submit the orderData
        const formData = {
            ...orderData,
            documento: `TSRV2${orderData.numero}`,
            contacto: orderData.nombre
        };

        if (!isFormFilled()) {
            console.error('Please fill all the required fields.');
            return;
        }

        try {
            var MYTOKEN = JSON.parse(localStorage.getItem('token'));
            const headers = {
                'Authorization': 'Bearer'+ MYTOKEN.access_token,
                'Content-Type': 'application/json'
            };
            const response = await axios.post( HOSTBridge +'api/orderTalentumData', 
                formData,{headers});
            console.log('Order submitted:', response.data);
            window.alert("Order submitted:",response.data)
            // Handle the response as needed
        } catch (error) {
            console.error('Error submitting order:', error);
            window.alert("Error.",error)
            // Handle error appropriately
        }
    };

    const makePostRequest = async () => {
        if (orderData.user_id && orderData.producto) {
            try {
                const response = await axios.post( HostLead+ 'api/premio/pedido', {
                    user_id: orderData.user_id,
                    reward_id: orderData.producto
                });
                if(response.data.id){
                    setOrderData({ ...orderData, numero: response.data.id });
                    setIsSubmitted(true); // Update the state to indicate successful submission
                    setButtonText("CREADO CORRECTAMENTE Nº PEDIDO");
                } else {window.alert("Comprueba si tiene saldo suficiente. Actualizar Sigcois como proceda.");}
            } catch (error) {
                console.error('Error making POST request:', error);
            }
        }
    };

    const isFormFilled = () => {
        // Check if all required fields are filled
        // Add or remove fields based on your requirements
        return orderData.user_id && orderData.numero &&
               orderData.fecha && orderData.nombre && orderData.apellido1 &&
               orderData.email && orderData.telefono && orderData.direccion &&
               orderData.localidad && orderData.provincia && orderData.codpostal && 
               orderData.producto  && orderData.atencionde;
    };

    return (
        <Styles>
        <Container>
            <Form onSubmit={handleSubmit}>
                {/* Order Line Section */}
                    Order Line Details                
                <Row>
                <Col>
                    <Form.Group controlId="formProducto">
                        <Form.Label>Producto</Form.Label>
                        <Form.Control as="select" name="producto" value={orderData.producto} onChange={handleChange}>
                            {productos.map((producto, index) => (
                                <option key={index} value={producto.id}>{producto.name}</option> // Replace 'id' and 'name' with actual property names from your API
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formProducto">
                            <Form.Label>User ID</Form.Label>
                            <Form.Control
                                type="text"
                                name="user_id"
                                value={orderData.user_id}
                                onChange={handleChange}
                            />
                        </Form.Group>
                </Col>
                <Col>
                    <Button variant={isSubmitted ? "success": "primary"}
                    onClick={makePostRequest} 
                    disabled={isSubmitted}>{buttonText}
                    </Button>
                </Col>
                </Row>
                <Row>
                <Col>
                    <Form.Group controlId="formNumero">
                            <Form.Label>N. de pedido</Form.Label>
                            <Form.Control
                                type="text"
                                name="numero"
                                value={orderData.numero}
                                onChange={handleChange}
                            />
                        </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formLinea">
                        <Form.Label>linea</Form.Label>
                        <Form.Control
                            type="text"
                            name="linea"
                            value={orderData.linea}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>                
                <Col>
                    <Form.Group controlId="formCantidad">
                        <Form.Label>Cantidad</Form.Label>
                        <Form.Control
                            type="text"
                            name="cantidad"
                            value={orderData.cantidad}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formPuntos">
                        <Form.Label>Puntos</Form.Label>
                        <Form.Control
                            type="text"
                            name="producto"
                            value={orderData.puntos}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formPrecioVenta">
                        <Form.Label>Precio</Form.Label>
                        <Form.Control
                            type="text"
                            name="producto"
                            value={orderData.precioventa}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formAtencionde">
                        <Form.Label>A la atención de</Form.Label>
                        <Form.Control
                            type="text"
                            name="atencionde"
                            value={orderData.atencionde}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formPersonalizacion">
                        <Form.Label>Personalización</Form.Label>
                        <Form.Control
                            type="text"
                            name="personalizacion"
                            value={orderData.personalizacion}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                
            </Row>
            {/* Order Header Section */}
            Order Header Details
            <Row>
                <Col>
                    <Form.Group controlId="formNombre">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            name="nombre"
                            value={orderData.nombre}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formApellido1">
                        <Form.Label>Apellido1</Form.Label>
                        <Form.Control
                            type="text"
                            name="apellido1"
                            value={orderData.apellido1}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formApellido2">
                        <Form.Label>Apellido2</Form.Label>
                        <Form.Control
                            type="text"
                            name="apellido2"
                            value={orderData.apellido2}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formTelefono">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control
                            type="phone"
                            name="telefono"
                            value={orderData.telefono}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                </Row>
                <Row>
                <Col>
                    <Form.Group controlId="formEmail">
                        <Form.Label>email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={orderData.email}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formDireccion">
                        <Form.Label>Direccion</Form.Label>
                        <Form.Control
                            type="text"
                            name="direccion"
                            value={orderData.direccion}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                </Row>
                <Row>
                <Col>
                    <Form.Group controlId="formLocalidad">
                        <Form.Label>Localidad</Form.Label>
                        <Form.Control
                            type="text"
                            name="localidad"
                            value={orderData.localidad}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formProvincia">
                        <Form.Label>Provincia</Form.Label>
                        <Form.Control
                            type="text"
                            name="provincia"
                            value={orderData.provincia}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formCodpostal">
                        <Form.Label>Código Postal</Form.Label>
                        <Form.Control
                            type="text"
                            name="codpostal"
                            value={orderData.codpostal}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formPais">
                        <Form.Label>País</Form.Label>
                        <Form.Control
                            type="text"
                            name="pais"
                            value="España"
                            readOnly
                        />
                    </Form.Group>
                </Col>
                </Row>
                <Row>
                <Col>
                    <Form.Group controlId="formDocumento">
                        <Form.Label>Documento (TSRV2)</Form.Label>
                        <Form.Control
                            type="text"
                            name="documento"
                            value={`TSRV2${orderData.numero}`}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formFecha">
                        <Form.Label>Fecha</Form.Label>
                        <DatePicker
                            selected={orderData.fecha}                                                                
                            onChange={(date) => handleDateChange('fecha',date)} dateFormat="yyyy-MM-dd"
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formDelegacion">
                        <Form.Label>Delegación</Form.Label>
                        <Form.Control
                            type="text"
                            name="delegacion"
                            value={orderData.delegacion}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formoObservaciones">
                        <Form.Label>Observaciones</Form.Label>
                        <Form.Control
                            type="text"
                            name="observaciones"
                            value={orderData.observaciones}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formObstransporte">
                        <Form.Label>Observaciones de Transporte</Form.Label>
                        <Form.Control
                            type="text"
                            name="obstransporte"
                            value={orderData.obstransporte}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Col>
            </Row>            
            <Button variant="primary" type="submit" disabled={!isFormFilled()}>Enviar Pedido</Button>
            </Form>
        </Container>
        </Styles>
    );
};

export default UrgentRewardForm;
