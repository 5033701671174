import React, { Component, createRef } from "react";
import { Modal, Button, Form, FormCheck, Col } from "react-bootstrap";
import { HOST, HOSTBridge } from "../config/config";
import moment from "moment";
import Compress from "compress.js";
// import styled from 'styled-components'
import Axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";

class ModalNewSurvey extends Component {
  imageRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      url: "https://encuestas.trustsurvey.es/online.php", // URL por defecto
      allCategories: [],
      loadingImage: false,
    };
  }
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  componentDidMount() {
    this.getAllCategories();
  }

  obtenerEncuesta() {
    const data = JSON.parse(localStorage.getItem("dataSurvey"));
    if (!data) {
    } else if (this.state.id !== data.id) {
      this.setState({
        id: data.id,
        name: data.name,
        category_id: data.category_id,
        status_id: this.ReStateId(data.status_id),
        start_date: data.start_date,
        end_date: data.end_date,
        credits: data.credits,
        trustsurvey: data.trust_survey,
        perfilado: data.perfilado,
        min_response_time: data.min_response_time,
        survey_key: data.survey_key,
        url: "https://encuestas.trustsurvey.com/online.php",
        file: data.file,
        image_url: data.image_url,
      });
    }
  }

  ParseDate(date) {
    return date ? moment(date, "DD-MM-YYYY") : "";
  }

  modificarEncuesta() {
    console.log("enviando datos");
    var MYTOKEN = JSON.parse(localStorage.getItem("token"));
    var urlHost = HOSTBridge + "api/encuesta";
    const datosFinal = {
      ee_id: this.state.id.toString(),
      ee_image_url: this.state.image_url,
      ee_title: this.state.name,
      ee_category_id: this.state.category_id.toString(),
      ee_status_id: this.StateId(this.state.status_id),
      ee_associated_points: this.state.credits.toString(),
      ee_start_date: this.ParseDate(this.state.start_date),
      ee_end_date: this.ParseDate(this.state.end_date),
      ee_min_response_time: this.state.min_response_time.toString(),
      ee_survey_key: this.state.survey_key,
      ee_visible_sites: "panel-trustsurvey",
      ee_url_ee: "https://encuestas.trustsurvey.es/online.php",
      ee_callbackLink: "",
      ee_customize_link_successfullUpdated: "",
      ee_customize_link_failed: "",
      ee_customize_incompatible_user: "",
      ee_perfilado: this.state.perfilado.toString(),
      ee_trustSurvey: this.state.trustsurvey.toString(),
    };
    Axios({
      method: "put",
      url: urlHost,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + MYTOKEN.access_token,
      },
      data: JSON.stringify(datosFinal),
    })
      .then(function (res) {
        window.alert("Cambios Guardados");
        localStorage.removeItem("dataSurvey");
        window.location.reload();
      })
      .catch(function (res) {
        console.log("error de registro");
      });
  }

  getDimensions(file) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height,
        });
      };
      img.src = URL.createObjectURL(file);
    });
  }

  onDrop = async (event) => {
    const compress = new Compress();
    let imagen;
    const type = event.target.files[0].type;
    const uploadFile = [...event.target.files];
    const dimensions = await this.getDimensions(uploadFile[0]);

    if (dimensions.width !== 1080 || dimensions.height !== 710) {
      this.imageRef.current.value = "";
      alert("Las dimensiones de la imagen deben ser de 1080x710px");
      return;
    }

    this.setState({
      loadingImage: true,
    });

    var myToken = JSON.parse(localStorage.getItem("token"));
    compress
      .compress(uploadFile, {
        size: 1,
        quality: 1,
        maxWidth: 620,
        maxHeight: 407,
        resize: true,
      })
      .then((results) => {
        const img1 = results[0];
        imagen = img1.data;

        Axios({
          url: HOST + "/bridgemanager/DEV/uploadfile",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + myToken.access_token,
          },
          data: JSON.stringify({
            app: "PANEL",
            contenttype: type,
            data: imagen,
          }),
        })
          .then((resp) => {
            // localStorage.setItem("imageSurvey", resp.data.uri);
            this.setState({
              image_url: resp.data.uri,
            });
          })
          .finally(() => {
            this.setState({
              loadingImage: false,
            });
          });
      });
  };

  getAllCategories = async (obj) => {
    var urlHost = HOSTBridge + "api/getAllStatusCategories";
    var MYTOKEN = JSON.parse(localStorage.getItem("token"));
    var self = this;
    Axios({
      method: "GET",
      url: urlHost,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + MYTOKEN.access_token,
      },
    }).then(function (res) {
      var datos = res.data;
      self.setState({
        allCategories: datos,
      });
    });
  };

  handleCheckChange = (evt) => {
    if (evt.target.checked) {
      this.setState({ [evt.target.id]: 1 });
    } else {
      this.setState({ [evt.target.id]: 0 });
    }
  };

  StateId(state) {
    switch (state) {
      case "Activo":
        return 1;
      case "Desactivado":
        return 2;
      case "Completado":
        return 3;
      case "Finalizado":
        return 4;
      case "Borrado":
        return 5;
      default:
        return 404;
    }
  }

  ReStateId(state) {
    switch (state) {
      case 1:
        return "Activo";
      case 2:
        return "Desactivado";
      case 3:
        return "Completado";
      case 4:
        return "Finalizado";
      case 5:
        return "Borrado";
      default:
        return 404;
    }
  }

  render() {
    this.obtenerEncuesta();
    var id = this.state.id;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="">
            <div>Editar Encuesta Externa</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <div className="col-md-4 text-center float-right">
              <Form.Group controlId="paso1">
                {this.state.loadingImage ? (
                  <CircularProgress
                    size={50}
                    style={{ marginBottom: "1rem" }}
                  />
                ) : (
                  <>
                    {this.state.image_url && (
                      <div
                        style={{
                          padding: "1rem",
                          width: "100%",
                          borderRadius: "5px",
                          background: "rgb(0, 74, 129)",
                          color: "white",
                          marginBottom: "1rem",
                        }}
                      >
                        <img
                          width="100%"
                          src={this.state.image_url}
                          alt="imagen"
                        />
                      </div>
                    )}
                  </>
                )}

                <Form.File onChange={this.onDrop} ref={this.imageRef} />
                <div
                  className="text-muted"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "0.8rem",
                    marginBottom: "1rem",
                  }}
                >
                  <span>Formato recomendado: PNG, JPG</span>
                  <span>1080x710px (Requerido)</span>
                  <span>Peso máximo: 1MB</span>
                </div>
                <Form.Row>
                  <Form.Group controlId="trustsurvey">
                    <FormCheck
                      type="switch"
                      name="trustsurvey"
                      id="trustsurvey"
                      onChange={this.handleCheckChange}
                      label="TrustSurvey"
                      className="text-left"
                      checked={this.state.trustsurvey}
                    />
                  </Form.Group>

                  <Form.Group controlId="perfilado" style={{ width: "50%" }}>
                    <FormCheck
                      onChange={this.handleCheckChange}
                      type="switch"
                      checked={this.state.perfilado}
                      name="perfilado"
                      id="perfilado"
                      label="Perfilado"
                      className="text-left"
                    />
                  </Form.Group>
                </Form.Row>
              </Form.Group>
            </div>
            <div className="col-md-8">
              <Form.Group controlId="name">
                <Form.Label>Titulo</Form.Label>
                <Form.Control
                  name="name"
                  type="name"
                  placeholder="Nombre de la encuesta"
                  onChange={this.handleChange}
                  value={this.state.name}
                />
              </Form.Group>
              <Form.Label>
                <b>Configuración Básica</b>
              </Form.Label>
              <Form.Row>
                <Form.Group as={Col} controlId="category_id">
                  <Form.Label>Categoría</Form.Label>
                  <Form.Control
                    as="select"
                    name="category_id"
                    type="category"
                    onChange={this.handleChange}
                    value={this.state.category_id}
                  >
                    <option>Selecciona:</option>
                    {this.state.allCategories.map((value, index) => {
                      return (
                        <option key={index} value={value.id}>
                          {value.description}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="status_id">
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    as="select"
                    name="status_id"
                    type="state"
                    onChange={this.handleChange}
                    value={this.state.status_id}
                  >
                    <option>Selecciona:</option>
                    <option>Activo</option>
                    <option>Desactivado</option>
                    <option>Completado</option>
                    <option>Finalizado</option>
                    <option>Borrado</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Label>
                <b>Disponibilidad</b>
              </Form.Label>
              <Form.Row>
                <Form.Group as={Col} controlId="start_date">
                  <Form.Label>Fecha de Inicio</Form.Label>
                  <Form.Control
                    placeholder="DD-MM-AAAA"
                    name="start_date"
                    onChange={this.handleChange}
                    value={this.state.start_date}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="end_date">
                  <Form.Label>Fecha de finalización</Form.Label>
                  <Form.Control
                    placeholder="DD-MM-AAAA"
                    name="end_date"
                    onChange={this.handleChange}
                    value={this.state.end_date}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="credits">
                  <Form.Label>Puntos Asociados</Form.Label>
                  <Form.Control
                    type="number"
                    name="credits"
                    onChange={this.handleChange}
                    value={this.state.credits}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="min_response_time">
                  <Form.Label>Tiempo Min. de respuesta</Form.Label>
                  <Form.Control
                    type="number"
                    name="min_response_time"
                    onChange={this.handleChange}
                    value={this.state.min_response_time}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Label>
                <b>Configuración Del Sistema De Encuestas</b>
              </Form.Label>
              <Form.Row>
                <Form.Group as={Col} controlId="survey_key">
                  <Form.Label>Nombre de la Encuesta</Form.Label>
                  <Form.Control
                    placeholder="Nombre de la encuesta"
                    name="survey_key"
                    onChange={this.handleChange}
                    value={this.state.survey_key}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="url">
                  <Form.Label>URL de la Encuesta</Form.Label>
                  <Form.Control
                    type="text"
                    name="url"
                    value={this.state.url}
                    onChange={this.handleChange} // Permitir edición
                  />
                </Form.Group>
              </Form.Row>
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="warning"
            onClick={function () {
              var MYTOKEN = JSON.parse(localStorage.getItem("token"));
              var urlHost = HOSTBridge + "api/recompensa/deshabilitada";
              //   var self = this;
              Axios({
                method: "put",
                url: urlHost,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + MYTOKEN.access_token,
                },
                data: {
                  reward_id: id,
                },
              })
                .then(function (res) {
                  console.log("Borrado");
                  window.alert("La recompensa ha sido eliminada");
                  window.location.reload();
                })
                .catch(function (res) {
                  console.log("no funciona el delete reward");
                });
            }}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} /> Borrar Recompensa{" "}
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </Button>
          <Button variant="danger" onClick={this.props.onHide}>
            Cerrar
          </Button>
          <Button variant="success" onClick={() => this.modificarEncuesta()}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default ModalNewSurvey;
