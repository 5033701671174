import React, {Component} from 'react';
import {Modal, Button,Form} from 'react-bootstrap';
// import styled from 'styled-components'
import {HOST, HOSTBridge} from '../config/config';
import Axios from 'axios';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import Compress from 'compress.js'

class ModalNewSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:"",
            description:"",
            name:"",
            icon_url:"",
            user_variable:""
         }
         localStorage.removeItem("imageCategory")
    }

    handleChange = event => {
        this.setState({
          [event.target.id] : event.target.value
        });
    }
    onDrop(event){
        const compress = new Compress()
        var imagen;
        var type = event.target.files[0].type;
        var uploadFile = [...event.target.files];

        var myToken = JSON.parse(localStorage.getItem('token'));
        compress.compress(uploadFile,{
          size:1,
          quality:1,
          maxWidth:350,
          maxHeight:350,
          resize:true,
        }).then((results) => {
          const img1 = results[0]
          imagen = img1.data

          Axios({
            "url": HOST + "/bridgemanager/DEV/uploadfile",
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer" + myToken.access_token
            },
            "data": JSON.stringify({ "app": "PANEL", "contenttype": type, "data": imagen })

        }).then(resp => {

            localStorage.setItem('imageCategory', resp.data.uri)
        })
        })

        }
        NuevaEncuesta(){
          console.log("enviando datos")
          var MYTOKEN = JSON.parse(localStorage.getItem('token'));
          var urlHost = HOSTBridge + 'api/category';
          const datosFinal ={
                id:this.state.id,
                description:this.state.description,
                name:this.state.name,
                icon_url:this.icon_url,
                user_variable:0,
              }
          Axios({
              method:'post',
              url: urlHost,
              headers:{'Content-Type': "application/json",
                       Authorization: 'Bearer ' + MYTOKEN.access_token},
              data:JSON.stringify(datosFinal)
          }).then(function(res){
              window.alert("Categoría guardada")

              window.location.reload()


          }).catch(function(res){
              console.log("error de registro")
          })
          }
    render() {
        var imagen = localStorage.getItem("imageCategory");
        if(imagen !== this.state.icon_url){
          this.setState({
            icon_url:imagen
          })
        }

        return (
            <Modal
        show= {this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
            <Modal.Title id="">
                <div>Nueva Categoría</div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <div className="col-md-4 text-center float-right">
                <Form.Group controlId="paso1">
                    <img width="100%" src={this.state.icon_url} alt="imagen" />
                    <Form.File onChange={this.onDrop}/>
                    <Form.Text className="text-muted">Maximo 350x350</Form.Text>
                </Form.Group>
            </div>
            <div className="col-md-8">
                <Form.Group controlId="name">
                        <Form.Label>Nombre de la Categoría</Form.Label>
                        <Form.Control name="name" type="name" placeholder="Nombre de la encuesta"  onChange={this.handleChange} value={this.state.name} />
                </Form.Group>
                <Form.Group controlId="description">
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control as="textarea" value={this.state.description} onChange={this.handleChange} rows={3} />
                </Form.Group>
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.onHide}>Cerrar</Button>
          <Button variant="success" onClick={() => this.NuevaEncuesta()}>Guardar Cambios</Button>
        </Modal.Footer>
      </Modal>
         );
    }
}

export default ModalNewSurvey;