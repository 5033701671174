import React from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";

import "react-datepicker/dist/react-datepicker.css";
import { useCampaigns } from "../../CampaignsContext";

const CampaignNewForm = () => {
  const { createCampaign } = useCampaigns();
  const [loading, setLoading] = React.useState(false);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      name: "",
      startDate: "",
      endDate: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await createCampaign(data);
      reset();
    } catch (error) {
      alert("Error al crear el estudio");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Card className="position-sticky">
      <Card.Body>
        <Card.Title>Nuevo Estudio</Card.Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="username">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="name"
              name="name"
              placeholder="Nombre del estudio"
              isInvalid={errors.name}
              {...register("name", { required: "Este campo es requerido" })}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.name?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="startDate">
            <Form.Label>Fecha de Inicio</Form.Label>
            <Controller
              control={control}
              rules={{ required: "Este campo es requerido" }}
              name="startDate"
              render={({ field, fieldState }) => (
                <>
                  <DatePicker
                    className={`form-control w-100 ${
                      fieldState.error ? "is-invalid" : ""
                    }`}
                    name={field.name}
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    onBlur={field.onBlur}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Fecha de inicio"
                  />
                  <Form.Text className="text-danger">
                    {fieldState.error?.message}
                  </Form.Text>
                </>
              )}
            />
          </Form.Group>

          <Form.Group controlId="endDate">
            <Form.Label>Fecha de fin</Form.Label>
            <Controller
              control={control}
              rules={{
                required: "Este campo es requerido",
                validate: (value) => {
                  const startDate = getValues("startDate");
                  if (!startDate) return true;
                  return (
                    new Date(value) > new Date(startDate) ||
                    "La fecha de fin debe ser mayor a la fecha de inicio"
                  );
                },
              }}
              name="endDate"
              render={({ field, fieldState }) => (
                <>
                  <DatePicker
                    className={`form-control w-100 ${
                      fieldState.error ? "is-invalid" : ""
                    }`}
                    name={field.name}
                    onChange={(date) => field.onChange(date)}
                    onBlur={field.onBlur}
                    selected={field.value}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Fecha de fin"
                    minDate={getValues("startDate")}
                  />
                  <Form.Text className="text-danger">
                    {fieldState.error?.message}
                  </Form.Text>
                </>
              )}
            />
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            block
            disabled={!isValid || loading}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Crear"
            )}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CampaignNewForm;
