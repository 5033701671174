import React, { useRef, useState } from "react";
import { Button, Col, Form, Modal, Pagination, Table } from "react-bootstrap";
import { useCampaign } from "../../../CampaignContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faPenSquare,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";

function UsersTable({disabledActions = false}) {
  const statusInput = useRef(null);
  const { users, changeUserStatus, totalPages, currentPage, setPage } = useCampaign();
  const [show, setShow] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEditUser = async () => {
    try {
      await changeUserStatus(userToEdit.user_id, statusInput.current.value);
      handleClose();
    } catch (error) {
      alert("Error al editar usuario");
    }
  };

  return (
    <>
      <div>
        <Table striped bordered hover size="sm">
          <thead>
            <tr style={{ textAlign: "center", }} >
              <th>Usuario</th>
              <th>Email</th>
              <th>Estado</th>
              <th>Puntos</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index} style={{ textAlign: "center", }} >
                <td className="align-middle">
                  <Typography variant="body2">{user.fullname}</Typography>
                </td>
                <td className="align-middle">
                  <Typography variant="body2">{user.email ?? ''}</Typography>
                </td>
                <td className="align-middle">
                  {user.status === "ACTIVE" ? (
                    <FontAwesomeIcon icon={faCheckCircle} />
                  ) : (
                    <FontAwesomeIcon icon={faTimesCircle} />
                  )}
                </td>
                <td className="align-middle">
                  <Typography variant="body2">{user.points}</Typography>
                </td>
                <td className="align-middle">
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    disabled={disabledActions}
                    onClick={() => {
                      setUserToEdit(user);
                      handleShow();
                    }}
                  >
                    <FontAwesomeIcon icon={faPenSquare} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination className='paginationItemStyle'>
          {totalPages > 1 && Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <Pagination.Item
              className="paginationItemStyle"
              key={page}
              id= {page}
              active={page === currentPage}
              onClick={() => setPage(page)}
              >
                {page}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>

      {userToEdit && (
        <Modal centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Editar - {userToEdit.fullname}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Estado usuario</Form.Label>
              <Form.Control
                as="select"
                defaultValue={userToEdit.status}
                ref={statusInput}
              >
                <option value="ACTIVE">Activo</option>
                <option value="FINISHED">Desactivado</option>
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleEditUser}>
              Aplicar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default UsersTable;
