import React, { Component } from 'react';
import Axios from 'axios';
import  {HostNode} from '../../config/config'
// import {Container, Card } from 'react-bootstrap'
import Chart from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
// import styled from 'styled-components'

// import * as d3 from 'd3'

// var Chart = require('chart.js');
class first extends Component {
    chartRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = { 
            usersLastDay:0,
            data:{},
            registros:0,
         }
    }
    
    getallUsers = async() =>{
        const urlHost =HostNode +"api/registrosAllUsers"
        var MYTOKEN = JSON.parse(localStorage.getItem('token')); 
        const self = this;
        Axios({
            method: 'get',
            url: urlHost,
            headers:{
                "Content-Type": "application/json; charset=utf-8",
				"Authorization": "Bearer " + MYTOKEN.access_token,
            },  
        }).then(function(data){
           self.setState({
               registros:data.data
           })
        })
        
    }
    getLastUsers = async()=>{
        const urlHost =HostNode +"api/registrosLastDays"
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        const self = this;
        Axios({
            method: 'get',
            url: urlHost,
            headers:{
                "Content-Type": "application/json; charset=utf-8",
				"Authorization": "Bearer " + MYTOKEN.access_token,
            },  
        }).then(function(data){
          var datos = data.data.data;
          console.log(datos)
          var datos2= [];
          var fechas=[];
          for(var i = 0; i<datos.length; i++){
              datos2.push(datos[i]['count(*)'])
              fechas.push(datos[i]['FECHA'])
          }
         self.setState({
             datos:datos2,
             fecha:fechas,
         })
         self.Graficos1()
        })
    }
    
    Graficos1(){
    const myChartRef =this.chartRef.current.getContext("2d");
    new Chart(myChartRef, {
        type:"line",
        data:{
            labels:this.state.fecha,
            datasets:[{
               
                data: this.state.datos,
                backgroundColor:"#00669980",
                datalabels: {
                    
                  }
            },]
        },  
        options:{
            scales: {
                yAxes: [{
                    ticks: {beginAtZero:true,max:Math.max(...this.state.datos)+1}
                }]
            },
            legend: {
                display:false
            },
            plugins:{
                datalabels: {
                    backgroundColor:"#006699",
                    borderRadius: 4,
                    color: 'white',
                    font: {
                    weight: 'bold'
                    },
                    formatter: Math.round,
                    padding: 6
                }
            }
        }
    })
 }
    componentDidMount(){
        this.getLastUsers()
        this.getallUsers()
    }
    
    render() { 
        if(!this.state.datos && !this.state.fecha){
            
        }
        
        return ( 
            <div>
                <h1>Evolución a 7 días. Total: </h1> <h1  style={{color:"#006699"}}>{this.state.registros}</h1>
                        <canvas id="myChart" ref={this.chartRef}/>
            </div>
         );
    }
}
 
export default first;