import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import { useCampaign } from "../../../CampaignContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function SearchInput() {
  const searchInputRef = useRef();
  const { setSearchTerm, searchTerm } = useCampaign();
  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        setSearchTerm(searchInputRef.current.value);
      }}
    >
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Buscar usuarios</Form.Label>
        <div
          style={{
            position: "relative",
          }}
        >
          <Form.Control
            type="text"
            placeholder="Nombre de usuario o Correo electrónico"
            ref={searchInputRef}
          />
          {searchTerm && (
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                searchInputRef.current.value = "";
                setSearchTerm(null);
              }}
            />
          )}
        </div>
      </Form.Group>
    </Form>
  );
}

export default SearchInput;
