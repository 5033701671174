import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { HOST, HOSTBridge } from "../config/config";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Compress from "compress.js";
import { v4 as uuidv4 } from "uuid";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";

const REWARD_STATUS = [
  { id: 1, name: "Pendiente de publicar" },
  { id: 2, name: "Publicado" },
  { id: 3, name: "No publicado" },
  { id: 4, name: "Pendiente de Baja" },
  { id: 5, name: "Baja" },
  { id: 6, name: "Desactivado" },
];

const REWARD_TYPE = [
  { id: "gold", name: "Oro" },
  { id: "silver", name: "Plata" },
  { id: "bronze", name: "Bronce" },
];

const ModalNewReward = ({ show, onHide, categories, rewardId }) => {
  const MYTOKEN = JSON.parse(localStorage.getItem("token"));
  const [name, setName] = useState("");
  const [points, setPoints] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [image, setImage] = useState("");
  const [descripLarga, setDescripLarga] = useState("");
  const [descripCorta, setDescripCorta] = useState("");
  const [statusReward, setStatusReward] = useState("");
  const [fechaAlta, setFechaAlta] = useState(moment().format("YYYY-MM-DD"));

  const getReward = useCallback(
    async (id) => {
      try {
        const urlHost = HOSTBridge + "api/recompensa?id=" + id;
        const res = await Axios({
          method: "GET",
          url: urlHost,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + MYTOKEN.access_token,
          },
        });

        const data = res.data;
        console.log("👻 ~ data:", data);
        setDescripLarga(data.descripLarga);
        setDescripCorta(data.descripCorta);
        setName(data.nombre);
        setPoints(data.puntos);
        setPrice(data.price);
        setCategory(data.category);
        setType(data.type);
        setImage(data.image);
        setStatusReward(data.status_reward);
        setFechaAlta(data.fechaAlta);
        setStatusReward(data.status_reward);

        console.log("👻 ~ getReward ~ data:", data);
      } catch (error) {
        console.log("get reward error:", error);
      }
    },
    [MYTOKEN.access_token]
  );

  useEffect(() => {
    if (rewardId) {
      getReward(rewardId);
    }
  }, [getReward, rewardId]);

  const createReward = async () => {
    try {
      const urlHost = HOSTBridge + "api/recompensa";

      const data = {
        id: uuidv4(),
        descripLarga: descripLarga,
        desProducto: descripCorta,
        imagen: image,
        nombre: name,
        puntos: points,
        reward_id: null,
        status_reward: statusReward,
        reward_category_id: category,
        fechaAlta: fechaAlta,
        euros: price,
        type: type,
        createdAt: moment().format("YYYY-MM-DD"),
      };

      await Axios({
        method: "post",
        url: urlHost,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + MYTOKEN.access_token,
        },
        data: data,
      });

      window.alert("Recompensa Guardada");
      window.location.reload();
    } catch (error) {
      console.log("error del guardado");
    }
  };

  const updateReward = async () => {
    try {
      const urlHost = HOSTBridge + "api/recompensa";
      const data = {
        descripLarga: descripLarga,
        desProducto: descripCorta,
        imagen: image,
        nombre: name,
        puntos: points,
        reward_id: rewardId,
        status_reward: statusReward,
        category: category,
        fechaAlta: fechaAlta,
        euros: price,
        type: type,
      };
      await Axios({
        method: "put",
        url: urlHost,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + MYTOKEN.access_token,
        },
        data: data,
      });

      window.alert("Cambios Guardados");
      window.location.reload();
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeReward = async () => {
    try {
      const urlHost = HOST + "/api/recompensa/deshabilitada";

      await Axios({
        method: "put",
        url: urlHost,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + MYTOKEN.access_token,
        },
        data: {
          reward_id: rewardId,
        },
      });
      window.alert("La recompensa ha sido eliminada");
      window.location.reload();
    } catch (error) {
      console.log("no funciona el delete user");
    }
  };

  const onDrop = async (event) => {
    try {
      const compress = new Compress();
      const type = event.target.files[0].type;
      const uploadFile = [...event.target.files];

      const myToken = JSON.parse(localStorage.getItem("token"));
      const results = await compress.compress(uploadFile, {
        size: 1,
        quality: 1,
        maxWidth: 350,
        maxHeight: 350,
        resize: true,
      });

      const compressedImage = results[0];
      const resp = await Axios({
        url: HOST + "bridgemanager/DEV/uploadfile",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer" + myToken.access_token,
        },
        data: JSON.stringify({
          app: "PANEL",
          contenttype: type,
          data: compressedImage.data,
        }),
      });
      setImage(resp.data.uri);
    } catch (error) {
      console.log("error:", error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="">
          <div>Nueva Recompensa</div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <section>
          <div className="col-md-5 text-center float-right">
            <Form.Group controlId="img">
              <img width="100%" src={image} alt="imagen" />
              <Form.File onChange={onDrop} />
              <Form.Text className="text-muted">Maximo 350x350</Form.Text>
            </Form.Group>
            <Form.Group controlId="descripCorta">
              <Form.Label>Pequeña Descripción</Form.Label>
              <Form.Control
                as="textarea"
                value={descripCorta}
                onChange={(e) => setDescripCorta(e.target.value)}
                rows={3}
              />
            </Form.Group>
          </div>
          <div className="col-md-7">
            <Form.Group controlId="nombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
                rows={3}
              />
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} controlId="fechaAlta">
                <Form.Label>Fecha de alta</Form.Label>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy-MM-DD"
                  margin="normal"
                  id="date-picker-inline"
                  value={fechaAlta}
                  onChange={(date) =>
                    setFechaAlta(moment(date).format("YYYY-MM-DD"))
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  style={{
                    marginTop: 0,
                    height: 40,
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="status_reward">
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  as="select"
                  value={statusReward}
                  onChange={(e) => setStatusReward(e.target.value)}
                  rows={3}
                >
                  <option>Seleccionar...</option>
                  {REWARD_STATUS.map((status) => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="category">
                <Form.Label>Categoría</Form.Label>
                <Form.Control
                  as="select"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  rows={3}
                >
                  <option>Seleccionar...</option>
                  {categories?.map((item) => (
                    <option
                      key={item.reward_category_id}
                      value={item.reward_category_id}
                      selected={item.reward_category_id === category}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="type">
                <Form.Label>Tipo</Form.Label>
                <Form.Control
                  as="select"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  rows={3}
                >
                  <option>Seleccionar...</option>
                  {REWARD_TYPE.map((item) => (
                    <option key={item.id} value={item.id} selected={type === item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="puntos">
                <Form.Label>Puntos</Form.Label>
                <Form.Control
                  value={points}
                  onChange={(e) => setPoints(e.target.value)}
                  type="number"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="price">
                <Form.Label>Euros</Form.Label>
                <Form.Control
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  type="number"
                />
              </Form.Group>
            </Form.Row>
            <Form.Group controlId="descripLarga">
              <Form.Label>Descripción Larga</Form.Label>
              <Form.Control
                as="textarea"
                value={descripLarga}
                onChange={(e) => setDescripLarga(e.target.value)}
                rows={3}
              />
            </Form.Group>
          </div>
        </section>
      </Modal.Body>

      <Modal.Footer>
        {rewardId && (
          <Button variant="warning" onClick={removeReward}>
            <FontAwesomeIcon icon={faExclamationTriangle} /> Borrar Recompensa{" "}
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </Button>
        )}
        <Button variant="danger" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="success" onClick={() => {
          if (rewardId) {
            updateReward()
          } else {
            createReward();
          }
        }}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalNewReward;
