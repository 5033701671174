import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { debounce } from "@mui/material";
import { HostLead } from "../../../../../config/config";
import { Form } from "react-bootstrap";
import { useCampaign } from "../../../CampaignContext";

const AutocompleteUsers = ({ onChange, value, type = "ACTIVATE", disabled = false }) => {
  const [options, setOptions] = useState([]);
  const {campaignId} = useCampaign()

  const searchUsers = debounce(async (value) => {
    try {
      if (!value) {
        return;
      }
      if (value.length < 3) {
        return;
      }
      const url =
        type === "ACTIVATE"
          ? `${HostLead}api/getUsersByName?searchTerm=${value}`
          : `${HostLead}api/escuchas/campaign/${campaignId}/getUsers?searchTerm=${value}`;
      const response = await fetch(url);
      const data = await response.json();
      setOptions(type === "ACTIVATE" ? data : data.users);
    } catch (error) {
      console.error("Error searching users:", error);
    }
  }, 1000);

  const handleSearchChange = (event) => {
    searchUsers(event.target.value);
  };

  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>
        {type === "ACTIVATE" ? "Activar" : "Desactivar"} usuario
      </Form.Label>
      <Autocomplete
        disabled={disabled}
        options={options}
        noOptionsText="No se encontraron usuarios"
        getOptionLabel={(option) => option?.fullname ?? "--"}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        value={value}
        renderOption={(props, option) => (
          <li {...props} key={option.user_id}>
            {option.fullname}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleSearchChange}
            label="Escribe el nombre del usuario"
            variant="outlined"
          />
        )}
      />
    </Form.Group>
  );
};

export default AutocompleteUsers;
