import React, {Component} from 'react';
import {Modal, Button, OverlayTrigger,Popover} from 'react-bootstrap';
import Iframe from 'react-iframe';
class ModalTerms extends Component {
    constructor(props){
        super(props);
        this.state= {
          
        }; 
      }
    componentDidCatch(error, errorInfo){
        console.log("Toca el boton de cerrar")
    }
    
    handleChange = event => {
        this.setState({
          [event.target.id] : event.target.value
        });
        
      }

    handleSubmit = event => {
        event.preventDefault();
        this.modificarUsuario();
      }
      
    modificarPremio = async() => {

  }
      

    render() { 
        const popover=(
            <Popover id="enlace">
                <Popover.Title as="h2">Enlace de {this.props.nombre}</Popover.Title>
                <Popover.Content>
                    {this.props.enlace}
                </Popover.Content>
            </Popover>
        )
        return ( 
            <Modal
                show= {this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
        <Modal.Header>
            <b>{this.props.nombre}: </b>
            
            <OverlayTrigger trigger="click" placement="left" overlay= {popover}>
                <Button variant="secondary">Click para ver el enlace</Button>
            </OverlayTrigger>
        </Modal.Header>
        
        <Modal.Body>
        <Iframe url={this.props.enlace} 
                width="90%"
                height="300px"
                id="terms"
                display="initial"
                />
        </Modal.Body>
        
        <Modal.Footer>
            
        </Modal.Footer>
      </Modal>
         );
    }
}
 
export default ModalTerms;