import React, {Component} from 'react';
import {Modal, Form, Col, Button, Row} from 'react-bootstrap';
import { HostNode} from '../config/config';
import Axios from 'axios';
// import moment from 'moment'
// import Compress from 'compress.js'
// import ModalImage from './ModalImage'
import { useJsonToCsv } from 'react-json-csv';
  const { saveAsCsv } = useJsonToCsv();
class ModalNewSurvey extends Component {
    constructor(props){
        super(props);
        this.state= {
            perfilado:0,
            trustsurvey:0,
            modalShow:false,
            image:null,
            allCategories:[],
            totalRespuestas:[0],
            totalPanelistas:[0],
            fields2:{
                "email" : "email",
                "user_id" : "user_id",
                "URL" :"URL",
                "Gandia" : "GANDIA",
                "name" : "name",
                
            },

        }; 
    }
    componentDidMount() {
    console.log(this.props.surveyId)
    }
    handleChange = event => {
        this.setState({
            [event.target.id] : event.target.value
        });
        
    }
    getTotalPanelistas(id){
        const urlHost = HostNode + 'api/getTotalSurvey' 
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        const self =this;
        Axios({
            method: 'GET',
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + MYTOKEN.access_token,
            },  
            url: urlHost,
            params:{
                surveyId:id
            }          
        }).then(
            function(res){
                var datos = res.data;
                self.setState({
                    panelistasAsignados:datos
                })
                console.log("numPanelistas: " + datos)
            }
        )
        }
    
    obtenerEncuesta(){
        const data = JSON.parse(localStorage.getItem('dataSurvey'));
        if(!data){
            
        }else if( this.state.id !== data.id ) { 
            this.setState({
                id:data.id,
                name:data.name,
                category_id:data.category_id,
                start_date:data.start_date,
                end_date:data.end_date,
                credits:data.credits,
                trustsurvey:data.trust_survey,
                perfilado:data.perfilado,
                survey_key:data.survey_key,

            })
            this.obtenerOleadas(data.id)
            this.getTotalPanelistas(data.id)
            }
    }

    obtenerOleadas(id){
        const urlHost = HostNode + 'api/getOleadas'
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        const self=this
        Axios({
            method: 'GET',
            url: urlHost,
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + MYTOKEN.access_token,
            },  
            params:{
                sid:id
            }
            
        }).then(
            function(res){
                var datos = res.data;
                self.setState({
                    totalPanelistas: datos.Totalpanelistas,
                    totalRespuestas: datos.Totalrespuestas,
                })
                console.log(datos)
            }
        )
    }

    downloadAllPanelistas(){
        const urlHost = HostNode + 'api/getRemainingPanel'
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        const self =this;
        Axios({
            method: 'GET',
            url: urlHost,
            headers:{'Content-Type': "application/json",
                   Authorization: 'Bearer ' + MYTOKEN.access_token},
            params:{
                surveyId:this.state.id,
            }
        })
        .then(function(res){
            const data = res.data
            self.setState({
                datos : data,
            })
            console.log(data)
            const filename= `Datos de ${self.props.surveyName}`;
            const fields = self.state.fields2;
            console.log(data)
            saveAsCsv({ data, fields, filename })
        })
    }
    downloadSomePanelistas(){
        const urlHost = HostNode + 'api/getSomeRemainingPanel'
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        const self =this;
        Axios({
            method: 'GET',
            url: urlHost,
            headers:{'Content-Type': "application/json",
                   Authorization: 'Bearer ' + MYTOKEN.access_token},
            params:{
                surveyId:this.state.id,
                numNeed:this.state.numPanelistas

            }
        })
        .then(function(res){
            const data = res.data
            self.setState({
                datos : data,
            })
            console.log(data)
            const filename= `Datos de ${self.props.surveyName}`;
            const fields = self.state.fields2;
            console.log(data)
            saveAsCsv({ data, fields, filename })
        })
    }
    render() {    
        this.obtenerEncuesta();
        
        console.log('prueba' + this.state.totalRespuestas.lenght)
        return ( 
            <Modal
                show= {this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
            <Modal.Header>
                <Modal.Title id="">
                    <div>Asignación de usuarios a la encuesta: {this.state.name} id: {this.state.id}</div>  
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2>Total de panelistas asignados: {this.state.panelistasAsignados}</h2>
                {this.state.totalPanelistas[0] !=0?
                    
                    this.state.totalPanelistas.map((usuarios,index) =>{
                        return <p>En la <b>{usuarios.oleada}</b>º oleada, se han enviado a <b>{usuarios.Total}</b> usuarios y han respondido <b>{this.state.totalRespuestas[index]?this.state.totalRespuestas[index].Total:0}</b> </p>
                    })
                        : <p>No has asignado ningun panelista a esta encuesta</p>
                }

                <div>
                    <Row >
                        <Col style={{width:"50%"}}>
                            <Form.Group controlId="numPanelistas">
                                    <Form.Label>¿Cuántos panelistas necesitas más?</Form.Label>
                                    <Form.Control name="numPanelistas" type="numPanelistas" placeholder="Numero Panelistas"  onChange={this.handleChange} value={this.state.numPanelistas} />
                            </Form.Group>    
                        </Col>
                        <Col>
                            <Form.Label>Botón</Form.Label><br/>
                            <Button variant="success" onClick={() => this.downloadSomePanelistas()}>Asignar Oleada</Button>
                        </Col>
                    </Row>
                    <Button variant="warning" onClick={() => this.downloadAllPanelistas()}>Descargar Total restantes</Button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                
                <Button variant="danger" onClick={this.props.onHide}>Cerrar</Button>
                
            </Modal.Footer>
            
            </Modal>

        );
    }
}
export default ModalNewSurvey;