import React, { createContext, useCallback, useEffect, useState } from "react";
import { HOST_ESCUCHAS_NODE } from "../../config/config";

const CampaignsContext = createContext();

const CampaignsProvider = ({ children }) => {
  const [campaigns, setCampaigns] = useState([]);

  const getCampaigns = useCallback(async () => {
    try {
      const response = await fetch(`${HOST_ESCUCHAS_NODE}/campaign`);
      const data = await response.json();
      setCampaigns(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const createCampaign = useCallback(
    async (campaign) => {
      try {
        const response = await fetch(`${HOST_ESCUCHAS_NODE}/campaign`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(campaign),
        });
        const data = await response.json();
        setCampaigns([...campaigns, data]);
      } catch (error) {
        console.error(error);
      }
    },
    [campaigns]
  );

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  return (
    <CampaignsContext.Provider
      value={{
        campaigns,
        createCampaign,
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
};

const useCampaigns = () => {
  const context = React.useContext(CampaignsContext);
  if (context === undefined) {
    throw new Error("useCampaigns must be used within a CampaignsContext");
  }
  return context;
};

export { CampaignsProvider, CampaignsContext, useCampaigns };
