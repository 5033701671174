import React, {Component} from 'react';
import {Container,Pagination, Table,OverlayTrigger,Tooltip,Button} from 'react-bootstrap';
import styled from 'styled-components';
import {HOST, HOSTBridge} from '../../config/config';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faUserEdit, faCheckCircle,faBan,faTimesCircle,faCheck,} from '@fortawesome/free-solid-svg-icons'
import ModalUser from '../../complements/ModalUser';
import ModalNewUser from '../../complements/ModalNewUser';
const Styles = styled.div`
.container{
    text-align:center;
}
.page-link{
    background-color:#343a40;
    color:white;
    border: 1px solid #dee2e6;

}
&:hover{
    background-color:#3e444a,
    color:white;
    border: 1px solid #dee2e6;

}
paginationItemStyle{
    float:Left;
}
.nuevoUsuario{
    float:right;
}
`
class Usuarios extends Component{
    constructor(){
        super();
        this.state={
            cols : ["Id", 'Nombre', 'Apellidos', 'Email', 'Rol', 'Estado', ''],
            modalShow:false,
            setModelShow: false,
            modalShow2:false,
            setModelShow2: false,
            userTotal:0,
            userPage:10,
            currentPage:1,
            pages:0,
            usuarios:[]

        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(event){
        this.setState({
            currentPage: Number(event.target.id)
          });
    }
    componentDidMount(){

        this.getUsers();

    }

    getUsers = async(obj) =>{

        var urlHost = HOSTBridge  + "api/getAllBackOffice?limit=500&offset=0&pageClicked=1&[]";
        var MYTOKEN = JSON.parse(localStorage.getItem('token'));
        var self= this;
        Axios({
            method: 'GET',
            url: urlHost,
            headers:{
                "Content-Type": "application/json; charset=utf-8",
				"Authorization": "Bearer " + MYTOKEN.access_token,
            },
            data:{}
        })
        .then(function(res){
            var datos = res.data;
            console.log(datos);
            self.setState({
                usuarios: datos.content,
                userTotal:datos.content.length,

            })
        }).catch(
            function(res){
                console.log("no funciona el get users")
            }
        )
    }
    render(){
        const pageNumbers =[];
        const users = this.state.usuarios;
        for(let i= 1; i <= Math.ceil(this.state.userTotal/this.state.userPage);i++){
            pageNumbers.push(i);
        }
        const indexOfLastTodo = this.state.currentPage * this.state.userPage;
        const indexOfFirstTodo = indexOfLastTodo - this.state.userPage;
        const currentTodos = users.slice(indexOfFirstTodo, indexOfLastTodo);
        const renderUsers = currentTodos.map((user, index) => {

                const{userId, name, firstSurname, secondSurname, email, status} = user;
                return(


                    <tr key={userId}>
                        <td>{userId}</td>
                        <td>{name}</td>
                        <td>{firstSurname} {secondSurname}</td>
                        <td>{email}</td>
                        <td></td>
                        <td>{statusCheck(status)}</td>
                        <td>
                            <span onClick={() => this.setState({modalShow:true,
                                                                userId:userId,
                                                                name: name,
                                                                status_id:status,
                                                                mail:email})}>
                                <FontAwesomeIcon cursor="pointer" icon={faUserEdit} />
                            </span>
                        </td>
                    </tr>

                )
             })
        const renderPageNumbers = pageNumbers.map(number => {
            return(

                <Pagination.Item
                    className="paginationItemStyle"
                    key={number}
                    id= {number}
                    onClick={this.handleClick}>
                        {number}
                    </Pagination.Item>
            )
        })
        return (
            <Styles>
            <Container className ='container'>
            <div>
                <Button variant="outline-primary" className="nuevoUsuario" onClick={() => this.setState({modalShow2:true})}>
                    <FontAwesomeIcon icon={faPlus} /> Nuevo admin</Button>
                <Table striped bordered hover variant="light">
                    <thead>
                        <tr>
                            {this.state.cols.map((col) => <td key={col}>{col}</td>)}
                        </tr>
                    </thead>
                    <tbody>
                         <ModalUser
                            show={this.state.modalShow}
                            mail={this.state.mail}
                            status_id={this.state.status_id}
                            name={this.state.name}
                            userId={this.state.userId}
                            onHide={() => this.setState({modalShow:false})} />
                            <ModalNewUser
                                show={this.state.modalShow2}
                                onHide={() => this.setState({modalShow2:false})}
                                ></ModalNewUser>

                    {renderUsers}

                    </tbody>



                </Table>
                <Pagination className='paginationItemStyle'>

                            {renderPageNumbers}

                </Pagination>


            </div>
            </Container>
            </Styles>
        )
    }
}


  function statusCheck(status){
    switch(status){
        case 1:
            return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">No Verificado</Tooltip>}>
                    <FontAwesomeIcon  icon={faCheck} />
                  </OverlayTrigger>;
        case 2:
            return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Activo</Tooltip>}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                   </OverlayTrigger>;
        case 3:
            return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Desactivado</Tooltip>}>
                     <FontAwesomeIcon icon={faBan} />
                    </OverlayTrigger>;
        case 4:
            return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Bloqueado</Tooltip>}>
                     <FontAwesomeIcon icon={faTimesCircle} />
                    </OverlayTrigger>;
        default:
            return ;
    }
  }

export default Usuarios;