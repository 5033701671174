import React, {Component} from 'react'
import { Container } from 'react-bootstrap'

import styled from 'styled-components'
import Sunburst from 'sunburst-chart';
import * as d3 from 'd3'
import Sankey from './sankeychart'
import Chart from 'chart.js';
// var Chart = require('chart.js');

const Styles = styled.div`
    .contenedor{
        widht:100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height:auto;
        font-family:Roboto;
    }
    .canvas{
        width:990px;
        text-align:center;  
    }
    @media (min-width: 1025px) and (max-width: 1280px) {
        .canvas{
            widht:768px;
        }
        
    }
    @media (min-width: 481px) and (max-width: 767px) {
        .canvas{
            widht:414px;
        }
        
    }


`
Chart.defaults.global.defaultFontFamily = "'Roboto', sans-serif"

class ChartsJs extends Component {
    chartRef = React.createRef();
    chartRef2 = React.createRef();
    chartRef3 = React.createRef();
    chartRef4 = React.createRef();
    chartRef5 = React.createRef();
    chartRef6 = React.createRef();
    chartRef7= React.createRef();
    chartRef8= React.createRef();
    chartRef9= React.createRef();
    componentDidMount() {
        const myChartRef =this.chartRef.current.getContext("2d");
        new Chart(myChartRef, {
            type:"line",
            data:{
                labels:["Enero", "Febrero","Marzo", "Abril"],
                datasets:[{
                    label: "Ventas de Mascarilla",
                    data: [17,20,85,45],
                    backgroundColor:"#00669980"
                },{
                    label:"Ventas de Geles",
                    data:[15,25,85,64],
                    backgroundColor:"#C3D5E50"
                },{
                    label:"% Coronavirus",
                    data:[35,40,80,75],
                    backgroundColor:"#66666680"
                }]
            },  
            options:{
                tooltips:{
                    backgroundColor:'#2E6D9D'
                }
            }
        })
        const myChartRef2 =this.chartRef2.current.getContext("2d");
        new Chart(myChartRef2, {
            type: 'bar',
            data:{
                labels:["Enero", "Febrero","Marzo", "Abril"],
                datasets:[{
                    label: "Ventas de Mascarilla",
                    data: [17,20,85,45],
                    backgroundColor:"#00669980"
                },{
                    label:"Ventas de Geles",
                    data:[15,25,85,64],
                    backgroundColor:"#C3D5E50"
                },{
                    label:"% Coronavirus",
                    data:[35,40,80,75],
                    backgroundColor:"#66666680"
                }]
            },  
            options:{
                tooltips:{
                    backgroundColor:'#2E6D9D'
                }
            }
        });
        const myChartRef3 =this.chartRef3.current.getContext("2d");
        new Chart(myChartRef3, {
            type: 'radar',
            data:{
                labels:["Dormir", "Comer","Hacer Deporte", "Jugar videojuegos", "Escuchar Musica"],
                datasets:[{
                    label:"% de Hobbies realizados en la cuarentena",
                    data: [20,20,15,25, 20],
                    backgroundColor:"#00669980"
                }]
            },  
            options:{
                tooltips:{
                    backgroundColor:'#2E6D9D'
                }
            }
        });
        const myChartRef4 =this.chartRef4.current.getContext("2d");
        new Chart(myChartRef4, {
            type: 'pie',
            data:{
                labels:["PP","Psoe","Podemos","Vox","Ciudadanos"],
                datasets:[{
                    data:[20,25,10,12,23],
                    backgroundColor:['#16acf9','#f91616','#9716f9','#00900c','#ff6a00']
                }]
            },  
            options:{
                tooltips:{
                    backgroundColor:'#2E6D9D'
                }
            }
        });
        const myChartRef5 =this.chartRef5.current.getContext("2d");
        new Chart(myChartRef5, {
            type: 'doughnut',
            data:{
                labels:["PP","Psoe","Podemos","Vox","Ciudadanos"],
                datasets:[{
                    data:[20,25,10,12,23],
                    backgroundColor:['#16acf9','#f91616','#9716f9','#00900c','#ff6a00']
                }]
            },  
            options:{
                tooltips:{
                    backgroundColor:'#2E6D9D'
                }
            }
        });
        const myChartRef6 =this.chartRef6.current.getContext("2d");
        new Chart(myChartRef6, {
            type: 'doughnut',
            data:{
                labels:["Psoe","Podemos","PP","Vox","Ciudadanos"],
                datasets:[{
                    data:[25,14,20,12,20],
                    backgroundColor:['#f91616','#9716f9','#16acf9','#00900c','#ff6a00']
                }]
            },  
            options:{
                circumference:Math.PI,
                rotation: Math.PI,
                
            }
        });
        const myChartRef7 =this.chartRef7.current.getContext("2d");
        new Chart(myChartRef7, {
            type: 'polarArea',
            data:{
                labels:["Psoe","Podemos","PP","Vox","Ciudadanos"],
                datasets:[{
                    data:[25,14,20,12,20],
                    backgroundColor:['#f91616','#9716f9','#16acf9','#00900c','#ff6a00']
                }]
            },  
            
        });
        const myChartRef8 =this.chartRef8.current.getContext("2d");
        new Chart(myChartRef8, {
            type: 'scatter',
            data:{
                datasets:[{
                    label:"Datos por puntos",
                    data:[
                     {
                            x: 2,
                            y: 7
                        },{
                            x: 6,
                            y: 17
                        },
                        {
                            x: 4,
                            y: 16
                        },
                        {
                            x: 10,
                            y: 5
                        },
                        {
                            x: 7,
                            y: 4
                        },{
                            x: 7,
                            y: 18
                        },{
                            x: 8,
                            y: 8
                        }, {
                            x: 10,
                            y: 5
                        }
                    ],
                    backgroundColor:"#000000",
                }]
            },  
            options: {
                scales: {
                    xAxes: [{
                        type: 'linear',
                        position: 'bottom'
                    }]
                }
            },
            
        });
        const myChartRef9 =this.chartRef9.current.getContext("2d");
        new Chart(myChartRef9, {
            type: 'bubble',
            data:{
                
                datasets:[{
                    label:"tormenta",   
                    data:[
                        {
                            x:15,
                            y:12,
                            r:15,
                        },
                        {
                            x:8,
                            y:2,
                            r:15,
                        },
                        {
                            x:17,
                            y:7,
                            r:10,
                        },
                        {
                            x:13,
                            y:6,
                            r:15,
                        },
                        {
                            x:12,
                            y:5,
                            r:15,
                        },
                        {
                            x:13,
                            y:8,
                            r:15,
                        },
                        {
                            x:14,
                            y:4,
                            r:15,
                        },
                        {
                            x:17,
                            y:7,
                            r:15,
                        }
                        
                    ],
                    backgroundColor:["#e56205","#990e8e","#10990e","#e56205","#990e8e","#10990e","#e56205"]
                }],
            }
        });
        const color = d3.scaleOrdinal(d3.schemePaired);
        const myChartRef10 = Sunburst();
        myChartRef10.data({
            name:"",
            children:[
            {
                name:"Madrid",
                value:10    ,

            },
            {
                name:"Castilla la Mancha",
                children:[
                    {
                        name:"Guadalajara",
                        value:5,
                        color:"#245d95"
                    },
                    {
                        name:"Cuenca",
                        value:5
                    },
                    {
                        name:"Albacete",
                        value:5
                    },
                    {
                        name:"Ciudad Real",
                        value:5
                    },
                    {
                        name:"Toledo",
                        value:5
                    },

                ]
            },{
                name:"Castilla y Leon",
                children:[
                    {
                        name:"Soria",
                        value:5,
                    },
                    {
                        name:"Segovia",
                        value:5,
                    },
                    {
                        name:"Avila",
                        value:5
                    },
                    {
                        name:"Salamanca",
                        value:5
                    },
                    {
                        name:"Valladolid",
                        value:5
                    },{
                        name:"Palencia",
                        value:5
                    },{
                        name:"León",
                        value:5
                    },{
                        name:"Zamora",
                        value:5
                    },{
                        name:"León",
                        value:5,
                    },

                ],
                
            },
            ],
        }).excludeRoot(true).color((d, parent) => color(parent ? parent.data.name : null)).width([520])(document.getElementById("sunburst"));
    }

    render() {
        return ( 
            <Styles>
            <Container className="contenedor">
                <div className="canvas">
                    <h1>Grafico tipo: Lineas</h1>
                    <canvas id="myChart"
                            ref={this.chartRef}/>
                    <br/>
                    <br/>
                    <br/>
                    <h1>Grafico tipo: Barras</h1>
                    <canvas id="myChart"
                            ref={this.chartRef2}/>
                    <br/>
                    <h1>Grafico tipo: Radar </h1>
                    <canvas id="myChart"
                            ref={this.chartRef3}/>
                    <br/>
                    <br/>
                    <br/>
                    <h1>Grafico tipo: Pastel </h1>
                    <canvas id="myChart"
                        ref={this.chartRef4}/>
                    <br/>
                    <br/>
                    <br/>
                    <h1>Grafico tipo: Donut </h1>
                    <canvas id="myChart"
                        ref={this.chartRef5}/>
                    <br/>
                    <br/>
                    <br/>
                    <h1>Grafico tipo: Hemiciclo </h1>
                    <canvas id="myChart"
                        ref={this.chartRef6}/>
                    <br/>
                    <br/>
                    <br/>
                    <h1>Grafico tipo: Area Polar </h1>
                    <canvas id="myChart"
                        ref={this.chartRef7}/>
                    <br/>
                    <br/>
                    <br/>
                    <h1>Grafico tipo: Scatter </h1>
                    <canvas id="myChart"
                        ref={this.chartRef8}/>
                    <br/>
                    <br/>
                    <br/>
                    <h1>Grafico tipo: Burbujas </h1>
                    <canvas id="myChart"
                        ref={this.chartRef9}/>
                    <br/>
                    <br/>
                    <br/>
                    <h1>Grafico tipo: SunBurst </h1>
                    <div id="sunburst"></div>

                    <br/>
                    <br/>
                    <br/>
                    <h1>Grafico tipo: Sankey</h1>
                    <div style={{paddingLeft:'20%'}}><Sankey/></div>
                    <br/>
                    <br/>
                    <br/>
                    
                </div>
                
            </Container>
            </Styles>
        );
    }
    }

export default ChartsJs;