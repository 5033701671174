import { CircularProgress, Typography } from "@mui/material";
import React from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import InfoCard from "../../components/InfoCard";
import { useLogCampaign } from "./LogCampaingContext";
import LogsTable from "./components/LogsTable";
import DateFilters from "./components/DateFilters";
import { useCampaigns } from "../../../campaigns/CampaignsContext";
import { useCampaign } from "../../CampaignContext";
import { ExportButton } from "./components/ExportButton";
import { CAMPAIGN_STATUS } from "../../../../config/constants";
import { ExportProgramSurveysButton } from "./components/ExportProgramSurveysButton";

function LogCampaign() {
  const {
    overview,
    isOverviewLoading,
    disableEstudio,
    isDisableCampaingLoading
  } = useLogCampaign();
  const {campaigns} = useCampaigns();
  const {campaignId} = useCampaign();
  const currentCampaign = campaigns.find(c => c.id === parseInt(campaignId));
  const isFinished = currentCampaign?.status === CAMPAIGN_STATUS.FINISHED

  return (
    <Container>
      <Row>
        <div style={{ marginBottom: "20px", display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
          <Typography variant="h5">{currentCampaign?.name}</Typography>
          {isFinished && <Typography variant="caption">(Finalizado)</Typography>}
        </div>
      </Row>

      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          marginBottom: "20px",
        }}
      >
        {isOverviewLoading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <InfoCard
              num={overview?.count_users}
              title={`Persona${overview?.count_users === 1 ? "s" : ""}`}
            />
            <InfoCard
              variant="secondary"
              num={overview?.coincidencias}
              title={`Coincidencia${overview?.coincidencias === 1 ? "s" : ""}`}
            />
            <InfoCard
              variant="secondary"
              num={overview?.no_coincidencias}
              title="Sin Coincidencia"
            />
          </>
        )}
      </Row>

      <Row style={{ marginBottom: "16px" }}>
        <Col
          style={{
            padding: 0,
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <DateFilters />
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          }}>
            <Button
              variant="outline-primary"
              onClick={disableEstudio}
              disabled={isDisableCampaingLoading || isFinished}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                hidden={!isDisableCampaingLoading}
              />
              Desactivar estudio</Button>
            <ExportButton />
            <ExportProgramSurveysButton />
          </div>
        </Col>
      </Row>

      <Row>
        <Col style={{ padding: 0 }}>
          <div
            style={{
              marginBottom: "60px",
            }}
          >
            <LogsTable />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default LogCampaign;
