import React, { Component } from 'react';
import Axios from 'axios';
import  {HostNode, HostLead} from '../../config/config'
// import {Container, Card } from 'react-bootstrap'
import  Chart  from 'chart.js';
// import palette from 'google-palette'
// import ChartDataLabels from 'chartjs-plugin-datalabels';
// import styled from 'styled-components'
import 'chartjs-plugin-colorschemes';

// import * as d3 from 'd3'
// import { jssPreset } from '@material-ui/core';

// var Chart = require('chart.js');
class second extends Component {
    chartRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = { 
            twitter:null,
            facebook:null,
        }
    }
    componentDidMount(){
        this.getOrigenes()
    }
    getOrigenes = async() =>{
        const urlHost = HostLead+'api/getOrigenes'
        const self =this;
        Axios({
            method:"get",
            url: urlHost,
        }).then(function(data){
            // var datos = data.data.data
            var labels = [];
            var datas =[]
            var total =0;
            // var cosas = datos.map((dato) =>{
            //     labels.push(dato.origen2);
            //     datas.push(dato.Res);
            //     total = total + dato.Res
            //     console.log(total)
            // })
            
            self.setState({
                total:total
            })
            self.Graficos1(labels,datas);
        })
    }
    getFacebookUsers = async()=>{
        const urlHost =HostNode+"/api/getFCusers"
        var MYTOKEN = JSON.parse(localStorage.getItem('token')); 
        const self = this;
        Axios({
            method: 'get',
            url: urlHost,
            headers:{
                "Content-Type": "application/json; charset=utf-8",
				"Authorization": "Bearer " + MYTOKEN.access_token,
            },  
        }).then(function(data){
            var datos = data.data;
            self.setState({
                facebook:datos
            })
        }
    )
    }
    
    getTwitterUsers = async()=>{
        const urlHost =HostNode+"/api/getTWusers"
        var MYTOKEN = JSON.parse(localStorage.getItem('token')); 
        const self = this;
        Axios({
            method: 'get',
            url: urlHost,
            headers:{
                "Content-Type": "application/json; charset=utf-8",
				"Authorization": "Bearer " + MYTOKEN.access_token,
            },  
        }).then(function(data){
            var datos = data.data;
            self.setState({
                twitter:datos
            })
            self.Graficos1()
        }
    )
    }
    Graficos1(x,y){
    const myChartRef =this.chartRef.current.getContext("2d");
 
    new Chart(myChartRef, {
        type:"bar",
        data:{
            labels:x,
            datasets:[{
                data:y,
            }]
        },  
        options:{
            legend: {
                display: false
            },
            plugins:{ 
                colorschemes: {
                    scheme: 'brewer.SetThree7'
                },
                datalabels: {
                    backgroundColor:"#006699",
                    borderRadius: 4,
                    color: 'white',
                    font: {
                    weight: 'bold'
                    }
                }
            }
        }
    })
}
    
    render() { 
        return ( 
            <div>
                <h1>Origenes RRSS</h1>
                <h2>Total: <b>{this.state.total}</b></h2>
                <canvas id="myChart"
                        ref={this.chartRef}/>
            </div>
        );
    }
}

export default second;