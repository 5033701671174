import { Typography } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CampaignsList from "./CampaignsList";
import { CampaignsProvider } from "./CampaignsContext";
import CampaignNewForm from "./components/CampaignNewForm/CampaignNewForm";

function Campaigns() {
  return (
    <CampaignsProvider>
      <Container
        style={{
          marginTop: "20px",
        }}
      >
        <Row>
          <Col lg={8}>
            <Typography variant="h5">Estudios realizados</Typography>
            <CampaignsList />
          </Col>
          <Col lg={4}>
            <CampaignNewForm />
          </Col>
        </Row>
      </Container>
    </CampaignsProvider>
  );
}

export default Campaigns;
